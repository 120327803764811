//      

/** All built-in and custom scalars, mapped to their actual values */
                       
             
                 
                   
              
                
            
            
  






                       
                                                 
                                                   
  

export const AuthMessageValues = Object.freeze({
  REQUEST_COOKIE: 'REQUEST_COOKIE', 
  UPDATE_SESSION: 'UPDATE_SESSION', 
  REQUEST_SIGN_OUT: 'REQUEST_SIGN_OUT', 
  SESSION: 'SESSION'
});


                                                            

export const AppScanBlockedReasonValues = Object.freeze({
  CORRUPTED_APP_PACKAGE: 'CORRUPTED_APP_PACKAGE', 
  NO_CREDENTIALS: 'NO_CREDENTIALS', 
  NOT_BLOCKED: 'NOT_BLOCKED', 
  OTHER: 'OTHER'
});


                                                                              

export const AuthStrategyValues = Object.freeze({
  OTP: 'OTP', 
  GOOGLE_ACCOUNT: 'GOOGLE_ACCOUNT', 
  SAML: 'SAML'
});


                                                              

export const CompliancePolicyValues = Object.freeze({
  GDPR: 'GDPR', 
  PCI: 'PCI', 
  HIPAA: 'HIPAA', 
  FTC: 'FTC', 
  OWASP: 'OWASP', 
  CALOPPA: 'CALOPPA', 
  COPPA: 'COPPA', 
  VPPA: 'VPPA', 
  CCPA: 'CCPA', 
  MITRE: 'MITRE', 
  OWASP_MSTG: 'OWASP_MSTG', 
  OWASP_ASVS: 'OWASP_ASVS'
});


                                                                      

export const FindingPriorityValues = Object.freeze({
  NO_PRIORITY: 'NO_PRIORITY', 
  P0: 'P0', 
  P1: 'P1', 
  P2: 'P2', 
  P3: 'P3', 
  P4: 'P4'
});


                                                                    

export const FindingSeverityValues = Object.freeze({
  INFORMATIONAL: 'INFORMATIONAL', 
  LOW: 'LOW', 
  MEDIUM: 'MEDIUM', 
  HIGH: 'HIGH'
});


                                                                    

export const MobileAppListFilterValues = Object.freeze({
  SCAN_AND_SECURE_APPS: 'SCAN_AND_SECURE_APPS', 
  OPENSCAN_APPS: 'OPENSCAN_APPS'
});


                                                                            

export const MobileAppPlatformValues = Object.freeze({
  IOS: 'IOS', 
  ANDROID: 'ANDROID', 
  WINDOWS_PHONE: 'WINDOWS_PHONE', 
  ANDROID_AMAZON: 'ANDROID_AMAZON', 
  ANDROID_OCULUS: 'ANDROID_OCULUS', 
  ANDROID_FACEBOOK_PORTAL: 'ANDROID_FACEBOOK_PORTAL', 
  MACOS: 'MACOS', 
  IOS_ON_MAC: 'IOS_ON_MAC', 
  TVOS: 'TVOS', 
  MAC_CATALYST: 'MAC_CATALYST', 
  ANDROID_SAMSUNG: 'ANDROID_SAMSUNG', 
  ANDROID_OCULUS_RIFT: 'ANDROID_OCULUS_RIFT', 
  ANDROID_OCULUS_QUEST: 'ANDROID_OCULUS_QUEST', 
  WATCHOS: 'WATCHOS'
});


                                                                        

export const MobileAppReleaseTypeValues = Object.freeze({
  APP_STORE: 'APP_STORE', 
  PRE_PROD: 'PRE_PROD', 
  APP_STORE_THIRD_PARTY: 'APP_STORE_THIRD_PARTY', 
  ENTERPRISE: 'ENTERPRISE'
});


                                                                              

export const MobileAppSubscriptionValues = Object.freeze({
  STATIC: 'STATIC', 
  DYNAMIC: 'DYNAMIC', 
  APP_LOGIC: 'APP_LOGIC', 
  P1_ALERTS: 'P1_ALERTS', 
  NO_SUBSCRIPTION: 'NO_SUBSCRIPTION'
});


                                                                                

export const PiiTypeValues = Object.freeze({
  ADVERTISING_ID: 'ADVERTISING_ID', 
  AGE: 'AGE', 
  CREDIT_CARD_NUMBER: 'CREDIT_CARD_NUMBER', 
  CREDIT_CARD_TRACK_NUMBER: 'CREDIT_CARD_TRACK_NUMBER', 
  DATE_OF_BIRTH: 'DATE_OF_BIRTH', 
  EMAIL_ADDRESS: 'EMAIL_ADDRESS', 
  FEMALE_NAME: 'FEMALE_NAME', 
  FIRST_NAME: 'FIRST_NAME', 
  GENDER: 'GENDER', 
  IBAN_CODE: 'IBAN_CODE', 
  HTTP_COOKIE: 'HTTP_COOKIE', 
  ICD9_CODE: 'ICD9_CODE', 
  ICD10_CODE: 'ICD10_CODE', 
  IMEI_HARDWARE_ID: 'IMEI_HARDWARE_ID', 
  LAST_NAME: 'LAST_NAME', 
  LOCATION: 'LOCATION', 
  MAC_ADDRESS: 'MAC_ADDRESS', 
  MAC_ADDRESS_LOCAL: 'MAC_ADDRESS_LOCAL', 
  MALE_NAME: 'MALE_NAME', 
  MEDICAL_TERM: 'MEDICAL_TERM', 
  PASSPORT: 'PASSPORT', 
  PERSON_NAME: 'PERSON_NAME', 
  PHONE_NUMBER: 'PHONE_NUMBER', 
  STREET_ADDRESS: 'STREET_ADDRESS', 
  SWIFT_CODE: 'SWIFT_CODE', 
  AUTH_TOKEN: 'AUTH_TOKEN', 
  PASSWORD: 'PASSWORD', 
  JAPAN_BANK_ACCOUNT: 'JAPAN_BANK_ACCOUNT', 
  JAPAN_DRIVERS_LICENSE_NUMBER: 'JAPAN_DRIVERS_LICENSE_NUMBER', 
  JAPAN_INDIVIDUAL_NUMBER: 'JAPAN_INDIVIDUAL_NUMBER', 
  AMERICAN_BANKERS_CUSIP_ID: 'AMERICAN_BANKERS_CUSIP_ID', 
  US_BANK_ROUTING_MICR: 'US_BANK_ROUTING_MICR', 
  US_DRIVERS_LICENSE_NUMBER: 'US_DRIVERS_LICENSE_NUMBER', 
  US_EMPLOYER_IDENTIFICATION_NUMBER: 'US_EMPLOYER_IDENTIFICATION_NUMBER', 
  US_HEALTHCARE_NPI: 'US_HEALTHCARE_NPI', 
  US_PASSPORT: 'US_PASSPORT', 
  US_PREPARER_TAXPAYER_IDENTIFICATION_NUMBER: 'US_PREPARER_TAXPAYER_IDENTIFICATION_NUMBER', 
  US_SOCIAL_SECURITY_NUMBER: 'US_SOCIAL_SECURITY_NUMBER', 
  AWS_CREDENTIALS: 'AWS_CREDENTIALS', 
  AZURE_AUTH_TOKEN: 'AZURE_AUTH_TOKEN', 
  BASIC_AUTH_HEADER: 'BASIC_AUTH_HEADER', 
  ENCRYPTION_KEY: 'ENCRYPTION_KEY', 
  GCP_API_KEY: 'GCP_API_KEY', 
  GCP_CREDENTIALS: 'GCP_CREDENTIALS', 
  JSON_WEB_TOKEN: 'JSON_WEB_TOKEN', 
  WEAK_PASSWORD_HASH: 'WEAK_PASSWORD_HASH', 
  XSRF_TOKEN: 'XSRF_TOKEN'
});


                                                    

export const RoleValues = Object.freeze({
  DEVELOPER: 'DEVELOPER', 
  MANAGER: 'MANAGER', 
  SECURITY: 'SECURITY', 
  DEVELOPER_ALL_APPS: 'DEVELOPER_ALL_APPS', 
  SECURITY_ALL_APPS: 'SECURITY_ALL_APPS'
});


                                              

export const SecurityFindingSearchImportanceTagValues = Object.freeze({
  SECURITY_P1: 'SECURITY_P1', 
  SECURITY: 'SECURITY', 
  APPLE: 'APPLE', 
  APPLE_P1: 'APPLE_P1', 
  GOOGLE: 'GOOGLE', 
  GOOGLE_P1: 'GOOGLE_P1', 
  DEFENSE_IN_DEPTH: 'DEFENSE_IN_DEPTH', 
  SERVER_SIDE: 'SERVER_SIDE', 
  CUSTOMER_POLICY: 'CUSTOMER_POLICY', 
  KEYS_TO_THE_KINGDOM: 'KEYS_TO_THE_KINGDOM', 
  DATA_LEAKS: 'DATA_LEAKS', 
  COFFEE_SHOP_COMPROMISE: 'COFFEE_SHOP_COMPROMISE', 
  CATEGORY_SSL_CERTIFICATE_VALIDATION: 'CATEGORY_SSL_CERTIFICATE_VALIDATION', 
  CATEGORY_SSL_HOSTNAME_VALIDATION: 'CATEGORY_SSL_HOSTNAME_VALIDATION', 
  CATEGORY_SSL_VULNERABLE_SDK: 'CATEGORY_SSL_VULNERABLE_SDK', 
  CATEGORY_SSL_SECURE_NETWORK_REQUESTS: 'CATEGORY_SSL_SECURE_NETWORK_REQUESTS', 
  CATEGORY_SSL_OPEN_SOCKETS: 'CATEGORY_SSL_OPEN_SOCKETS', 
  CATEGORY_SSL_CLEARTEXT_COMMUNICATION: 'CATEGORY_SSL_CLEARTEXT_COMMUNICATION', 
  APP_STORE_PRIVACY: 'APP_STORE_PRIVACY', 
  DATA_TYPE_INSTALLED_APPS: 'DATA_TYPE_INSTALLED_APPS', 
  DATA_TYPE_SMS_MESSAGES: 'DATA_TYPE_SMS_MESSAGES', 
  DATA_TYPE_CONTACT_INFO: 'DATA_TYPE_CONTACT_INFO', 
  DATA_TYPE_CONTACT_LIST: 'DATA_TYPE_CONTACT_LIST', 
  DATA_TYPE_PHOTOS: 'DATA_TYPE_PHOTOS', 
  DATA_TYPE_LOCATION: 'DATA_TYPE_LOCATION', 
  DATA_TYPE_IDFA: 'DATA_TYPE_IDFA', 
  DATA_TYPE_HEALTH_AND_FITNESS: 'DATA_TYPE_HEALTH_AND_FITNESS', 
  DATA_TYPE_PASTEBOARD: 'DATA_TYPE_PASTEBOARD', 
  DATA_TYPE_CELLULAR_CARRIER: 'DATA_TYPE_CELLULAR_CARRIER', 
  DATA_TYPE_WIFI_NETWORK: 'DATA_TYPE_WIFI_NETWORK', 
  DATA_TYPE_MAC_ADDRESS: 'DATA_TYPE_MAC_ADDRESS', 
  DATA_TYPE_DEVICE_IDENTIFIER: 'DATA_TYPE_DEVICE_IDENTIFIER', 
  DATA_TYPE_UNKNOWN: 'DATA_TYPE_UNKNOWN'
});


                                                                                                          

export const FilterByImportanceTagEnumValues = Object.freeze({
  DATA_LEAKS: 'DATA_LEAKS'
});


                                                                                        

export const FilterByProductEnumValues = Object.freeze({
  CLOUD_SECURE: 'CLOUD_SECURE', 
  API_SECURE: 'API_SECURE', 
  WEB_SECURE: 'WEB_SECURE'
});


                                                                            

export const FilterByCategoryEnumValues = Object.freeze({
  API_MANAGEMENT: 'API_MANAGEMENT', 
  DATABASE: 'DATABASE', 
  OTHER: 'OTHER', 
  SECRET_MANAGEMENT: 'SECRET_MANAGEMENT', 
  SERVERLESS: 'SERVERLESS', 
  STORAGE: 'STORAGE', 
  VIRTUAL_MACHINE: 'VIRTUAL_MACHINE'
});


                                                                              

export const OpenScanFavoriteAppGroupValues = Object.freeze({
  CUSTOMER_APPS: 'CUSTOMER_APPS', 
  COMPETITOR_APPS: 'COMPETITOR_APPS', 
  USED_BY_EXECS: 'USED_BY_EXECS', 
  USED_BY_FINANCE: 'USED_BY_FINANCE', 
  USED_BY_HR: 'USED_BY_HR', 
  USED_BY_IT: 'USED_BY_IT', 
  USED_BY_SALES: 'USED_BY_SALES', 
  USED_BY_MARKETING: 'USED_BY_MARKETING', 
  USED_BY_ENGINEERING: 'USED_BY_ENGINEERING'
});


                                                                                      

export const MobileAppAnalysisStatusValues = Object.freeze({
  ONGOING: 'ONGOING', 
  COMPLETED: 'COMPLETED'
});


                                                                                    

export const MobileAppAnalysisFeatureEnumValues = Object.freeze({
  KEYS_TO_THE_KINGDOM: 'KEYS_TO_THE_KINGDOM', 
  COFFEE_SHOP_COMPROMISE: 'COFFEE_SHOP_COMPROMISE', 
  SDK_SPYWARE: 'SDK_SPYWARE'
});


                                                                                              

export const MobileAppScanStatusValues = Object.freeze({
  NO_SCANS_INITIATED: 'NO_SCANS_INITIATED', 
  SCAN_ONGOING: 'SCAN_ONGOING', 
  SCAN_COMPLETED: 'SCAN_COMPLETED'
});


                                                                            

export const SecurityFindingSearchPriorityValues = Object.freeze({
  P0: 'P0', 
  P1: 'P1', 
  P2: 'P2', 
  P3: 'P3', 
  P4: 'P4', 
  NO_PRIORITY: 'NO_PRIORITY'
});


/**
 * Used by the Security Finding Search endpoint as a filter, it's identical to the
 * FindingPriority enum except it also has NO_PRIORITY
 */
                                                                                                

export const SecurityFindingSearchReleaseTypeValues = Object.freeze({
  APP_STORE: 'APP_STORE', 
  PRE_PROD: 'PRE_PROD', 
  APP_STORE_THIRD_PARTY: 'APP_STORE_THIRD_PARTY', 
  ENTERPRISE: 'ENTERPRISE'
});


                                                                                                      

export const TargetStatusValues = Object.freeze({
  OPEN: 'OPEN', 
  NEW: 'NEW', 
  CLOSED_BY_POLICY: 'CLOSED_BY_POLICY', 
  CLOSED_FIXED: 'CLOSED_FIXED', 
  CLOSED_RISK_ACCEPTED: 'CLOSED_RISK_ACCEPTED', 
  CLOSED_COMPENSATING_CONTROL: 'CLOSED_COMPENSATING_CONTROL', 
  CLOSED_ITEM_NOT_FOUND: 'CLOSED_ITEM_NOT_FOUND', 
  OPEN_READY_TO_RESCAN: 'OPEN_READY_TO_RESCAN'
});


                                                              

export const StatusGroupValues = Object.freeze({
  OPEN: 'OPEN', 
  CLOSED: 'CLOSED'
});


                                                            

export const SdkFindingsListFilterValues = Object.freeze({
  HAS_SDK_ISSUES: 'HAS_SDK_ISSUES', 
  HAS_OPEN_SDK_ISSUES: 'HAS_OPEN_SDK_ISSUES'
});


                                                                                

export const TargetAdditionalContextTypeValues = Object.freeze({
  CUSTOM_FORMAT: 'CUSTOM_FORMAT', 
  STACK_TRACE: 'STACK_TRACE', 
  CODE_LIST: 'CODE_LIST', 
  ASSOCIATED_SDKS: 'ASSOCIATED_SDKS'
});


                                                                                            

export const ComplianceReportTypeValues = Object.freeze({
  data_trust_mobile_reports: 'data_trust_mobile_reports', 
  data_trust_api_reports: 'data_trust_api_reports', 
  data_trust_cloud_reports: 'data_trust_cloud_reports', 
  data_trust_web_reports: 'data_trust_web_reports', 
  app_store_answers_reports: 'app_store_answers_reports', 
  global_app_store_answers_reports: 'global_app_store_answers_reports', 
  coming_soon: 'coming_soon', 
  gdpr_api_reports: 'gdpr_api_reports', 
  soc2_api_reports: 'soc2_api_reports', 
  hipaa_api_reports: 'hipaa_api_reports', 
  iso27001_api_reports: 'iso27001_api_reports', 
  pci_api_reports: 'pci_api_reports', 
  pentest_api_reports: 'pentest_api_reports', 
  gdpr_cloud_reports: 'gdpr_cloud_reports', 
  soc2_cloud_reports: 'soc2_cloud_reports', 
  hipaa_cloud_reports: 'hipaa_cloud_reports', 
  iso27001_cloud_reports: 'iso27001_cloud_reports', 
  pci_cloud_reports: 'pci_cloud_reports', 
  pentest_cloud_reports: 'pentest_cloud_reports', 
  gdpr_mobile_reports: 'gdpr_mobile_reports', 
  sca_mobile_reports: 'sca_mobile_reports', 
  soc2_mobile_reports: 'soc2_mobile_reports', 
  hipaa_mobile_reports: 'hipaa_mobile_reports', 
  iso27001_mobile_reports: 'iso27001_mobile_reports', 
  pci_mobile_reports: 'pci_mobile_reports', 
  pentest_mobile_reports: 'pentest_mobile_reports', 
  gdpr_web_reports: 'gdpr_web_reports', 
  soc2_web_reports: 'soc2_web_reports', 
  hipaa_web_reports: 'hipaa_web_reports', 
  iso27001_web_reports: 'iso27001_web_reports', 
  pci_web_reports: 'pci_web_reports', 
  pentest_web_reports: 'pentest_web_reports', 
  vulnerability_cloud_reports: 'vulnerability_cloud_reports', 
  vulnerability_web_reports: 'vulnerability_web_reports', 
  vulnerability_mobile_reports: 'vulnerability_mobile_reports', 
  vulnerability_api_reports: 'vulnerability_api_reports', 
  fedramp_cloud_reports: 'fedramp_cloud_reports', 
  fedramp_web_reports: 'fedramp_web_reports', 
  fedramp_mobile_reports: 'fedramp_mobile_reports', 
  fedramp_api_reports: 'fedramp_api_reports', 
  owasp_asvs_api_reports: 'owasp_asvs_api_reports', 
  owasp_asvs_web_reports: 'owasp_asvs_web_reports', 
  owasp_asvs_cloud_reports: 'owasp_asvs_cloud_reports', 
  owasp_asvs_mobile_reports: 'owasp_asvs_mobile_reports', 
  owasp_mstg_mobile_reports: 'owasp_mstg_mobile_reports', 
  form_10k_mobile_reports: 'form_10k_mobile_reports', 
  form_10k_api_reports: 'form_10k_api_reports', 
  form_10k_web_reports: 'form_10k_web_reports', 
  form_10k_cloud_reports: 'form_10k_cloud_reports', 
  ciso_cso_quarterly_memo_reports: 'ciso_cso_quarterly_memo_reports', 
  security_findings_export_reports: 'security_findings_export_reports', 
  cnapp_reports: 'cnapp_reports'
});


                                                                              

export const AppleCategoryValues = Object.freeze({
  CONTACT_INFO: 'CONTACT_INFO', 
  HEALTH_AND_FITNESS: 'HEALTH_AND_FITNESS', 
  FINANCIAL_INFO: 'FINANCIAL_INFO', 
  LOCATION: 'LOCATION', 
  SENSITIVE_INFO: 'SENSITIVE_INFO', 
  CONTACTS: 'CONTACTS', 
  USER_CONTENT: 'USER_CONTENT', 
  BROWSING_HISTORY: 'BROWSING_HISTORY', 
  SEARCH_HISTORY: 'SEARCH_HISTORY', 
  IDENTIFIERS: 'IDENTIFIERS', 
  PURCHASES: 'PURCHASES', 
  USAGE_DATA: 'USAGE_DATA', 
  DIAGNOSTICS: 'DIAGNOSTICS', 
  OTHER_DATA: 'OTHER_DATA'
});


                                                                

export const DataTypeValues = Object.freeze({
  DATA_TYPE_CONTACT_INFO: 'DATA_TYPE_CONTACT_INFO', 
  DATA_TYPE_CONTACT_LIST: 'DATA_TYPE_CONTACT_LIST', 
  DATA_TYPE_PHOTOS: 'DATA_TYPE_PHOTOS', 
  DATA_TYPE_LOCATION: 'DATA_TYPE_LOCATION', 
  DATA_TYPE_IDFA: 'DATA_TYPE_IDFA', 
  DATA_TYPE_HEALTH_AND_FITNESS: 'DATA_TYPE_HEALTH_AND_FITNESS', 
  DATA_TYPE_PASTEBOARD: 'DATA_TYPE_PASTEBOARD', 
  DATA_TYPE_CELLULAR_CARRIER: 'DATA_TYPE_CELLULAR_CARRIER', 
  DATA_TYPE_WIFI_NETWORK: 'DATA_TYPE_WIFI_NETWORK', 
  DATA_TYPE_MAC_ADDRESS: 'DATA_TYPE_MAC_ADDRESS', 
  DATA_TYPE_DEVICE_IDENTIFIER: 'DATA_TYPE_DEVICE_IDENTIFIER', 
  DATA_TYPE_UNKNOWN: 'DATA_TYPE_UNKNOWN'
});


                                                      

export const OnboardingProductsEnumValues = Object.freeze({
  MOBILE: 'MOBILE', 
  WEB: 'WEB', 
  API: 'API', 
  CLOUD: 'CLOUD'
});


                                                                                  

export const OnboardingStepsEnumValues = Object.freeze({
  MOBILE_CI_CD_SCANS: 'MOBILE_CI_CD_SCANS', 
  MOBILE_PROTECT_SDK_INTEGRATION: 'MOBILE_PROTECT_SDK_INTEGRATION', 
  MOBILE_CREDENTIALS: 'MOBILE_CREDENTIALS', 
  MOBILE_SETUP_VISITED: 'MOBILE_SETUP_VISITED', 
  WEB_URLS: 'WEB_URLS', 
  WEB_UPLOAD_LOGS: 'WEB_UPLOAD_LOGS', 
  WEB_SETUP_VISITED: 'WEB_SETUP_VISITED', 
  API_CI_CD_SCANS: 'API_CI_CD_SCANS', 
  API_UPLOAD_SPECS: 'API_UPLOAD_SPECS', 
  API_CLOUD_PLATFORM: 'API_CLOUD_PLATFORM', 
  API_GATEWAY: 'API_GATEWAY', 
  API_SETUP_VISITED: 'API_SETUP_VISITED', 
  API_PROTECT: 'API_PROTECT', 
  CLOUD_CLOUD_PLATFORM: 'CLOUD_CLOUD_PLATFORM', 
  CLOUD_SETUP_VISITED: 'CLOUD_SETUP_VISITED', 
  CLOUD_ALPHASOC_TELEMETRY: 'CLOUD_ALPHASOC_TELEMETRY', 
  API_SAST: 'API_SAST', 
  MOBILE_SAST: 'MOBILE_SAST', 
  WEB_SAST: 'WEB_SAST'
});


                                                                            

export const JiraExportFilterEnumValues = Object.freeze({
  ALL_ISSUES: 'ALL_ISSUES', 
  P1_ISSUES_AND_BLOCKERS: 'P1_ISSUES_AND_BLOCKERS', 
  HIGH_SEVERITY: 'HIGH_SEVERITY', 
  DISABLED: 'DISABLED'
});


                                                                              

export const ScopedAccessTokenTypeEnumValues = Object.freeze({
  APP_SECURE_SHARE_MOBILE_APP_PERMISSIONS: 'APP_SECURE_SHARE_MOBILE_APP_PERMISSIONS', 
  APP_SECURE_SHARE_FINDING_PERMISSIONS: 'APP_SECURE_SHARE_FINDING_PERMISSIONS', 
  APP_SEARCH_SHARE_OPENSCAN_APP_PERMISSIONS: 'APP_SEARCH_SHARE_OPENSCAN_APP_PERMISSIONS'
});


                                                                                        

export const ApiCredentialsAccessLevelEnumValues = Object.freeze({
  ANY: 'ANY', 
  READ_ONLY: 'READ_ONLY', 
  LOW_PRIVILEGES: 'LOW_PRIVILEGES', 
  HIGH_PRIVILEGES: 'HIGH_PRIVILEGES'
});


                                                                                                

export const ApiCredentialsAuthenticationTypeEnumValues = Object.freeze({
  API_KEYS: 'API_KEYS', 
  OAUTH2: 'OAUTH2', 
  DESCRIBED_IN_UPLOADED_FILE: 'DESCRIBED_IN_UPLOADED_FILE', 
  BASIC_AUTH: 'BASIC_AUTH'
});


                                                                                                              

/** Misc Types */
                                
                                 
                                      
                                              
                                                
                                               
  

                                   
                                    
                                                                                         
                                                                                             
                                                                 
                                                                                     
                                                                      
                                                                     
                                                                        
                                                                             
                                                                                       
                                                              
                                                                                    
                                                                                                                               
                                                          
                                                                                                     
                                                                           
                                                                                                                 
                                                                                       
                                                                                         
                                                               
                                                                                                             
                                                                                   
                                                                                              
                                                                    
                                                                                             
                                                                   
                                                                                                
                                                                      
                                                                                                     
                                                                           
                                                                                     
                                                                                                               
  

                               
                                
                                              
                                                
                                                 
                                              
                                                
                                                 
                                  
  

                              
                               
                                                           
                                                                              
                                                                              
                                                             
                                                            
  

                                         
                                          
                                      
                                        
                                            
  

                            
                             
                                            
                                                    
                                                
  

                    
                     
                                                
                                      
                                              
                                              
                                  
                                                   
                                        
                                                          
                                                                  
                                                                
  

                                    
                                     
                                      
                       
  

                                       
                                        
                                      
                       
                                      
  

                                              
                                               
                                                               
                                                                      
  

                               
                                
                                                                    
                                                                 
  

                                             
                                              
                                          
                                           
  

                                     
                                      
                                                
                                                               
                                                     
  

                                        
                                         
                                                
                                                               
  

                                      
                                       
                                                     
                                                                
                                                                       
                                                                  
                                                                     
                                                            
                                                                      
                                                                           
  

/** CORE TYPES */
                         
                          
                                                          
                                                               
                                              
                                               
                                              
                                                
                                                  
                                              
                                  
                                          
                               
                                                            
                                
                                      
                                        
                              
                                       
                                               
                                      
                                                             
                                                                  
                                                      
                                                         
                                                  
                                       
                                                      
                                     
                                                                
                                                                           
                                                               
                                                                          
                                                                      
                                                                      
                                                                
                                                                     
                                                             
                                                          
                                                                              
                                                                     
                                                                        
                                                          
                                                                       
                                               
                                 
  

                                 
                                  
                                             
                                           
                                                  
                                                          
                                                                  
                                                                   
                                                               
                                                                      
                                                                 
                                                                      
                                                                 
                                                                                 
                                                              
                                                                   
                                                               
                                                         
                                                 
                                                                
                                                                 
                                                                
                                                                    
                                                                            
                                                                                  
                                                                         
                                                                                    
                                                          
                                                          
                                                                             
                                                         
                                                            
                                                               
                                                               
                                                           
                                                            
                                                  
                                                     
                                                                        
                                                             
                                                           
                                                                                  
                                                                                  
                                                                           
                                                                        
                                                                             
                                              
                                                      
                                     
                                                    
  

                                      
                                       
                                      
                       
  

                             
                              
                                               
                                            
  

                                
                                 
                                              
                                              
                                                  
                                                               
                                      
                                                    
                                            
                                              
  

                         
                          
                                    
                                                       
  

                                  
                                   
                                              
                                              
                                      
                                          
                                                
                                                 
                                  
                                                        
                                     
                                           
                                                 
                                              
                                            
                                         
                                 
                                                               
  

                                            
                                             
                                                
                                            
  

                                             
                                              
                                           
                                          
  

                           
                            
                                      
                                         
                                               
                                            
                                              
                                         
                                                            
                                                                      
                                                                                                
                                                                                 
                                                              
  

                                     
                                      
                                                
                             
                                            
  

                                     
                                      
                                      
                                         
  

                              
                               
                                  
                                             
                                              
                                                              
                                        
                                            
                                                      
                                                       
                                                     
  

                                     
                                      
                                       
                                                
  

                                
                                 
                                  
                                     
                                         
                                                            
                                                            
                                                        
  

                                             
                                              
                                                
                                                 
  

                                    
                                     
                                              
                                               
                                            
  

                                         
                                          
                                            
                                                                         
                                                                            
                                                                         
                                                                             
                                                  
                                                     
                                                             
                                                                
                                                           
                                                             
                                                         
                                                          
                                                                 
                                                                 
                                                                
  

                                                
                                                 
                                                              
                                                                          
                                                                                      
                                                                                  
                                                                            
                                                                              
                                                                           
                                                                            
                                                       
                                                               
  

                                                     
                                                      
                                                                          
                                                                              
                                                          
                                                                
                                                                       
                                                                
                                                                      
                                                                      
                                                                     
                                                               
                                                                           
                                                             
                                                                        
                                                                     
                                                          
  

                                             
                                              
                                            
                                             
  

                           
                            
                                          
                                         
                                               
                                            
                                         
                                              
                                               
                                                               
  

                           
                            
                                              
                                              
                                  
                                        
                              
                                             
                                               
                                              
                                                          
                                                     
                                             
                                                
                                                       
                                                       
                                      
                                                       
                                                           
                                                             
                                                              
                                                                
                                                     
                                                       
                                                           
                                                    
                                                       
                                                        
                                              
                                                             
                                            
                                                                             
                                                                     
                             
                                       
                                                      
                                             
  

                                       
                                        
                                                   
                                               
                                                       
                                         
                                              
                                              
                            
                                                    
                                                                   
                                                            
                                                  
                                                    
                                                 
  

                                               
                                                
                                                               
                                                
  

                                                               
                                                                
                                       
                                           
                                            
                                                          
                                                            
  

                                                       
                                                        
                                    
                                          
                              
                                                  
                                             
                                                 
                                              
                                               
                                                                        
  

                               
                                
                                                                         
                                  
                                                        
                                                          
                                              
                                                                   
                                              
                                              
                                               
                                                       
                                                    
                                                  
                                  
                                               
                                                 
                                                            
                                                            
                                                 
                                             
                       
                              
                                                    
                            
                         
                                         
                                                 
                                                    
                                                        
                                                 
                                                                         
  

                       
                        
                                          
                                                    
  

                                       
                                        
                                        
                                    
  

                      
                       
                                              
                                              
                                                              
                                                 
                                                  
                                                        
                                  
                                                   
                                        
                                              
  

                                 
                                  
                                  
                                               
                                                
                                             
                                         
                                       
  

                                          
                                           
                                                  
                                                
  

                            
                             
                                  
                                            
                                              
                                         
                                                         
  

                              
                               
                                           
                                        
  

                           
                            
                                             
                                       
  

                               
                                
                                  
                                              
                                              
                                          
                                           
                                                
                                    
                                      
  

                                     
                                      
                                  
                                              
                                        
  

                                           
                                            
                                                     
                                                  
                                                       
                                                         
                                                                      
  

                                                 
                                                  
                                           
                                                   
  

                      
                       
                                                                      
                                              
                                              
                                                         
                                  
                                                   
                                              
                                        
                                                       
                                                  
                                                         
                                                 
                                                           
                                                 
                                              
                                          
                                                 
                                                        
                                                         
                                                                              
  

/** ENDPOINTS */
                                      
                                       
                                                
                                  
  

                                                                     
                                                                      
                                                                        
                                                                                  
                                                                                  
                                                                        
                                                                           
                                                                             
  

                                                                        
                                                                         
                                                           
                                                
                                                         
                                                   
                                                            
                                                             
                                                         
                                                          
  

                                                
                                                 
                                                                        
                                                                                  
                                                                                  
                                                                           
                                                                                  
                                                           
                                                
                                                         
                                                                           
                                                   
                                                            
                                                             
                                                         
                                                          
                                                 
  

                                    
                                     
                                              
                                              
                                                              
                                                 
                                                  
                                                        
                                  
                                        
                                              
  

                              
                               
                                  
                                                        
                                              
                                              
                                               
                                  
                                                     
                                                   
                                       
                                           
  

                                               
                                                
                                                
                                            
  

                                         
                                          
                                                
                                                  
  

                                 
                                  
                                                
                     
  

                                              
                                               
                                                
                                              
  

                                   
                                        
                                                        
                                                                
                                                  
  

                                          
                                                          
  

                                                  
                            
  

                                             
                                                           
  

                                            
                                             
                                                
                                                  
  

                                               
                                                
                                                                  
                                                
  

                                                
                                                 
                                                                  
                                                
  

                                      
                                       
                                  
                                                       
                                                             
                                                
                                              
                                        
                              
                                               
  

                                               
                                                
                                                                      
  

                                          
                                           
                                                
                                             
  

                                                  
                                                   
                                                                      
                                                          
                                                                      
                                                             
                                                         
                                                  
                                                  
                                                                  
                                                              
                                                                      
                                                                                 
                                                                     
                                                                                
                                                                            
                                                                            
                                                                      
                                                                           
                                                                   
                                                                
                                                                                    
                                                                           
                                                                              
                                                                
                                                                             
                                                               
                                                                
                                                           
                                                         
                                                     
                                                      
                                                                 
  

                                         
                                          
                                               
                                                         
                                                 
  

                                                  
                                                   
                                                     
  

                                         
                                          
                                                
                                        
  

                                                   
                                                    
                                                 
                                        
                                          
  

                                               
                                                
                                                    
                                         
  

                                           
                                            
                                                      
  

                                                
                                                 
                                                 
                                        
                                          
  

                                            
                                             
                                                 
                                         
  

                                        
                                         
                                                   
  

                                              
                                               
                                               
                                              
  

                                          
                                           
                                                              
  

                                               
                                                
                                                     
                                                             
                                                 
                                                         
                                                        
                                                    
                                                                     
                                                                             
                                                                                 
                                                    
                                                
                                                       
                                                        
                                                         
                                                        
                                                   
                                                                    
  

                                             
                                              
                                                             
                                                              
                                                                          
                                                             
                                                           
  

                                                           
                                                            
                                             
                                            
                                                 
                                        
                                                
                                           
  

                                            
                                             
                                                
                                               
                                                           
                                            
                                                                    
                                                           
                                                
                                                                 
                                        
  

                                        
                                         
                                                
                                                               
                                                      
  

                                                          
                                                           
                                             
                                              
                                      
                                        
                                            
                                           
  

                                                 
                                                  
                                               
                                                         
                                                   
                                                 
                                  
                                              
                                               
                                               
                                                         
                                                         
  

                                              
                                               
                                               
                                                   
                                                   
                                  
                                              
                                               
                                                         
                                               
  

                                          
                                           
                                                
                                                
  

                                                 
                                                  
                                  
                                              
                                              
                                                     
                                          
  

                                                    
                                                     
                                                
                                                         
  

                                                   
                                                    
                                                
                                                
                           
  

                                                   
                                                    
                                                    
                                                                 
  

                                                  
                                                   
                                                                      
                                              
                                              
                                                         
                                  
                                                   
                                              
                                        
                                                       
                                                  
                                                         
                                                 
                                                           
                                                 
                                              
                                          
                                                 
                                                        
                                                         
                                                                              
  

                                
                                 
                                    
                                      
                                                       
                                                   
                                                
                                          
                                                        
  

                                        
                                         
                                          
                                                
  

                                                     
                                                      
                                          
                                           
                                                    
  

                                                         
                                                          
                                                                                   
  

                                      
                                       
                                              
                                              
                                                        
                                                               
                                                                      
                                                             
                                                        
                                                
                                                 
  

                                                 
                                                  
                                       
  

                                  
                                   
                                                  
                                                
  

                                    
                                     
                                                    
  

                                           
                                            
                                                 
                                              
                                              
  

                                       
                                        
                                      
                                            
                                              
  

                                                    
                                                     
                                           
  

                              
                               
                                                                  
                                             
                                                     
  

                       
                        
                                                   
                                                                              
                                     
  

                                                
                                                 
                                              
                                                 
  

                                                 
                                                  
                                              
                                           
  

                                                               
                                                                
                                              
                                                            
                                                           
                                                              
  

                                     
                                      
                                              
                                                
                                                
                                        
                                                      
                                                  
                                        
                                                
                                                             
                                                       
                                                        
                                              
                                                                           
                                                            
                                                              
  

                                                 
                                                  
                                                     
  

                                         
                                          
                                                        
                                                               
                                                
                                                                               
  

                                         
                                          
                                                        
                                                               
                                                
  

                                         
                                          
                                                     
                                                            
                                                            
                                                
                                              
                                              
                                                
                                      
  

                                             
                                              
                                                           
                                                
  

export const ApplicationLayerProtocolValues = Object.freeze({
  UNKNOWN: 'UNKNOWN', 
  HTTP: 'HTTP'
});


                                                                                      

export const AssetGroupMembershipKindValues = Object.freeze({
  ASSET_SEARCH_MATCH: 'ASSET_SEARCH_MATCH', 
  MANUAL: 'MANUAL', 
  SHADOW_ASSET_MATCH: 'SHADOW_ASSET_MATCH'
});


                                                                                      

export const CloudServiceTypeValues = Object.freeze({
  UNKNOWN: 'UNKNOWN', 
  AWS_S3: 'AWS_S3', 
  AWS_API_GATEWAY: 'AWS_API_GATEWAY', 
  AWS_LAMBDA: 'AWS_LAMBDA', 
  AWS_ROUTE53: 'AWS_ROUTE53', 
  AWS_ELASTICSEARCH: 'AWS_ELASTICSEARCH', 
  AWS_EBS: 'AWS_EBS', 
  AWS_RDS: 'AWS_RDS', 
  AWS_API_GATEWAY_MOCK: 'AWS_API_GATEWAY_MOCK', 
  AWS_API_GATEWAY_HTTP: 'AWS_API_GATEWAY_HTTP', 
  AWS_API_GATEWAY_AWS_INTEGRATION: 'AWS_API_GATEWAY_AWS_INTEGRATION', 
  AWS_API_GATEWAY_HTTP_UPSTREAM: 'AWS_API_GATEWAY_HTTP_UPSTREAM', 
  APIGEE_API_GATEWAY: 'APIGEE_API_GATEWAY', 
  APIGEE_API_GATEWAY_HOSTED_TARGET: 'APIGEE_API_GATEWAY_HOSTED_TARGET', 
  APIGEE_API_GATEWAY_HTTP: 'APIGEE_API_GATEWAY_HTTP', 
  APIGEE_API_GATEWAY_HTTP_UPSTREAM: 'APIGEE_API_GATEWAY_HTTP_UPSTREAM', 
  AZURE_API_MANAGEMENT_SERVICE: 'AZURE_API_MANAGEMENT_SERVICE', 
  AZURE_API_MANAGEMENT_SERVICE_API: 'AZURE_API_MANAGEMENT_SERVICE_API', 
  AZURE_API_MANAGEMENT_HTTP_UPSTREAM: 'AZURE_API_MANAGEMENT_HTTP_UPSTREAM', 
  AZURE_WEB_SITE: 'AZURE_WEB_SITE', 
  AZURE_DNS: 'AZURE_DNS', 
  AZURE_FUNCTION_APP: 'AZURE_FUNCTION_APP', 
  GCP_CLOUD_DNS: 'GCP_CLOUD_DNS', 
  GCP_APP_ENGINE: 'GCP_APP_ENGINE', 
  GCP_CLOUD_STORAGE: 'GCP_CLOUD_STORAGE', 
  GCP_FIREBASE_DATABASE: 'GCP_FIREBASE_DATABASE', 
  GCP_FIREBASE_STORAGE: 'GCP_FIREBASE_STORAGE', 
  GCP_FIREBASE_FUNCTIONS: 'GCP_FIREBASE_FUNCTIONS', 
  GCP_CLOUD_FUNCTIONS: 'GCP_CLOUD_FUNCTIONS'
});


                                                                      

export const DiscoveredViaValues = Object.freeze({
  AMAZON_WEB_SERVICES: 'AMAZON_WEB_SERVICES', 
  APIGEE: 'APIGEE', 
  APIGEE_ON_PREM: 'APIGEE_ON_PREM', 
  CLOUDFLARE: 'CLOUDFLARE', 
  APPLICATION_FINGERPRINTING: 'APPLICATION_FINGERPRINTING', 
  AXWAY: 'AXWAY', 
  AZURE: 'AZURE', 
  CERTIFICATE_TRANSPARENCY: 'CERTIFICATE_TRANSPARENCY', 
  GOOGLE_CLOUD_PLATFORM: 'GOOGLE_CLOUD_PLATFORM', 
  KONG: 'KONG', 
  MANUAL_IMPORT: 'MANUAL_IMPORT', 
  MOBILE_APP_SCANS: 'MOBILE_APP_SCANS', 
  MULESOFT: 'MULESOFT', 
  RESTFUL_API_DISCOVERER: 'RESTFUL_API_DISCOVERER', 
  REVERSE_IP_LOOKUP: 'REVERSE_IP_LOOKUP', 
  SAN_EXTRACTION: 'SAN_EXTRACTION', 
  WEB_APPLICATION_SCANS: 'WEB_APPLICATION_SCANS', 
  WEBSITE_CRAWLER: 'WEBSITE_CRAWLER', 
  SWAGGER_HUB: 'SWAGGER_HUB', 
  MOBILE_APP_STORE: 'MOBILE_APP_STORE', 
  MOBILE_PROTECT_USAGE: 'MOBILE_PROTECT_USAGE', 
  CLOUD_PROTECT: 'CLOUD_PROTECT', 
  KUBERNETES_CLUSTER_ANALYSIS: 'KUBERNETES_CLUSTER_ANALYSIS', 
  GITHUB_INTEGRATION: 'GITHUB_INTEGRATION', 
  GITHUB_ENTERPRISE_SERVER_INTEGRATION: 'GITHUB_ENTERPRISE_SERVER_INTEGRATION', 
  GITLAB_INTEGRATION: 'GITLAB_INTEGRATION', 
  BITBUCKET_INTEGRATION: 'BITBUCKET_INTEGRATION', 
  POSTMAN_CLOUD: 'POSTMAN_CLOUD', 
  AKAMAI: 'AKAMAI',
  SHADOW_RESTFUL_API_DISCOVERER: 'SHADOW_RESTFUL_API_DISCOVERER'
});


                                                                

export const HostedOnValues = Object.freeze({
  AMAZON_WEB_SERVICES: 'AMAZON_WEB_SERVICES', 
  APIGEE: 'APIGEE', 
  AXWAY: 'AXWAY', 
  AZURE: 'AZURE', 
  DETECTION_FAILED: 'DETECTION_FAILED', 
  GOOGLE_CLOUD_PLATFORM: 'GOOGLE_CLOUD_PLATFORM', 
  KONG: 'KONG', 
  MULESOFT: 'MULESOFT', 
  ON_PREMISE: 'ON_PREMISE', 
  UNKNOWN: 'UNKNOWN', 
  GITHUB: 'GITHUB', 
  BITBUCKET: 'BITBUCKET', 
  GITHUB_ENTERPRISE_SERVER: 'GITHUB_ENTERPRISE_SERVER', 
  CLOUDFLARE: 'CLOUDFLARE', 
  AKAMAI: 'AKAMAI'
});


                                                      

export const HttpMethodValues = Object.freeze({
  GET: 'GET', 
  PUT: 'PUT', 
  POST: 'POST', 
  DELETE: 'DELETE', 
  OPTIONS: 'OPTIONS', 
  HEAD: 'HEAD', 
  PATCH: 'PATCH', 
  TRACE: 'TRACE', 
  X_HORIZON_UNDEFINED_HTTP_METHOD: 'X_HORIZON_UNDEFINED_HTTP_METHOD', 
  UNKNOWN: 'UNKNOWN'
});


                                                          

export const PolicyComplianceStandardValues = Object.freeze({
  OWASP: 'OWASP', 
  PCI_DSS: 'PCI_DSS', 
  NIST_800_53: 'NIST_800_53', 
  FFIEC_VC2: 'FFIEC_VC2', 
  CIS_BENCHMARK: 'CIS_BENCHMARK', 
  FEDRAMP_V5_API_SECURITY: 'FEDRAMP_V5_API_SECURITY'
});


                                                                                      

export const PolicyComplianceStandardCriteriaValues = Object.freeze({
  OWASP_A1_2019: 'OWASP_A1_2019', 
  OWASP_A2_2019: 'OWASP_A2_2019', 
  OWASP_A3_2019: 'OWASP_A3_2019', 
  OWASP_A4_2019: 'OWASP_A4_2019', 
  OWASP_A5_2019: 'OWASP_A5_2019', 
  OWASP_A6_2019: 'OWASP_A6_2019', 
  OWASP_A7_2019: 'OWASP_A7_2019', 
  OWASP_A8_2019: 'OWASP_A8_2019', 
  OWASP_A9_2019: 'OWASP_A9_2019', 
  OWASP_A10_2019: 'OWASP_A10_2019', 
  OWASP_W1_2021: 'OWASP_W1_2021', 
  OWASP_W2_2021: 'OWASP_W2_2021', 
  OWASP_W3_2021: 'OWASP_W3_2021', 
  OWASP_W4_2021: 'OWASP_W4_2021', 
  OWASP_W5_2021: 'OWASP_W5_2021', 
  OWASP_W6_2021: 'OWASP_W6_2021', 
  OWASP_W7_2021: 'OWASP_W7_2021', 
  OWASP_W8_2021: 'OWASP_W8_2021', 
  OWASP_W9_2021: 'OWASP_W9_2021', 
  OWASP_W10_2021: 'OWASP_W10_2021', 
  OWASP_R1_2011: 'OWASP_R1_2011', 
  OWASP_R2_2011: 'OWASP_R2_2011', 
  OWASP_R3_2011: 'OWASP_R3_2011', 
  OWASP_R4_2011: 'OWASP_R4_2011', 
  OWASP_R5_2011: 'OWASP_R5_2011', 
  OWASP_R6_2011: 'OWASP_R6_2011', 
  OWASP_R7_2011: 'OWASP_R7_2011', 
  OWASP_R8_2011: 'OWASP_R8_2011', 
  OWASP_R9_2011: 'OWASP_R9_2011', 
  OWASP_R10_2011: 'OWASP_R10_2011', 
  PCI_DSS_v3_2_1_a1_3: 'PCI_DSS_v3_2_1_a1_3', 
  PCI_DSS_v3_2_1_a1_2_1: 'PCI_DSS_v3_2_1_a1_2_1', 
  PCI_DSS_v3_2_1_a2_3: 'PCI_DSS_v3_2_1_a2_3', 
  PCI_DSS_v3_2_1_a2_2_3: 'PCI_DSS_v3_2_1_a2_2_3', 
  PCI_DSS_v3_2_1_a3_4: 'PCI_DSS_v3_2_1_a3_4', 
  PCI_DSS_v3_2_1_a4_1: 'PCI_DSS_v3_2_1_a4_1', 
  PCI_DSS_v3_2_1_a6_1: 'PCI_DSS_v3_2_1_a6_1', 
  PCI_DSS_v3_2_1_a6_5_10: 'PCI_DSS_v3_2_1_a6_5_10', 
  PCI_DSS_v3_2_1_a6_6: 'PCI_DSS_v3_2_1_a6_6', 
  PCI_DSS_v3_2_1_a8_7: 'PCI_DSS_v3_2_1_a8_7', 
  PCI_DSS_v3_2_1_a10_1: 'PCI_DSS_v3_2_1_a10_1', 
  PCI_DSS_v3_2_1_aA_2_1: 'PCI_DSS_v3_2_1_aA_2_1', 
  NIST_800_53_AC_1: 'NIST_800_53_AC_1', 
  NIST_800_53_AC_4: 'NIST_800_53_AC_4', 
  NIST_800_53_AU_11: 'NIST_800_53_AU_11', 
  NIST_800_53_SA_2: 'NIST_800_53_SA_2', 
  NIST_800_53_SA_4: 'NIST_800_53_SA_4', 
  NIST_800_53_SC_1: 'NIST_800_53_SC_1', 
  NIST_800_53_SC_7: 'NIST_800_53_SC_7', 
  NIST_800_53_SC_12: 'NIST_800_53_SC_12', 
  NIST_800_53_SC_16: 'NIST_800_53_SC_16', 
  NIST_800_53_SI_1: 'NIST_800_53_SI_1', 
  NIST_800_53_SI_2: 'NIST_800_53_SI_2', 
  NIST_800_53_CM_2: 'NIST_800_53_CM_2', 
  NIST_800_53_CM_4: 'NIST_800_53_CM_4', 
  OWASP_M1_2014: 'OWASP_M1_2014', 
  OWASP_M2_2014: 'OWASP_M2_2014', 
  OWASP_M3_2014: 'OWASP_M3_2014', 
  OWASP_M4_2014: 'OWASP_M4_2014', 
  OWASP_M5_2014: 'OWASP_M5_2014', 
  OWASP_M6_2014: 'OWASP_M6_2014', 
  OWASP_M7_2014: 'OWASP_M7_2014', 
  OWASP_M8_2014: 'OWASP_M8_2014', 
  OWASP_M9_2014: 'OWASP_M9_2014', 
  OWASP_M10_2014: 'OWASP_M10_2014', 
  OWASP_M1_2016: 'OWASP_M1_2016', 
  OWASP_M2_2016: 'OWASP_M2_2016', 
  OWASP_M3_2016: 'OWASP_M3_2016', 
  OWASP_M4_2016: 'OWASP_M4_2016', 
  OWASP_M5_2016: 'OWASP_M5_2016', 
  OWASP_M6_2016: 'OWASP_M6_2016', 
  OWASP_M7_2016: 'OWASP_M7_2016', 
  OWASP_M8_2016: 'OWASP_M8_2016', 
  OWASP_M9_2016: 'OWASP_M9_2016', 
  OWASP_M10_2016: 'OWASP_M10_2016', 
  OWASP_MSTG_ARCH_1: 'OWASP_MSTG_ARCH_1', 
  OWASP_MSTG_ARCH_2: 'OWASP_MSTG_ARCH_2', 
  OWASP_MSTG_ARCH_3: 'OWASP_MSTG_ARCH_3', 
  OWASP_MSTG_ARCH_4: 'OWASP_MSTG_ARCH_4', 
  OWASP_MSTG_ARCH_5: 'OWASP_MSTG_ARCH_5', 
  OWASP_MSTG_ARCH_6: 'OWASP_MSTG_ARCH_6', 
  OWASP_MSTG_ARCH_7: 'OWASP_MSTG_ARCH_7', 
  OWASP_MSTG_ARCH_8: 'OWASP_MSTG_ARCH_8', 
  OWASP_MSTG_ARCH_9: 'OWASP_MSTG_ARCH_9', 
  OWASP_MSTG_ARCH_10: 'OWASP_MSTG_ARCH_10', 
  OWASP_MSTG_ARCH_11: 'OWASP_MSTG_ARCH_11', 
  OWASP_MSTG_ARCH_12: 'OWASP_MSTG_ARCH_12', 
  OWASP_MSTG_STORAGE_1: 'OWASP_MSTG_STORAGE_1', 
  OWASP_MSTG_STORAGE_2: 'OWASP_MSTG_STORAGE_2', 
  OWASP_MSTG_STORAGE_3: 'OWASP_MSTG_STORAGE_3', 
  OWASP_MSTG_STORAGE_4: 'OWASP_MSTG_STORAGE_4', 
  OWASP_MSTG_STORAGE_5: 'OWASP_MSTG_STORAGE_5', 
  OWASP_MSTG_STORAGE_6: 'OWASP_MSTG_STORAGE_6', 
  OWASP_MSTG_STORAGE_7: 'OWASP_MSTG_STORAGE_7', 
  OWASP_MSTG_STORAGE_8: 'OWASP_MSTG_STORAGE_8', 
  OWASP_MSTG_STORAGE_9: 'OWASP_MSTG_STORAGE_9', 
  OWASP_MSTG_STORAGE_10: 'OWASP_MSTG_STORAGE_10', 
  OWASP_MSTG_STORAGE_11: 'OWASP_MSTG_STORAGE_11', 
  OWASP_MSTG_STORAGE_12: 'OWASP_MSTG_STORAGE_12', 
  OWASP_MSTG_STORAGE_13: 'OWASP_MSTG_STORAGE_13', 
  OWASP_MSTG_STORAGE_14: 'OWASP_MSTG_STORAGE_14', 
  OWASP_MSTG_STORAGE_15: 'OWASP_MSTG_STORAGE_15', 
  OWASP_MSTG_CRYPTO_1: 'OWASP_MSTG_CRYPTO_1', 
  OWASP_MSTG_CRYPTO_2: 'OWASP_MSTG_CRYPTO_2', 
  OWASP_MSTG_CRYPTO_3: 'OWASP_MSTG_CRYPTO_3', 
  OWASP_MSTG_CRYPTO_4: 'OWASP_MSTG_CRYPTO_4', 
  OWASP_MSTG_CRYPTO_5: 'OWASP_MSTG_CRYPTO_5', 
  OWASP_MSTG_CRYPTO_6: 'OWASP_MSTG_CRYPTO_6', 
  OWASP_MSTG_AUTH_1: 'OWASP_MSTG_AUTH_1', 
  OWASP_MSTG_AUTH_2: 'OWASP_MSTG_AUTH_2', 
  OWASP_MSTG_AUTH_3: 'OWASP_MSTG_AUTH_3', 
  OWASP_MSTG_AUTH_4: 'OWASP_MSTG_AUTH_4', 
  OWASP_MSTG_AUTH_5: 'OWASP_MSTG_AUTH_5', 
  OWASP_MSTG_AUTH_6: 'OWASP_MSTG_AUTH_6', 
  OWASP_MSTG_AUTH_7: 'OWASP_MSTG_AUTH_7', 
  OWASP_MSTG_AUTH_8: 'OWASP_MSTG_AUTH_8', 
  OWASP_MSTG_AUTH_9: 'OWASP_MSTG_AUTH_9', 
  OWASP_MSTG_AUTH_10: 'OWASP_MSTG_AUTH_10', 
  OWASP_MSTG_AUTH_11: 'OWASP_MSTG_AUTH_11', 
  OWASP_MSTG_AUTH_12: 'OWASP_MSTG_AUTH_12', 
  OWASP_MSTG_NETWORK_1: 'OWASP_MSTG_NETWORK_1', 
  OWASP_MSTG_NETWORK_2: 'OWASP_MSTG_NETWORK_2', 
  OWASP_MSTG_NETWORK_3: 'OWASP_MSTG_NETWORK_3', 
  OWASP_MSTG_NETWORK_4: 'OWASP_MSTG_NETWORK_4', 
  OWASP_MSTG_NETWORK_5: 'OWASP_MSTG_NETWORK_5', 
  OWASP_MSTG_NETWORK_6: 'OWASP_MSTG_NETWORK_6', 
  OWASP_MSTG_PLATFORM_1: 'OWASP_MSTG_PLATFORM_1', 
  OWASP_MSTG_PLATFORM_2: 'OWASP_MSTG_PLATFORM_2', 
  OWASP_MSTG_PLATFORM_3: 'OWASP_MSTG_PLATFORM_3', 
  OWASP_MSTG_PLATFORM_4: 'OWASP_MSTG_PLATFORM_4', 
  OWASP_MSTG_PLATFORM_5: 'OWASP_MSTG_PLATFORM_5', 
  OWASP_MSTG_PLATFORM_6: 'OWASP_MSTG_PLATFORM_6', 
  OWASP_MSTG_PLATFORM_7: 'OWASP_MSTG_PLATFORM_7', 
  OWASP_MSTG_PLATFORM_8: 'OWASP_MSTG_PLATFORM_8', 
  OWASP_MSTG_PLATFORM_9: 'OWASP_MSTG_PLATFORM_9', 
  OWASP_MSTG_PLATFORM_10: 'OWASP_MSTG_PLATFORM_10', 
  OWASP_MSTG_PLATFORM_11: 'OWASP_MSTG_PLATFORM_11', 
  OWASP_MSTG_CODE_1: 'OWASP_MSTG_CODE_1', 
  OWASP_MSTG_CODE_2: 'OWASP_MSTG_CODE_2', 
  OWASP_MSTG_CODE_3: 'OWASP_MSTG_CODE_3', 
  OWASP_MSTG_CODE_4: 'OWASP_MSTG_CODE_4', 
  OWASP_MSTG_CODE_5: 'OWASP_MSTG_CODE_5', 
  OWASP_MSTG_CODE_6: 'OWASP_MSTG_CODE_6', 
  OWASP_MSTG_CODE_7: 'OWASP_MSTG_CODE_7', 
  OWASP_MSTG_CODE_8: 'OWASP_MSTG_CODE_8', 
  OWASP_MSTG_CODE_9: 'OWASP_MSTG_CODE_9', 
  OWASP_MSTG_RESILIENCE_1: 'OWASP_MSTG_RESILIENCE_1', 
  OWASP_MSTG_RESILIENCE_2: 'OWASP_MSTG_RESILIENCE_2', 
  OWASP_MSTG_RESILIENCE_3: 'OWASP_MSTG_RESILIENCE_3', 
  OWASP_MSTG_RESILIENCE_4: 'OWASP_MSTG_RESILIENCE_4', 
  OWASP_MSTG_RESILIENCE_5: 'OWASP_MSTG_RESILIENCE_5', 
  OWASP_MSTG_RESILIENCE_6: 'OWASP_MSTG_RESILIENCE_6', 
  OWASP_MSTG_RESILIENCE_7: 'OWASP_MSTG_RESILIENCE_7', 
  OWASP_MSTG_RESILIENCE_8: 'OWASP_MSTG_RESILIENCE_8', 
  OWASP_MSTG_RESILIENCE_9: 'OWASP_MSTG_RESILIENCE_9', 
  OWASP_MSTG_RESILIENCE_10: 'OWASP_MSTG_RESILIENCE_10', 
  OWASP_MSTG_RESILIENCE_11: 'OWASP_MSTG_RESILIENCE_11', 
  OWASP_MSTG_RESILIENCE_12: 'OWASP_MSTG_RESILIENCE_12', 
  OWASP_MSTG_RESILIENCE_13: 'OWASP_MSTG_RESILIENCE_13', 
  OWASP_ASVS_V_1_1: 'OWASP_ASVS_V_1_1', 
  OWASP_ASVS_V_1_2: 'OWASP_ASVS_V_1_2', 
  OWASP_ASVS_V_1_3: 'OWASP_ASVS_V_1_3', 
  OWASP_ASVS_V_1_4: 'OWASP_ASVS_V_1_4', 
  OWASP_ASVS_V_1_5: 'OWASP_ASVS_V_1_5', 
  OWASP_ASVS_V_1_6: 'OWASP_ASVS_V_1_6', 
  OWASP_ASVS_V_1_7: 'OWASP_ASVS_V_1_7', 
  OWASP_ASVS_V_1_8: 'OWASP_ASVS_V_1_8', 
  OWASP_ASVS_V_1_9: 'OWASP_ASVS_V_1_9', 
  OWASP_ASVS_V_1_10: 'OWASP_ASVS_V_1_10', 
  OWASP_ASVS_V_1_11: 'OWASP_ASVS_V_1_11', 
  OWASP_ASVS_V_1_12: 'OWASP_ASVS_V_1_12', 
  OWASP_ASVS_V_1_13: 'OWASP_ASVS_V_1_13', 
  OWASP_ASVS_V_1_14: 'OWASP_ASVS_V_1_14', 
  OWASP_ASVS_V_2_1: 'OWASP_ASVS_V_2_1', 
  OWASP_ASVS_V_2_2: 'OWASP_ASVS_V_2_2', 
  OWASP_ASVS_V_2_3: 'OWASP_ASVS_V_2_3', 
  OWASP_ASVS_V_2_4: 'OWASP_ASVS_V_2_4', 
  OWASP_ASVS_V_2_5: 'OWASP_ASVS_V_2_5', 
  OWASP_ASVS_V_2_6: 'OWASP_ASVS_V_2_6', 
  OWASP_ASVS_V_2_7: 'OWASP_ASVS_V_2_7', 
  OWASP_ASVS_V_2_8: 'OWASP_ASVS_V_2_8', 
  OWASP_ASVS_V_2_9: 'OWASP_ASVS_V_2_9', 
  OWASP_ASVS_V_2_10: 'OWASP_ASVS_V_2_10', 
  OWASP_ASVS_V_3_1: 'OWASP_ASVS_V_3_1', 
  OWASP_ASVS_V_3_2: 'OWASP_ASVS_V_3_2', 
  OWASP_ASVS_V_3_3: 'OWASP_ASVS_V_3_3', 
  OWASP_ASVS_V_3_4: 'OWASP_ASVS_V_3_4', 
  OWASP_ASVS_V_3_5: 'OWASP_ASVS_V_3_5', 
  OWASP_ASVS_V_3_6: 'OWASP_ASVS_V_3_6', 
  OWASP_ASVS_V_3_7: 'OWASP_ASVS_V_3_7', 
  OWASP_ASVS_V_3_8: 'OWASP_ASVS_V_3_8', 
  OWASP_ASVS_V_3_9: 'OWASP_ASVS_V_3_9', 
  OWASP_ASVS_V_3_10: 'OWASP_ASVS_V_3_10', 
  OWASP_ASVS_V_4_1: 'OWASP_ASVS_V_4_1', 
  OWASP_ASVS_V_4_2: 'OWASP_ASVS_V_4_2', 
  OWASP_ASVS_V_4_3: 'OWASP_ASVS_V_4_3', 
  OWASP_ASVS_V_4_4: 'OWASP_ASVS_V_4_4', 
  OWASP_ASVS_V_4_5: 'OWASP_ASVS_V_4_5', 
  OWASP_ASVS_V_4_6: 'OWASP_ASVS_V_4_6', 
  OWASP_ASVS_V_4_7: 'OWASP_ASVS_V_4_7', 
  OWASP_ASVS_V_4_8: 'OWASP_ASVS_V_4_8', 
  OWASP_ASVS_V_4_9: 'OWASP_ASVS_V_4_9', 
  OWASP_ASVS_V_4_10: 'OWASP_ASVS_V_4_10', 
  OWASP_ASVS_V_5_1: 'OWASP_ASVS_V_5_1', 
  OWASP_ASVS_V_5_2: 'OWASP_ASVS_V_5_2', 
  OWASP_ASVS_V_5_3: 'OWASP_ASVS_V_5_3', 
  OWASP_ASVS_V_5_4: 'OWASP_ASVS_V_5_4', 
  OWASP_ASVS_V_5_5: 'OWASP_ASVS_V_5_5', 
  OWASP_ASVS_V_5_6: 'OWASP_ASVS_V_5_6', 
  OWASP_ASVS_V_5_7: 'OWASP_ASVS_V_5_7', 
  OWASP_ASVS_V_5_8: 'OWASP_ASVS_V_5_8', 
  OWASP_ASVS_V_5_9: 'OWASP_ASVS_V_5_9', 
  OWASP_ASVS_V_5_10: 'OWASP_ASVS_V_5_10', 
  OWASP_ASVS_V_6_1: 'OWASP_ASVS_V_6_1', 
  OWASP_ASVS_V_6_2: 'OWASP_ASVS_V_6_2', 
  OWASP_ASVS_V_6_3: 'OWASP_ASVS_V_6_3', 
  OWASP_ASVS_V_6_4: 'OWASP_ASVS_V_6_4', 
  OWASP_ASVS_V_6_5: 'OWASP_ASVS_V_6_5', 
  OWASP_ASVS_V_6_6: 'OWASP_ASVS_V_6_6', 
  OWASP_ASVS_V_6_7: 'OWASP_ASVS_V_6_7', 
  OWASP_ASVS_V_6_8: 'OWASP_ASVS_V_6_8', 
  OWASP_ASVS_V_6_9: 'OWASP_ASVS_V_6_9', 
  OWASP_ASVS_V_6_10: 'OWASP_ASVS_V_6_10', 
  OWASP_ASVS_V_7_1: 'OWASP_ASVS_V_7_1', 
  OWASP_ASVS_V_7_2: 'OWASP_ASVS_V_7_2', 
  OWASP_ASVS_V_7_3: 'OWASP_ASVS_V_7_3', 
  OWASP_ASVS_V_7_4: 'OWASP_ASVS_V_7_4', 
  OWASP_ASVS_V_7_5: 'OWASP_ASVS_V_7_5', 
  OWASP_ASVS_V_7_6: 'OWASP_ASVS_V_7_6', 
  OWASP_ASVS_V_7_7: 'OWASP_ASVS_V_7_7', 
  OWASP_ASVS_V_7_8: 'OWASP_ASVS_V_7_8', 
  OWASP_ASVS_V_7_9: 'OWASP_ASVS_V_7_9', 
  OWASP_ASVS_V_7_10: 'OWASP_ASVS_V_7_10', 
  OWASP_ASVS_V_8_1: 'OWASP_ASVS_V_8_1', 
  OWASP_ASVS_V_8_2: 'OWASP_ASVS_V_8_2', 
  OWASP_ASVS_V_8_3: 'OWASP_ASVS_V_8_3', 
  OWASP_ASVS_V_8_4: 'OWASP_ASVS_V_8_4', 
  OWASP_ASVS_V_8_5: 'OWASP_ASVS_V_8_5', 
  OWASP_ASVS_V_8_6: 'OWASP_ASVS_V_8_6', 
  OWASP_ASVS_V_8_7: 'OWASP_ASVS_V_8_7', 
  OWASP_ASVS_V_8_8: 'OWASP_ASVS_V_8_8', 
  OWASP_ASVS_V_8_9: 'OWASP_ASVS_V_8_9', 
  OWASP_ASVS_V_8_10: 'OWASP_ASVS_V_8_10', 
  OWASP_ASVS_V_9_1: 'OWASP_ASVS_V_9_1', 
  OWASP_ASVS_V_9_2: 'OWASP_ASVS_V_9_2', 
  OWASP_ASVS_V_9_3: 'OWASP_ASVS_V_9_3', 
  OWASP_ASVS_V_9_4: 'OWASP_ASVS_V_9_4', 
  OWASP_ASVS_V_9_5: 'OWASP_ASVS_V_9_5', 
  OWASP_ASVS_V_9_6: 'OWASP_ASVS_V_9_6', 
  OWASP_ASVS_V_9_7: 'OWASP_ASVS_V_9_7', 
  OWASP_ASVS_V_9_8: 'OWASP_ASVS_V_9_8', 
  OWASP_ASVS_V_9_9: 'OWASP_ASVS_V_9_9', 
  OWASP_ASVS_V_9_10: 'OWASP_ASVS_V_9_10', 
  OWASP_ASVS_V_10_1: 'OWASP_ASVS_V_10_1', 
  OWASP_ASVS_V_10_2: 'OWASP_ASVS_V_10_2', 
  OWASP_ASVS_V_10_3: 'OWASP_ASVS_V_10_3', 
  OWASP_ASVS_V_10_4: 'OWASP_ASVS_V_10_4', 
  OWASP_ASVS_V_10_5: 'OWASP_ASVS_V_10_5', 
  OWASP_ASVS_V_10_6: 'OWASP_ASVS_V_10_6', 
  OWASP_ASVS_V_10_7: 'OWASP_ASVS_V_10_7', 
  OWASP_ASVS_V_10_8: 'OWASP_ASVS_V_10_8', 
  OWASP_ASVS_V_10_9: 'OWASP_ASVS_V_10_9', 
  OWASP_ASVS_V_10_10: 'OWASP_ASVS_V_10_10', 
  OWASP_ASVS_V_11_1: 'OWASP_ASVS_V_11_1', 
  OWASP_ASVS_V_11_2: 'OWASP_ASVS_V_11_2', 
  OWASP_ASVS_V_11_3: 'OWASP_ASVS_V_11_3', 
  OWASP_ASVS_V_11_4: 'OWASP_ASVS_V_11_4', 
  OWASP_ASVS_V_11_5: 'OWASP_ASVS_V_11_5', 
  OWASP_ASVS_V_11_6: 'OWASP_ASVS_V_11_6', 
  OWASP_ASVS_V_11_7: 'OWASP_ASVS_V_11_7', 
  OWASP_ASVS_V_11_8: 'OWASP_ASVS_V_11_8', 
  OWASP_ASVS_V_11_9: 'OWASP_ASVS_V_11_9', 
  OWASP_ASVS_V_11_10: 'OWASP_ASVS_V_11_10', 
  OWASP_ASVS_V_12_1: 'OWASP_ASVS_V_12_1', 
  OWASP_ASVS_V_12_2: 'OWASP_ASVS_V_12_2', 
  OWASP_ASVS_V_12_3: 'OWASP_ASVS_V_12_3', 
  OWASP_ASVS_V_12_4: 'OWASP_ASVS_V_12_4', 
  OWASP_ASVS_V_12_5: 'OWASP_ASVS_V_12_5', 
  OWASP_ASVS_V_12_6: 'OWASP_ASVS_V_12_6', 
  OWASP_ASVS_V_12_7: 'OWASP_ASVS_V_12_7', 
  OWASP_ASVS_V_12_8: 'OWASP_ASVS_V_12_8', 
  OWASP_ASVS_V_12_9: 'OWASP_ASVS_V_12_9', 
  OWASP_ASVS_V_12_10: 'OWASP_ASVS_V_12_10', 
  OWASP_ASVS_V_13_1: 'OWASP_ASVS_V_13_1', 
  OWASP_ASVS_V_13_2: 'OWASP_ASVS_V_13_2', 
  OWASP_ASVS_V_13_3: 'OWASP_ASVS_V_13_3', 
  OWASP_ASVS_V_13_4: 'OWASP_ASVS_V_13_4', 
  OWASP_ASVS_V_13_5: 'OWASP_ASVS_V_13_5', 
  OWASP_ASVS_V_13_6: 'OWASP_ASVS_V_13_6', 
  OWASP_ASVS_V_13_7: 'OWASP_ASVS_V_13_7', 
  OWASP_ASVS_V_13_8: 'OWASP_ASVS_V_13_8', 
  OWASP_ASVS_V_13_9: 'OWASP_ASVS_V_13_9', 
  OWASP_ASVS_V_13_10: 'OWASP_ASVS_V_13_10', 
  OWASP_ASVS_V_14_1: 'OWASP_ASVS_V_14_1', 
  OWASP_ASVS_V_14_2: 'OWASP_ASVS_V_14_2', 
  OWASP_ASVS_V_14_3: 'OWASP_ASVS_V_14_3', 
  OWASP_ASVS_V_14_4: 'OWASP_ASVS_V_14_4', 
  OWASP_ASVS_V_14_5: 'OWASP_ASVS_V_14_5', 
  OWASP_ASVS_V_14_6: 'OWASP_ASVS_V_14_6', 
  OWASP_ASVS_V_14_7: 'OWASP_ASVS_V_14_7', 
  OWASP_ASVS_V_14_8: 'OWASP_ASVS_V_14_8', 
  OWASP_ASVS_V_14_9: 'OWASP_ASVS_V_14_9', 
  OWASP_ASVS_V_14_10: 'OWASP_ASVS_V_14_10', 
  FFIEC_VC2_REQUIREMENT_1: 'FFIEC_VC2_REQUIREMENT_1', 
  FFIEC_VC2_REQUIREMENT_2: 'FFIEC_VC2_REQUIREMENT_2', 
  FFIEC_VC2_REQUIREMENT_3: 'FFIEC_VC2_REQUIREMENT_3', 
  FFIEC_VC2_REQUIREMENT_4: 'FFIEC_VC2_REQUIREMENT_4', 
  FFIEC_VC2_REQUIREMENT_5: 'FFIEC_VC2_REQUIREMENT_5', 
  FFIEC_VC2_REQUIREMENT_6: 'FFIEC_VC2_REQUIREMENT_6', 
  FFIEC_VC2_REQUIREMENT_7: 'FFIEC_VC2_REQUIREMENT_7', 
  FFIEC_VC2_REQUIREMENT_8: 'FFIEC_VC2_REQUIREMENT_8', 
  FFIEC_VC2_REQUIREMENT_9: 'FFIEC_VC2_REQUIREMENT_9', 
  CIS_BENCHMARK_GOOGLE_CLOUD_PLATFORM_1: 'CIS_BENCHMARK_GOOGLE_CLOUD_PLATFORM_1', 
  CIS_BENCHMARK_GOOGLE_CLOUD_PLATFORM_3: 'CIS_BENCHMARK_GOOGLE_CLOUD_PLATFORM_3', 
  CIS_BENCHMARK_GOOGLE_CLOUD_PLATFORM_4: 'CIS_BENCHMARK_GOOGLE_CLOUD_PLATFORM_4', 
  CIS_BENCHMARK_GOOGLE_CLOUD_PLATFORM_5: 'CIS_BENCHMARK_GOOGLE_CLOUD_PLATFORM_5', 
  CIS_BENCHMARK_GOOGLE_CLOUD_PLATFORM_6: 'CIS_BENCHMARK_GOOGLE_CLOUD_PLATFORM_6', 
  CIS_BENCHMARK_KUBERNETES_1: 'CIS_BENCHMARK_KUBERNETES_1', 
  CIS_BENCHMARK_KUBERNETES_3: 'CIS_BENCHMARK_KUBERNETES_3', 
  CIS_BENCHMARK_KUBERNETES_5: 'CIS_BENCHMARK_KUBERNETES_5', 
  CIS_BENCHMARK_AWS_1: 'CIS_BENCHMARK_AWS_1', 
  CIS_BENCHMARK_AWS_2: 'CIS_BENCHMARK_AWS_2', 
  CIS_BENCHMARK_AWS_3: 'CIS_BENCHMARK_AWS_3', 
  CIS_BENCHMARK_AZURE_3: 'CIS_BENCHMARK_AZURE_3', 
  CIS_BENCHMARK_AZURE_4: 'CIS_BENCHMARK_AZURE_4', 
  CIS_BENCHMARK_AZURE_6: 'CIS_BENCHMARK_AZURE_6', 
  CIS_BENCHMARK_AZURE_7: 'CIS_BENCHMARK_AZURE_7', 
  CIS_BENCHMARK_AZURE_8: 'CIS_BENCHMARK_AZURE_8', 
  CIS_BENCHMARK_AZURE_9: 'CIS_BENCHMARK_AZURE_9', 
  FEDRAMP_V5_API_SECURITY_SAST: 'FEDRAMP_V5_API_SECURITY_SAST', 
  FEDRAMP_V5_API_SECURITY_DAST: 'FEDRAMP_V5_API_SECURITY_DAST'
});


                                                                                                      

export const PolicyRuleGroupValues = Object.freeze({
  REST_API_AND_SERVERLESS: 'REST_API_AND_SERVERLESS', 
  CLOUD_SECURITY: 'CLOUD_SECURITY', 
  WEB_APPS: 'WEB_APPS', 
  ENCRYPTION: 'ENCRYPTION', 
  MOBILE: 'MOBILE', 
  KSPM: 'KSPM', 
  CIEM: 'CIEM', 
  SAST: 'SAST', 
  API_DEFINITION_STATIC_ANALYSIS: 'API_DEFINITION_STATIC_ANALYSIS', 
  FUNCTIONAL_API_SECURITY_TEST: 'FUNCTIONAL_API_SECURITY_TEST', 
  POSTMAN_PROTECT: 'POSTMAN_PROTECT'
});


                                                                    

export const JiraViolationsAutoExportSettingEnumValues = Object.freeze({
  AUTO_EXPORT_NONE: 'AUTO_EXPORT_NONE', 
  AUTO_EXPORT_HIGH_SEVERITY: 'AUTO_EXPORT_HIGH_SEVERITY', 
  AUTO_EXPORT_HIGH_AND_MEDIUM_SEVERITY: 'AUTO_EXPORT_HIGH_AND_MEDIUM_SEVERITY', 
  AUTO_EXPORT_ALL: 'AUTO_EXPORT_ALL'
});


                                                                                                            

export const PolicyRuleTypeRelevanceValues = Object.freeze({
  INFORMATIONAL: 'INFORMATIONAL', 
  PROACTIVE: 'PROACTIVE', 
  IMPORTANT: 'IMPORTANT', 
  URGENT: 'URGENT'
});


                                                                                    

export const PolicyRuleTypeStatusValues = Object.freeze({
  ENABLED: 'ENABLED', 
  DELETED_BY_USER: 'DELETED_BY_USER'
});


                                                                              

export const PolicyViolationExceptionValues = Object.freeze({
  WONT_FIX: 'WONT_FIX', 
  POLICY_RULE_DELETED: 'POLICY_RULE_DELETED'
});


                                                                                      

export const WebRequestTypeValues = Object.freeze({
  XHR: 'XHR', 
  FETCH: 'FETCH', 
  FONT: 'FONT', 
  SCRIPT: 'SCRIPT', 
  STYLESHEET: 'STYLESHEET', 
  IMAGE: 'IMAGE', 
  MEDIA: 'MEDIA', 
  DOCUMENT: 'DOCUMENT', 
  TEXTTRACK: 'TEXTTRACK', 
  EVENTSOURCE: 'EVENTSOURCE', 
  WEBSOCKET: 'WEBSOCKET', 
  MANIFEST: 'MANIFEST', 
  OTHER: 'OTHER'
});


                                                                  

export const WebApplicationTypeValues = Object.freeze({
  UNKNOWN: 'UNKNOWN', 
  SINGLE_PAGE: 'SINGLE_PAGE', 
  TRADITIONAL: 'TRADITIONAL', 
  HYBRID: 'HYBRID'
});


                                                                          

export const WebApplicationStatusEnumValues = Object.freeze({
  ONLINE: 'ONLINE', 
  NO_LONGER_ACCESSIBLE: 'NO_LONGER_ACCESSIBLE'
});


                                                                                      

export const GraphQlApiStatusEnumValues = Object.freeze({
  ONLINE: 'ONLINE', 
  NO_LONGER_ACCESSIBLE: 'NO_LONGER_ACCESSIBLE'
});


                                                                              

export const RestfulApiStatusEnumValues = Object.freeze({
  ONLINE: 'ONLINE', 
  NO_LONGER_ACCESSIBLE: 'NO_LONGER_ACCESSIBLE'
});


                                                                              

export const NetworkServiceStatusEnumValues = Object.freeze({
  ONLINE: 'ONLINE', 
  NO_LONGER_ACCESSIBLE: 'NO_LONGER_ACCESSIBLE'
});


                                                                                      

export const VendorMobileSdkStatusEnumValues = Object.freeze({
  DETECTED: 'DETECTED', 
  NO_LONGER_ACCESSIBLE: 'NO_LONGER_ACCESSIBLE'
});


                                                                                        

export const WeekdayValues = Object.freeze({
  MONDAY: 'MONDAY', 
  TUESDAY: 'TUESDAY', 
  WEDNESDAY: 'WEDNESDAY', 
  THURSDAY: 'THURSDAY', 
  FRIDAY: 'FRIDAY', 
  SATURDAY: 'SATURDAY', 
  SUNDAY: 'SUNDAY'
});


                                                    

export const SpecialScanStatusValues = Object.freeze({
  NOT_STARTED: 'NOT_STARTED', 
  ONGOING: 'ONGOING', 
  FINISHED: 'FINISHED', 
  COMPLETED: 'COMPLETED'
});


                                                                        

export const SpecialScanTypeValues = Object.freeze({
  HACK_AND_EXTRACT: 'HACK_AND_EXTRACT', 
  LEAKY_APIS: 'LEAKY_APIS', 
  DETECT_AND_INJECT: 'DETECT_AND_INJECT', 
  XSS_PROTECTION: 'XSS_PROTECTION', 
  SPA_DETECT_AND_INJECT: 'SPA_DETECT_AND_INJECT'
});


                                                                    

export const PolicyViolationStatusEnumValues = Object.freeze({
  OPEN: 'OPEN', 
  WONT_FIX: 'WONT_FIX', 
  RESOLVED: 'RESOLVED', 
  DELETED: 'DELETED'
});


                                                                                        

export const IacScanFilterEnumValues = Object.freeze({
  TERRAFORM: 'TERRAFORM'
});


                                                                        

export const TlsScanTypeValues = Object.freeze({
  SUPPORTS_SSL_2_0: 'SUPPORTS_SSL_2_0', 
  SUPPORTS_SSL_3_0: 'SUPPORTS_SSL_3_0', 
  SUPPORTS_TLS_1_0: 'SUPPORTS_TLS_1_0', 
  SUPPORTS_TLS_1_1: 'SUPPORTS_TLS_1_1', 
  SUPPORTS_TLS_1_2: 'SUPPORTS_TLS_1_2', 
  SUPPORTS_TLS_1_3: 'SUPPORTS_TLS_1_3', 
  SUPPORTS_OCSP_STAPLING: 'SUPPORTS_OCSP_STAPLING', 
  SUPPORTS_SESSION_RESUMPTION: 'SUPPORTS_SESSION_RESUMPTION', 
  SUPPORTS_COMPRESSION: 'SUPPORTS_COMPRESSION', 
  SUPPORTS_SECURE_RENEGOTIATION: 'SUPPORTS_SECURE_RENEGOTIATION', 
  SUPPORTS_TLS_1_3_EARLY_DATA: 'SUPPORTS_TLS_1_3_EARLY_DATA', 
  VULNERABLE_TO_HEARTBLEED: 'VULNERABLE_TO_HEARTBLEED', 
  VULNERABLE_TO_CCS_INJECTION: 'VULNERABLE_TO_CCS_INJECTION', 
  VULNERABLE_TO_ROBOT: 'VULNERABLE_TO_ROBOT'
});


                                                            

export const SecurityPropertiesEnumValues = Object.freeze({
  MATCHES_HOSTNAME: 'MATCHES_HOSTNAME', 
  IS_EXPIRING: 'IS_EXPIRING', 
  SUPPORTS_MUST_STAPLE: 'SUPPORTS_MUST_STAPLE', 
  EMBEDS_CERTIFICATE_TRANSPARENCY_SCT: 'EMBEDS_CERTIFICATE_TRANSPARENCY_SCT', 
  VULNERABLE_TO_SHA1_COLLISION: 'VULNERABLE_TO_SHA1_COLLISION', 
  RSA_KEY_SMALLER_THAN_2048_BITS: 'RSA_KEY_SMALLER_THAN_2048_BITS', 
  LEGACY_SYMANTEC_ANCHOR: 'LEGACY_SYMANTEC_ANCHOR'
});


                                                                                  

export const NetworkServiceConnectivityScanStatusEnumValues = Object.freeze({
  REACHABLE: 'REACHABLE', 
  NOT_REACHABLE: 'NOT_REACHABLE', 
  NOT_REACHABLE_CONNECTION_REJECTED: 'NOT_REACHABLE_CONNECTION_REJECTED', 
  NOT_REACHABLE_CONNECTION_TIMED_OUT: 'NOT_REACHABLE_CONNECTION_TIMED_OUT', 
  NOT_REACHABLE_DNS_LOOKUP_FAILED: 'NOT_REACHABLE_DNS_LOOKUP_FAILED'
});


                                                                                                                      

export const CredentialTriagingDecisionEnumValues = Object.freeze({
  NONE: 'NONE', 
  MONITORED: 'MONITORED', 
  IGNORED: 'IGNORED'
});


                                                                                                  

export const ShouldAddAssetsRecentlyDiscoveredViaSourceValues = Object.freeze({
  ALL_SOURCES: 'ALL_SOURCES', 
  PUBLIC_INTERNET_ANALYZER: 'PUBLIC_INTERNET_ANALYZER'
});


                                                                                                                          

export const CloudAuthenticatorCategoryValues = Object.freeze({
  CLOUD_ACCOUNT: 'CLOUD_ACCOUNT', 
  API_GATEWAY_ACCOUNT: 'API_GATEWAY_ACCOUNT', 
  API_DOCUMENTATION_ACCOUNT: 'API_DOCUMENTATION_ACCOUNT'
});


                                                                                          

export const CloudAuthenticatorStatusValues = Object.freeze({
  ENABLED: 'ENABLED', 
  ACCESS_ERROR: 'ACCESS_ERROR', 
  DELETED: 'DELETED', 
  MISSING_PERMISSIONS: 'MISSING_PERMISSIONS', 
  SCANS_PAUSED: 'SCANS_PAUSED'
});


                                                                                      

export const CloudAuthenticatorTypeValues = Object.freeze({
  APIGEE: 'APIGEE', 
  APIGEE_HYBRID: 'APIGEE_HYBRID', 
  APIGEE_ON_PREM: 'APIGEE_ON_PREM', 
  AMAZON_WEB_SERVICES: 'AMAZON_WEB_SERVICES', 
  AZURE: 'AZURE', 
  GOOGLE_CLOUD_PLATFORM: 'GOOGLE_CLOUD_PLATFORM', 
  MULESOFT: 'MULESOFT', 
  KONG: 'KONG', 
  AXWAY: 'AXWAY', 
  SWAGGER_HUB: 'SWAGGER_HUB', 
  KUBERNETES: 'KUBERNETES', 
  TERRAFORM_CLOUD: 'TERRAFORM_CLOUD', 
  AKAMAI: 'AKAMAI', 
  CLOUDFLARE: 'CLOUDFLARE'
});


                                                                                  

export const AutoRemediationAttemptStatusValues = Object.freeze({
  CREATED: 'CREATED', 
  STARTED: 'STARTED', 
  SUCCEEDED: 'SUCCEEDED', 
  FAILED: 'FAILED'
});


                                                                                              

export const AlertsIntegrationsServiceValues = Object.freeze({
  SLACK: 'SLACK', 
  MICROSOFT_TEAMS: 'MICROSOFT_TEAMS'
});


                                                                                        

export const AssetGroupsSearchIncludeEnumValues = Object.freeze({
  policy_violation_stats: 'policy_violation_stats', 
  assets_overview: 'assets_overview'
});


                                                                                              

export const PolicyRulesIncludeEnumValues = Object.freeze({
  policy_violation_stats: 'policy_violation_stats'
});


                                                                                  

export const CloudResourceStatusValues = Object.freeze({
  ONLINE: 'ONLINE', 
  NO_LONGER_ACCESSIBLE: 'NO_LONGER_ACCESSIBLE'
});


                                                                            

export const MetricTypesValues = Object.freeze({
  FULL_STACK_METRICS: 'FULL_STACK_METRICS', 
  WEB_APPLICATION_XSS_TOOLKIT_METRICS: 'WEB_APPLICATION_XSS_TOOLKIT_METRICS', 
  CLOUD_RESOURCE_CATEGORIES: 'CLOUD_RESOURCE_CATEGORIES', 
  API_RADAR: 'API_RADAR', 
  WEB_RESOURCES_OVERVIEW: 'WEB_RESOURCES_OVERVIEW'
});


                                                            

export const AuthenticatoTypeValues = Object.freeze({
  APIGEE: 'APIGEE', 
  APIGEE_ON_PREM: 'APIGEE_ON_PREM', 
  AMAZON_WEB_SERVICES: 'AMAZON_WEB_SERVICES', 
  AZURE: 'AZURE', 
  GOOGLE_CLOUD_PLATFORM: 'GOOGLE_CLOUD_PLATFORM', 
  MULESOFT: 'MULESOFT', 
  KONG: 'KONG', 
  AXWAY: 'AXWAY'
});


                                                                      

export const AssetStatusValues = Object.freeze({
  ONLINE: 'ONLINE', 
  NO_LONGER_ACCESSIBLE: 'NO_LONGER_ACCESSIBLE', 
  EXCLUDED_BY_CUSTOMER: 'EXCLUDED_BY_CUSTOMER'
});


                                                            

export const CloudProviderValues = Object.freeze({
  UNKNOWN: 'UNKNOWN', 
  APIGEE: 'APIGEE', 
  AMAZON_WEB_SERVICES: 'AMAZON_WEB_SERVICES', 
  AZURE: 'AZURE', 
  GOOGLE_CLOUD_PLATFORM: 'GOOGLE_CLOUD_PLATFORM', 
  MULESOFT: 'MULESOFT', 
  KONG: 'KONG', 
  AXWAY: 'AXWAY', 
  ON_PREMISE: 'ON_PREMISE'
});


                                                                

export const ResourceTypeEnumValues = Object.freeze({
  APIGEE_API_PROXY: 'APIGEE_API_PROXY', 
  AWS_API_GATEWAY_API: 'AWS_API_GATEWAY_API', 
  AWS_API_GATEWAY_API_STAGE: 'AWS_API_GATEWAY_API_STAGE', 
  AWS_ATHENA_WORKGROUP: 'AWS_ATHENA_WORKGROUP', 
  AWS_CLOUD_FRONT: 'AWS_CLOUD_FRONT', 
  AWS_CLOUD_TRAIL: 'AWS_CLOUD_TRAIL', 
  AWS_CODEBUILD_PROJECT: 'AWS_CODEBUILD_PROJECT', 
  AWS_DMS_INSTANCE: 'AWS_DMS_INSTANCE', 
  AWS_DOCDB_INSTANCE: 'AWS_DOCDB_INSTANCE', 
  AWS_DYNAMODB_DAX_CLUSTER: 'AWS_DYNAMODB_DAX_CLUSTER', 
  AWS_DYNAMODB_TABLE: 'AWS_DYNAMODB_TABLE', 
  AWS_EBS_SNAPSHOT: 'AWS_EBS_SNAPSHOT', 
  AWS_EBS_VOLUME: 'AWS_EBS_VOLUME', 
  AWS_EC2_AMI_IMAGE: 'AWS_EC2_AMI_IMAGE', 
  AWS_EC2_AUTOSCALING_GROUP: 'AWS_EC2_AUTOSCALING_GROUP', 
  AWS_EC2_VIRTUAL_MACHINE: 'AWS_EC2_VIRTUAL_MACHINE', 
  AWS_EFS_FILESYSTEM: 'AWS_EFS_FILESYSTEM', 
  AWS_EKS_KUBERNETES_CLUSTER: 'AWS_EKS_KUBERNETES_CLUSTER', 
  AWS_ELASTICACHE_MEMCACHED_CLUSTER: 'AWS_ELASTICACHE_MEMCACHED_CLUSTER', 
  AWS_ELASTICACHE_REDIS_CLUSTER: 'AWS_ELASTICACHE_REDIS_CLUSTER', 
  AWS_ELASTICSEARCH_DATABASE: 'AWS_ELASTICSEARCH_DATABASE', 
  AWS_ELB_LOAD_BALANCER: 'AWS_ELB_LOAD_BALANCER', 
  AWS_ELB_V2_LOAD_BALANCER: 'AWS_ELB_V2_LOAD_BALANCER', 
  AWS_EMR_CLUSTER: 'AWS_EMR_CLUSTER', 
  AWS_IAM_PASSWORD_POLICY: 'AWS_IAM_PASSWORD_POLICY', 
  AWS_IAM_USER: 'AWS_IAM_USER', 
  AWS_KINESIS_STREAM: 'AWS_KINESIS_STREAM', 
  AWS_KMS_KEY: 'AWS_KMS_KEY', 
  AWS_LAMBDA_FUNCTION: 'AWS_LAMBDA_FUNCTION', 
  AWS_RDS_CLUSTER: 'AWS_RDS_CLUSTER', 
  AWS_RDS_CLUSTER_SNAPSHOT: 'AWS_RDS_CLUSTER_SNAPSHOT', 
  AWS_RDS_INSTANCE: 'AWS_RDS_INSTANCE', 
  AWS_RDS_INSTANCE_SNAPSHOT: 'AWS_RDS_INSTANCE_SNAPSHOT', 
  AWS_REDSHIFT_CLUSTER: 'AWS_REDSHIFT_CLUSTER', 
  AWS_ROUTE53_DOMAIN: 'AWS_ROUTE53_DOMAIN', 
  AWS_S3_BUCKET: 'AWS_S3_BUCKET', 
  AWS_S3_BUCKET_ACCESS_POINT: 'AWS_S3_BUCKET_ACCESS_POINT', 
  AWS_SAGE_MAKER: 'AWS_SAGE_MAKER', 
  AWS_SNS_TOPIC: 'AWS_SNS_TOPIC', 
  AWS_SQS_QUEUE: 'AWS_SQS_QUEUE', 
  AWS_VPC: 'AWS_VPC', 
  AWS_WORKSPACE: 'AWS_WORKSPACE', 
  AXWAY_API_BACKEND: 'AXWAY_API_BACKEND', 
  AXWAY_API_PROXY: 'AXWAY_API_PROXY', 
  AZURE_API_MANAGEMENT_SERVICE: 'AZURE_API_MANAGEMENT_SERVICE', 
  AZURE_API_MANAGEMENT_SERVICE_API: 'AZURE_API_MANAGEMENT_SERVICE_API', 
  AZURE_APPLICATION_GATEWAY: 'AZURE_APPLICATION_GATEWAY', 
  AZURE_AUTOMATION_VARIABLE: 'AZURE_AUTOMATION_VARIABLE', 
  AZURE_CDN_ENDPOINT: 'AZURE_CDN_ENDPOINT', 
  AZURE_CLOUD_SERVICE: 'AZURE_CLOUD_SERVICE', 
  AZURE_COSMOSDB_INSTANCE: 'AZURE_COSMOSDB_INSTANCE', 
  AZURE_FUNCTION: 'AZURE_FUNCTION', 
  AZURE_FUNCTION_APP: 'AZURE_FUNCTION_APP', 
  AZURE_KEY_VAULT: 'AZURE_KEY_VAULT', 
  AZURE_KEY_VAULT_SECRET: 'AZURE_KEY_VAULT_SECRET', 
  AZURE_KUBERNETES_SERVICE_MANAGED_CLUSTER: 'AZURE_KUBERNETES_SERVICE_MANAGED_CLUSTER', 
  AZURE_MARIADB_INSTANCE: 'AZURE_MARIADB_INSTANCE', 
  AZURE_MARIADB_SERVER: 'AZURE_MARIADB_SERVER', 
  AZURE_POSTGRESQL_DATABASE: 'AZURE_POSTGRESQL_DATABASE', 
  AZURE_POSTGRESQL_SERVER: 'AZURE_POSTGRESQL_SERVER', 
  AZURE_REDIS_CACHE: 'AZURE_REDIS_CACHE', 
  AZURE_SERVICE_FABRIC_CLUSTER: 'AZURE_SERVICE_FABRIC_CLUSTER', 
  AZURE_SQLDB_INSTANCE: 'AZURE_SQLDB_INSTANCE', 
  AZURE_SQLDB_SERVER: 'AZURE_SQLDB_SERVER', 
  AZURE_STORAGE_ACCOUNT: 'AZURE_STORAGE_ACCOUNT', 
  AZURE_STORAGE_ACCOUNT_BLOB_CONTAINER: 'AZURE_STORAGE_ACCOUNT_BLOB_CONTAINER', 
  AZURE_VIRTUAL_MACHINE: 'AZURE_VIRTUAL_MACHINE', 
  AZURE_WEB_APP: 'AZURE_WEB_APP', 
  FINGERPRINTED_AWS_S3_BUCKET: 'FINGERPRINTED_AWS_S3_BUCKET', 
  FINGERPRINTED_AZURE_STORAGE_ACCOUNT_BLOB_CONTAINER: 'FINGERPRINTED_AZURE_STORAGE_ACCOUNT_BLOB_CONTAINER', 
  FINGERPRINTED_CASSANDRA_DB_DATABASE: 'FINGERPRINTED_CASSANDRA_DB_DATABASE', 
  FINGERPRINTED_CONFLUENCE_SERVER: 'FINGERPRINTED_CONFLUENCE_SERVER', 
  FINGERPRINTED_COUCHDB_DATABASE: 'FINGERPRINTED_COUCHDB_DATABASE', 
  FINGERPRINTED_DOCKER_REGISTRY: 'FINGERPRINTED_DOCKER_REGISTRY', 
  FINGERPRINTED_ELASTICSEARCH_DATABASE: 'FINGERPRINTED_ELASTICSEARCH_DATABASE', 
  FINGERPRINTED_GCP_CLOUD_STORAGE_BUCKET: 'FINGERPRINTED_GCP_CLOUD_STORAGE_BUCKET', 
  FINGERPRINTED_JENKINS_SERVER: 'FINGERPRINTED_JENKINS_SERVER', 
  FINGERPRINTED_MICROSOFT_EXCHANGE_SERVER: 'FINGERPRINTED_MICROSOFT_EXCHANGE_SERVER', 
  FINGERPRINTED_MONGODB_INSTANCE: 'FINGERPRINTED_MONGODB_INSTANCE', 
  FINGERPRINTED_REDIS_DATABASE: 'FINGERPRINTED_REDIS_DATABASE', 
  FINGERPRINTED_SOLR_DATABASE: 'FINGERPRINTED_SOLR_DATABASE', 
  GCP_APIGEE_HYBRID_API_PROXY: 'GCP_APIGEE_HYBRID_API_PROXY', 
  GCP_APP_ENGINE_SERVICE: 'GCP_APP_ENGINE_SERVICE', 
  GCP_CLOUD_FUNCTION: 'GCP_CLOUD_FUNCTION', 
  GCP_CLOUD_RUN_INSTANCE: 'GCP_CLOUD_RUN_INSTANCE', 
  GCP_CLOUD_SQL_INSTANCE: 'GCP_CLOUD_SQL_INSTANCE', 
  GCP_CLOUD_STORAGE_BUCKET: 'GCP_CLOUD_STORAGE_BUCKET', 
  GCP_FIREBASE_FIRESTORE_DATABASE: 'GCP_FIREBASE_FIRESTORE_DATABASE', 
  GCP_FIREBASE_FUNCTION: 'GCP_FIREBASE_FUNCTION', 
  GCP_FIREBASE_REALTIME_DATABASE: 'GCP_FIREBASE_REALTIME_DATABASE', 
  GCP_FIREBASE_STORAGE_BUCKET: 'GCP_FIREBASE_STORAGE_BUCKET', 
  GCP_KMS_KEY: 'GCP_KMS_KEY', 
  GCP_KUBERNETES_ENGINE_CLUSTER: 'GCP_KUBERNETES_ENGINE_CLUSTER', 
  GCP_SECRET_MANAGER_SECRET: 'GCP_SECRET_MANAGER_SECRET', 
  GCP_VIRTUAL_MACHINE: 'GCP_VIRTUAL_MACHINE', 
  KONG_API_BACKEND: 'KONG_API_BACKEND', 
  KONG_API_PROXY: 'KONG_API_PROXY', 
  MULESOFT_APPLICATION: 'MULESOFT_APPLICATION'
});


                                                                      

export const AssetOrderByValues = Object.freeze({
  policy_violations_count_asc: 'policy_violations_count_asc', 
  policy_violations_count_desc: 'policy_violations_count_desc', 
  date_created_desc: 'date_created_desc', 
  date_created_asc: 'date_created_asc'
});


                                                              

export const CloudResourceOrderByValues = Object.freeze({
  date_created_asc: 'date_created_asc', 
  date_created_desc: 'date_created_desc', 
  break_glass_order: 'break_glass_order', 
  cloud_events_count_desc: 'cloud_events_count_desc'
});


                                                                              

export const MobileAppOrderByValues = Object.freeze({
  policy_violations_count_asc: 'policy_violations_count_asc', 
  policy_violations_count_desc: 'policy_violations_count_desc', 
  platform_asc: 'platform_asc', 
  platform_desc: 'platform_desc', 
  last_scanned_via_asc: 'last_scanned_via_asc', 
  last_scanned_via_desc: 'last_scanned_via_desc', 
  name_asc: 'name_asc', 
  name_desc: 'name_desc', 
  active_protection_status_asc: 'active_protection_status_asc', 
  active_protection_status_desc: 'active_protection_status_desc', 
  vulnerable_hacker_toolkits_desc: 'vulnerable_hacker_toolkits_desc', 
  vulnerable_hacker_toolkits_asc: 'vulnerable_hacker_toolkits_asc'
});


                                                                      

export const ToolkitScanTypeEnumValues = Object.freeze({
  APP_STORE_PRIVACY_GLOBAL_REPORT: 'APP_STORE_PRIVACY_GLOBAL_REPORT', 
  APP_STORE_PRIVACY_SPECIFIC_APPS_REPORT: 'APP_STORE_PRIVACY_SPECIFIC_APPS_REPORT', 
  BREAK_GLASS: 'BREAK_GLASS', 
  CLOUD_DLP: 'CLOUD_DLP', 
  CLOUD_LEAKS: 'CLOUD_LEAKS', 
  CLOUD_RANSOMWARE_ENCRYPT: 'CLOUD_RANSOMWARE_ENCRYPT', 
  CLOUD_RANSOMWARE_STORAGE_SCAN: 'CLOUD_RANSOMWARE_STORAGE_SCAN', 
  CLOUD_RANSOMWARE_UPLOAD_MALWARE: 'CLOUD_RANSOMWARE_UPLOAD_MALWARE', 
  CODE_RED: 'CODE_RED', 
  COMPLIANCE_REPORTS: 'COMPLIANCE_REPORTS', 
  DETECT_AND_INJECT: 'DETECT_AND_INJECT', 
  LEAKY_APIS: 'LEAKY_APIS', 
  WEB_APPLICATION_XSS: 'WEB_APPLICATION_XSS'
});


                                                                            

export const WebApplicationXssScanIntensityEnumValues = Object.freeze({
  LOW: 'LOW', 
  MEDIUM: 'MEDIUM', 
  HIGH: 'HIGH'
});


                                                                                                          

export const XssToolkitResultEnumValues = Object.freeze({
  VULNERABLE: 'VULNERABLE', 
  NOT_VULNERABLE: 'NOT_VULNERABLE', 
  WATCHLIST: 'WATCHLIST'
});


                                                                              

export const AssetTypeEnumValues = Object.freeze({
  DOMAIN_NAME: 'DOMAIN_NAME', 
  NETWORK_SERVICE: 'NETWORK_SERVICE', 
  RESTFUL_API: 'RESTFUL_API', 
  CLOUD_RESOURCE: 'CLOUD_RESOURCE', 
  WEB_APPLICATION: 'WEB_APPLICATION', 
  GRAPHQL_API: 'GRAPHQL_API', 
  API_OPERATION: 'API_OPERATION', 
  MOBILE_APPLICATION: 'MOBILE_APPLICATION', 
  MOBILE_SDK: 'MOBILE_SDK', 
  GRPC_SERVICE: 'GRPC_SERVICE', 
  GRPC_METHOD: 'GRPC_METHOD', 
  KUBERNETES_CLUSTER: 'KUBERNETES_CLUSTER', 
  KUBERNETES_CLUSTER_COMPONENT: 'KUBERNETES_CLUSTER_COMPONENT', 
  SOAP_API: 'SOAP_API', 
  SOAP_API_OPERATION: 'SOAP_API_OPERATION', 
  REPOSITORY: 'REPOSITORY', 
  CLOUD_IDENTITY: 'CLOUD_IDENTITY'
});


                                                                

export const SharedLinksCloudResourceTypeEnumValues = Object.freeze({
  CLOUD_AUTHENTICATORS_ADD: 'CLOUD_AUTHENTICATORS_ADD', 
  SINGLE_POLICY_VIOLATION_READ: 'SINGLE_POLICY_VIOLATION_READ', 
  SINGLE_NETWORK_SERVICE_READ: 'SINGLE_NETWORK_SERVICE_READ', 
  SINGLE_RESTFUL_API_READ: 'SINGLE_RESTFUL_API_READ', 
  SINGLE_CLOUD_RESOURCE_READ: 'SINGLE_CLOUD_RESOURCE_READ', 
  SINGLE_WEB_APPLICATION_READ: 'SINGLE_WEB_APPLICATION_READ', 
  CROSS_PRODUCT_ONBOARDING: 'CROSS_PRODUCT_ONBOARDING'
});


                                                                                                      

export const ApplicationLayerProtocolEnumValues = Object.freeze({
  UNKNOWN: 'UNKNOWN', 
  HTTP: 'HTTP', 
  SSH: 'SSH', 
  REDIS: 'REDIS', 
  MONGODB: 'MONGODB'
});


                                                                                              

export const ActiveProtectionStatusEnumValues = Object.freeze({
  PROTECTION_DISABLED: 'PROTECTION_DISABLED', 
  PROTECTION_ENABLED: 'PROTECTION_ENABLED', 
  ACTIVE_PROTECTION_ENABLED: 'ACTIVE_PROTECTION_ENABLED', 
  NO_PROTECTION: 'NO_PROTECTION',
  OBSERVABILITY_MODE: 'OBSERVABILITY_MODE'
});


                                                                                          

export const BulkActionTypeValues = Object.freeze({
  ASSETS_MOVE_TO_ASSET_GROUP: 'ASSETS_MOVE_TO_ASSET_GROUP'
});


                                                                  

export const ApplePrivacyDataTypeEnumValues = Object.freeze({
  APPLE_PRIVACY_DATA_TYPE_CONTACT_INFO: 'APPLE_PRIVACY_DATA_TYPE_CONTACT_INFO', 
  APPLE_PRIVACY_DATA_TYPE_HEALTH_AND_FITNESS: 'APPLE_PRIVACY_DATA_TYPE_HEALTH_AND_FITNESS', 
  APPLE_PRIVACY_DATA_TYPE_FINANCIAL_INFO: 'APPLE_PRIVACY_DATA_TYPE_FINANCIAL_INFO', 
  APPLE_PRIVACY_DATA_TYPE_LOCATION: 'APPLE_PRIVACY_DATA_TYPE_LOCATION', 
  APPLE_PRIVACY_DATA_TYPE_SENSITIVE_INFO: 'APPLE_PRIVACY_DATA_TYPE_SENSITIVE_INFO', 
  APPLE_PRIVACY_DATA_TYPE_CONTACTS: 'APPLE_PRIVACY_DATA_TYPE_CONTACTS', 
  APPLE_PRIVACY_DATA_TYPE_USER_CONTENT: 'APPLE_PRIVACY_DATA_TYPE_USER_CONTENT', 
  APPLE_PRIVACY_DATA_TYPE_BROWSING_HISTORY: 'APPLE_PRIVACY_DATA_TYPE_BROWSING_HISTORY', 
  APPLE_PRIVACY_DATA_TYPE_SEARCH_HISTORY: 'APPLE_PRIVACY_DATA_TYPE_SEARCH_HISTORY', 
  APPLE_PRIVACY_DATA_TYPE_IDENTIFIERS: 'APPLE_PRIVACY_DATA_TYPE_IDENTIFIERS', 
  APPLE_PRIVACY_DATA_TYPE_PURCHASES: 'APPLE_PRIVACY_DATA_TYPE_PURCHASES', 
  APPLE_PRIVACY_DATA_TYPE_USAGE_DATA: 'APPLE_PRIVACY_DATA_TYPE_USAGE_DATA', 
  APPLE_PRIVACY_DATA_TYPE_DIAGNOSTICS: 'APPLE_PRIVACY_DATA_TYPE_DIAGNOSTICS', 
  APPLE_PRIVACY_DATA_TYPE_OTHER_DATA: 'APPLE_PRIVACY_DATA_TYPE_OTHER_DATA'
});


                                                                                      

export const SearchDomainNamesIncludeEnumValues = Object.freeze({
  code_red_stats: 'code_red_stats', 
  discovered_urls_stats: 'discovered_urls_stats'
});


                                                                                              

export const PostmanCheckScanStatusEnumValues = Object.freeze({
  IN_PROGRESS: 'IN_PROGRESS', 
  FAILED: 'FAILED', 
  COMPLETED: 'COMPLETED'
});


                                                                                          

export const PostmanOnboardingStatusEnumValues = Object.freeze({
  PENDING_POSTMAN_COLLECTION: 'PENDING_POSTMAN_COLLECTION', 
  INVALID_POSTMAN_COLLECTION: 'INVALID_POSTMAN_COLLECTION', 
  REQUESTED_BY_CUSTOMER_AND_PENDING: 'REQUESTED_BY_CUSTOMER_AND_PENDING', 
  REQUESTED_BY_CUSTOMER_AND_BLOCKED: 'REQUESTED_BY_CUSTOMER_AND_BLOCKED', 
  AUTHENTICATION_ERROR: 'AUTHENTICATION_ERROR', 
  PROCESSED: 'PROCESSED'
});


                                                                                            

export const PostmanPurposeEnumValues = Object.freeze({
  AUTHENTICATION_FLOW: 'AUTHENTICATION_FLOW', 
  CUSTOM_CHECK: 'CUSTOM_CHECK'
});


                                                                          

export const PortalStatusDisplayStyleEnumValues = Object.freeze({
  INFO: 'INFO', 
  OK: 'OK', 
  WARNING: 'WARNING', 
  ALERT: 'ALERT'
});


                                                                                              

export const BulkActionsActionTypeEnumValues = Object.freeze({
  ASSETS_MOVE_TO_ASSET_GROUP: 'ASSETS_MOVE_TO_ASSET_GROUP', 
  ASSETS_SHADOW_ASSETS_TRIAGE: 'ASSETS_SHADOW_ASSETS_TRIAGE', 
  ASSETS_UPDATE_ASSET_TAGS: 'ASSETS_UPDATE_ASSET_TAGS', 
  POLICY_VIOLATIONS_SECURE_SHARE: 'POLICY_VIOLATIONS_SECURE_SHARE'
});


                                                                                        

export const BulkActionsStatusEnumValues = Object.freeze({
  COMPLETED: 'COMPLETED', 
  ERROR: 'ERROR', 
  ONGOING: 'ONGOING'
});


                                                                                

export const AssetTagImportSourceEnumValues = Object.freeze({
  AWS_IMPORT: 'AWS_IMPORT', 
  GCP_IMPORT: 'GCP_IMPORT', 
  AZURE_IMPORT: 'AZURE_IMPORT'
});


                                                                                      

export const KnownMalwareTypeEnumValues = Object.freeze({
  STANDARD_MALWARE_TEST_FILE: 'STANDARD_MALWARE_TEST_FILE', 
  OTHER: 'OTHER'
});


                                                                              

export const StanfordDishUniProductEnumValues = Object.freeze({
  API_SECURE: 'API_SECURE', 
  WEB_SECURE: 'WEB_SECURE', 
  CLOUD_SECURE: 'CLOUD_SECURE', 
  MOBILE_SECURE: 'MOBILE_SECURE', 
  SUPPLY_CHAIN_SECURE: 'SUPPLY_CHAIN_SECURE'
});


                                                                                          

export const StanfordDishUniUserAttendanceEnumUpdateValues = Object.freeze({
  NOT_ATTENDING: 'NOT_ATTENDING', 
  PENDING: 'PENDING', 
  ATTENDING: 'ATTENDING'
});


                                                                                                                    

export const StanfordDishUniUserAttendanceEnumValues = Object.freeze({
  NOT_ATTENDING: 'NOT_ATTENDING', 
  PENDING: 'PENDING', 
  ATTENDING: 'ATTENDING', 
  COMPLETED: 'COMPLETED'
});


                                                                                                        

export const StanfordDishUniCategoryEnumValues = Object.freeze({
  MOBILE_APPLICATION_SECURITY: 'MOBILE_APPLICATION_SECURITY', 
  API_SECURITY: 'API_SECURITY', 
  WEB_APP_SECURITY: 'WEB_APP_SECURITY', 
  CLOUD_APP_SECURITY: 'CLOUD_APP_SECURITY', 
  SUPPLY_CHAIN_SECURITY: 'SUPPLY_CHAIN_SECURITY'
});


                                                                                            

export const StanfordDishUniMaterialTypeEnumValues = Object.freeze({
  VIDEO: 'VIDEO', 
  PDF: 'PDF', 
  ARTICLE: 'ARTICLE', 
  EBOOK: 'EBOOK', 
  YOUTUBE_VIDEO: 'YOUTUBE_VIDEO', 
  ZOOM_RECORDING: 'ZOOM_RECORDING', 
  GOOGLE_DRIVE_VIDEO: 'GOOGLE_DRIVE_VIDEO'
});


                                                                                                    

export const StanfordDishUniUserViewingStatusEnumValues = Object.freeze({
  NOT_VIEWED: 'NOT_VIEWED', 
  VIEWED: 'VIEWED'
});


                                                                                                              

export const AssetTypeGroupValues = Object.freeze({
  API: 'API', 
  MOBILE_SDK: 'MOBILE_SDK', 
  CLOUD_RESOURCE: 'CLOUD_RESOURCE'
});


                                                                  

export const VendorListOrderByEnumValues = Object.freeze({
  number_of_apps_related_to_vendor: 'number_of_apps_related_to_vendor', 
  number_of_apps_related_to_vendor_asc: 'number_of_apps_related_to_vendor_asc', 
  number_of_apps_related_to_vendor_desc: 'number_of_apps_related_to_vendor_desc', 
  open_policy_violations_related_to_vendor: 'open_policy_violations_related_to_vendor', 
  open_policy_violations_related_to_vendor_asc: 'open_policy_violations_related_to_vendor_asc', 
  open_policy_violations_related_to_vendor_desc: 'open_policy_violations_related_to_vendor_desc', 
  assets_belonging_to_vendor: 'assets_belonging_to_vendor', 
  assets_belonging_to_vendor_asc: 'assets_belonging_to_vendor_asc', 
  assets_belonging_to_vendor_desc: 'assets_belonging_to_vendor_desc', 
  date_created_asc: 'date_created_asc', 
  date_created_desc: 'date_created_desc', 
  name_asc: 'name_asc', 
  name_desc: 'name_desc'
});


                                                                                

export const VendorsListIncludeValues = Object.freeze({
  supply_chain_secure_stats: 'supply_chain_secure_stats', 
  supply_chain_secure_dashboard_metrics: 'supply_chain_secure_dashboard_metrics'
});


                                                                          

export const SupplyChainSecureAppTypeEnumValues = Object.freeze({
  MOBILE_APPLICATION: 'MOBILE_APPLICATION', 
  WEB_APPLICATION: 'WEB_APPLICATION'
});


                                                                                              

export const ThirdPartyAssetTypeGroupEnumValues = Object.freeze({
  API: 'API', 
  CLOUD_RESOURCE: 'CLOUD_RESOURCE', 
  MOBILE_SDK: 'MOBILE_SDK'
});


                                                                                              

export const SupplyChainSecureAppOrderByEnumValues = Object.freeze({
  NUMBER_OF_RELATED_ASSETS: 'NUMBER_OF_RELATED_ASSETS'
});


                                                                                                    

export const LeakyApiStatsStatusEnumValues = Object.freeze({
  PROTECTED: 'PROTECTED', 
  UNPROTECTED: 'UNPROTECTED', 
  NO_DATA: 'NO_DATA', 
  UNPROTECTED_BY_DESIGN: 'UNPROTECTED_BY_DESIGN'
});


                                                                                    

export const ExceptionTypeValues = Object.freeze({
  WONT_FIX: 'WONT_FIX'
});


                                                                

/** Misc Types */
                                    
                                     
                                                     
                                                        
                                                     
  

                                     
                                      
                                                
                              
  

                                       
                                        
                                                        
                                                          
  

                                           
                                            
                                                   
                                                   
                                                   
                                                        
  

                                                       
                                                        
                                                                
                                                     
                                                     
                                                       
                                                                
  

                                                   
                                                    
                                                                                 
  

                                  
                                   
                                      
                                          
                                             
                                              
                                                        
                                               
                                                      
  

                                              
                                               
                                                      
                                                
  

                       
                        
                                      
                                              
                                          
                                             
                                                               
                                              
  

                                         
                                          
                                                
                                                                 
                                               
                                        
  

                                             
                                              
                                              
  

                                       
                                        
                                                                      
                                            
                                          
                                                       
                                             
  

                           
                            
                                                            
                                                   
  

                           
                            
                                            
                                       
  

                                 
                                  
                                         
                                                  
                                                             
                                           
                                                
                                                      
                                          
                                                  
                                                                 
                                                                    
  

                          
                           
                                        
                                        
                                               
                                            
  

                                
                                 
                    
                                          
                                                            
                                             
                                                              
                                               
  

                             
                              
                                                  
                                               
  

                           
                            
                                  
                                              
                                                             
                                                              
                                                                
                                                            
                                                 
                                                  
                                                        
                                                  
                                                  
                                                 
                                                    
                                                      
                                                            
                                                             
  

                                                
                                                 
                               
                                          
                                       
  

                                
                                 
                                  
                                              
                                                
                                                                
                                  
                                                               
                                                      
                                   
  

                       
                        
                                  
                                              
                                             
                                                           
                                                 
                                                       
                                                
  

/** CORE TYPES */
                     
                      
                                  
                                              
                                              
                                                    
                                         
               
                                                    
                                    
                                                      
                                                
                                                
                            
                                                   
                                  
                                                         
                                                                 
                                                       
                                                      
                                                        
                                                        
                                                      
                                                           
                                                        
                                                       
                                                  
                                
                     
                                            
                                                     
                                      
                                                
                                           
                                             
                                                    
                                    
                                                       
                                                
                                                        
                                                             
  

                                          
                                           
                                  
                                           
                                     
                                            
                                          
                                                   
                                              
                                              
                                              
  

                                        
                                         
                                  
                                           
                                   
                                            
                                          
                                                   
                                              
                                              
                                              
  

                                     
                                      
                                  
                                                
                                
                                       
                                                
                                                 
  

                            
                             
                                  
                                        
                          
                                                       
                                                           
                                            
                                              
                                              
                          
                                                       
                        
                                                           
  

                                           
                                            
                                                                
                                                               
                                                         
                                                   
  

                              
                               
                                  
                                                       
                                                   
                                                         
                                              
                                               
                                                     
                                                                         
                                                                         
                                                                      
                                        
                                                  
                                                     
                                          
                                                          
  

                             
                              
                                                                         
                                                                         
                                                                      
                                  
                                        
                                               
                                                 
                                          
                                                       
                                                   
                                              
                                                          
  

                             
                              
                                                                         
                                                                         
                                                                      
                                  
                                        
                                                   
                                                     
                                                    
                                                    
                                                                 
                                          
                                                       
                                                   
                                              
                                                          
  

                                    
                                     
                                  
                                              
                                            
                                              
                                                  
                                                
                            
                                                    
                                    
                                                   
                                  
                                                    
                                    
  

                               
                                
                                  
                                         
                                         
                                                  
                                              
  

                                
                                 
                                  
                                                
                                                
                                              
  

                                      
                                       
                                  
                                              
                                                  
                                                
                                                        
                                                             
  

                             
                              
                                  
                                            
                                           
                                                
                                              
  

                             
                              
                                  
                                                  
                                              
                                                  
                                                     
                                                    
  

                              
                               
                                  
                                                  
                                              
                                                          
  

                                  
                                   
                                  
                                                  
                                              
                                                     
                                                      
  

                               
                                
                                  
                                                  
                                              
                                                     
                                               
  

                                             
                                              
                                   
                                        
                                                      
                                  
                                      
                                      
                                  
                                    
                                            
                                               
  

                                         
                                          
                                                                             
  

                                  
                                   
                                                     
                                             
                                                    
                                                            
                                              
                                  
                                   
                                                         
                                                    
  

                                 
                                  
                                  
                                                      
                                        
                                              
                                          
                                               
  

                                     
                                      
                                                            
                                                                     
                                                          
                                                           
                                  
  

                                                 
                                                  
                                                                         
  

                                                  
                                                   
                                                                     
                                                                   
                                                                         
                                                                       
  

                                         
                                          
                                                                            
                                                                    
                                                                               
                                                              
                                                              
                                                                         
                                                                               
                                                                            
                                                                            
                                                            
                                                      
                                                 
                                                  
  

                                                    
                                                     
                                                                            
                                                                         
  

                                                                    
                                                                     
                                                            
                                                   
  

                             
                              
                                  
                                         
                                        
                              
                                                             
                        
                                             
                                            
                                                 
                                                   
                                                       
                                                           
                                             
                                              
                                                     
                                                       
                                
                                                                 
                                                                        
                                                                     
                                                                       
                                                                       
                                                                     
                                                                          
                                                        
                                             
                                                
                                                     
                                                 
                                              
                                                         
                                                   
                                                       
                                                                        
                                                                           
                                                                           
                                                               
                                                                 
                                                                     
                                                            
                                                               
  

                                            
                                             
                                  
                                        
                                       
                                              
                                                   
                                                       
  

                               
                                
                                  
                                         
                                        
                              
                                                             
                        
                                             
                                            
                                                 
                                                   
                                                       
                                              
                                                       
                                
                                             
                                                                        
                                                                           
                                                                           
                                                    
                                                        
  

                            
                             
                                         
                                                 
                                           
                                           
                                          
  

                                          
                                           
                                                
                                              
                                         
                                               
                                          
                                                                             
  

                                    
                                     
                                                                  
                                                                                  
  

                                   
                                    
                                                        
                                                     
  

                          
                           
                                  
                                        
                                
                                                                     
                                                        
                                              
                                                         
                                                     
                                                  
                                                        
  

                                       
                                        
                                                  
  

                          
                           
                                                       
                                                       
                                                   
                                             
                                                 
                                              
                                
                                                             
                                                                        
                                                                                
                                                                     
                                                                       
                                                                       
                                                                       
                                                                     
                                                                      
                                                                          
                                                        
                                                
                      
                                  
                                                   
                                                    
                                                           
                               
                                       
                                                    
  

                                                    
                                                     
                                                   
                                              
  

                                      
                                       
                                  
                                            
                                              
  

                              
                               
                                  
                                              
                          
                                       
                                       
                                   
                                                       
                                                     
                        
                                                             
                                                   
                                                       
                                                                         
                                
                                                                 
                                                        
                              
                                              
                                                           
                                            
                                                   
                                                       
  

                      
                       
                                  
                                        
                                               
                                                   
                                                  
                                    
                                                         
                                                         
                                              
                                                               
                                                             
                                                        
  

                          
                           
                                  
                                                    
                 
                                                          
                                                    
                                                   
                                                         
                                                    
                                                     
                                                           
                                              
                                                   
                                   
                                                          
                                     
                                           
                                                         
                                                                       
                                                                         
                                                                                 
                                                               
                                                                   
  

                            
                             
                                  
                                   
                                     
                                            
  

                              
                               
                                  
                                         
                                               
                                                    
                                                 
                                               
                                                         
                                                             
                                                                 
                           
                                                             
                                                    
  

                                 
                                  
                                                    
                                            
                                         
  

                                
                                 
                                  
                                         
                                               
                                                    
                                               
                                                         
                                                             
                                                                 
                           
                                                             
                                            
                                                                  
                                               
                                                          
  

                                          
                                           
                                  
                                           
                                              
                                                               
                                     
                                                                   
  

                                             
                                              
                                  
                                         
                                               
                                                    
                                               
                                                         
                                                             
                                                                 
                           
  

                                           
                                            
                                                
                                                
                                      
                                                        
                                                    
  

                               
                                
                                  
                                              
                                                       
                                   
                                                 
                                    
                                                     
                                                                
                                             
                                                           
                                                          
                                                            
                                  
                                                             
                                             
                                                           
                                         
                                                            
                                           
                                                         
                                     
                                                             
                                             
                             
                                                         
                                     
  

                                            
                                             
                                  
                                          
                                                       
                                         
                            
                                                       
                                                   
                                              
                        
                                             
                                            
                                                    
                                                                         
  

                                                  
                                                   
                                               
  

                                          
                                           
                                                
                                                 
  

                                                    
                                                     
                                  
                                                
                                                
                                                        
                                                    
  

                                                           
                                                            
                                                     
                                                      
                                                             
                                                            
                                                         
                                                         
                                                    
                                                      
                                                               
  

                                 
                                  
                                  
                                               
                                                       
                                                       
                                     
                                                            
                                                                  
                                       
                                              
                                                 
                                                     
                                    
                                                     
                                                                
                                             
                                                           
                                                          
                                                        
                                                           
                                                        
                                                             
                                                              
                                                          
                                                            
                                           
                                                    
                                       
                                     
                                                                        
                                                                             
  

                          
                           
                                  
                                                  
                                  
                                                     
                                         
                                            
                               
                                                   
                                                       
                                             
                                        
                                
                                                                     
                                                        
                                              
                                                             
                                                                      
                                                     
                                                   
                                                       
                                                                           
                                                                           
                                                                        
  

                    
                     
                                                         
                              
                                                          
                                                          
                                                           
                                                             
                                                     
                                                           
                                                                            
                                                  
                                                  
                                              
                                  
                                                     
                                             
                                               
                                                        
                                                                     
             
                                                  
  

                                        
                                         
                                                                
                                                               
                                                          
                                                                      
                                                            
                                                                     
                                                               
                                                             
                                                                   
                                                          
  

                                
                                 
                                                  
                                              
                                  
                                             
                                               
                                                        
  

                              
                               
                                  
                                            
                                             
                                              
                                                             
                                                   
                                                       
                                                  
                                   
                                  
                                                 
                                
                                                                     
                                                                     
                                                                       
                                                                       
                                                                          
                                                                        
                                                        
                                                           
                                            
                                                       
                                                                           
                                                                           
                                                                        
  

                                      
                                       
                                  
                                            
                                             
                                              
                                                   
                                                       
                                                  
                                   
                                         
                                                  
                        
                                
                                                             
                                                                   
                                                                
                                                      
                                                             
                                                                           
                                                                           
                                                                        
                                                            
  

                                      
                                       
                                  
                                       
                                                   
                                                                 
                                              
                                                  
  

                                           
                                            
                                  
                                                  
                                              
                                                           
                                       
  

                       
                        
                                  
                                                                     
                                            
  

                                 
                                  
                                              
                                                                      
  

                           
                            
                                                    
                                            
                                             
                                                   
  

                                 
                                  
                                  
                                         
                                
                                                                     
                                                        
                                                     
                                              
  

                                                 
                                                  
                                           
                                  
  

                                         
                                          
                                           
                                                        
                                                  
                                              
                                  
                                                          
                                                                              
                                  
                                                    
  

                               
                                
                                        
                                         
                                              
                                           
  

                                                      
                                                       
                                               
  

                                            
                                             
                                                   
                                                   
                                                          
                                                       
                                                
                                              
                                                       
                                             
                                                 
                                          
                                                                             
                                                                
                                                           
                                                                    
                                                 
                                                  
                                                           
                                                       
  

                                             
                                              
                                                          
                                                             
                                                            
                                                                         
                                                            
  

                                                      
                                                       
                                      
                                        
                                                            
                                       
                                        
                                          
                                                
                                                  
                                                
                                                          
  

                                                  
                                                   
                                                
                                                          
  

                                         
                                          
                                            
                                                    
                                                 
                                               
                                                    
                                          
                                                      
  

                                     
                                      
                                                 
  

                                            
                                             
                                        
                                        
  

                                       
                                        
                                        
                                        
                                            
                                                 
  

                                   
                                    
                                                
  

                                       
                                        
                                         
                                         
                                            
  

                                   
                                    
                                         
                                        
  

                                          
                                           
                                     
  

                                 
                                  
                                                           
                                                   
                                                         
                                                           
                                                     
                                                           
                                                           
  

                                               
                                                
                                                      
                                                               
                                             
                                      
                                                       
  

                                                     
                                                      
                                                             
                                                               
                                            
                                                     
                                                               
                                                         
                                                         
                                                         
                                                         
                                                                      
                                                                  
                                                   
                                                           
                                                          
                                              
                                                      
                                                     
                                                        
                                                       
                                               
                                                   
                                      
                                                             
                                            
  

                                                 
                                                  
                                                               
                                                          
                                                         
                                                     
                                                         
                                                                      
                                                                  
                                                      
  

                                                
                                                 
                                                             
                                                             
                                                            
                                                           
                                                     
                                                       
                                                        
                                                       
                                                 
                                                               
                                            
                                                   
                                              
                                                      
                                            
                                      
  

                                            
                                             
                                                
                                                  
  

                                           
                                            
                                                
                                                            
  

                                  
                                   
                                           
                                                         
                                                    
                                              
  

                                      
                                       
                                   
  

                                 
                                  
                                         
                                         
  

                                                
                                                 
                                                      
                                                     
                                                       
                                               
                                               
                                                            
                                                             
                                             
                                                           
                                            
                                      
                                        
                                            
                                                         
                                                     
                                                     
                                                           
                                                             
                                                       
                                                
                                                                
                                              
                                                           
                                                          
  

                                            
                                             
                                                
                                                        
  

export const SastDefaultRulePacksValues = Object.freeze({
  ALL: 'ALL', 
  STANDARD: 'STANDARD', 
  CRITICAL: 'CRITICAL', 
  SECRETS_SCANNING: 'SECRETS_SCANNING', 
  GATEKEEPER_GAMBLING: 'GATEKEEPER_GAMBLING', 
  P1_ISSUES: 'P1_ISSUES', 
  API_RULES: 'API_RULES'
});


                                                                              

                                             
                                                    
                                                                            
  

                                    
                                            
                                             
                                                 
                                                  
  

                                         
                                          
                                                  
                                                                          
  

                                 
                                  
                                               
                                        
                              
  

                                          
                                           
                                       
  

                                        
                                         
                                                
                                                    
                                                            
  

                                                
                                                 
                                               
                                        
                                                         
                                                         
                                                        
  

                                            
                                             
                                                
                                                          
  

                                                    
                                                     
                                                                       
  

                                        
                                         
                                                                           
  

                                                      
                                                       
                                                                
  

                                        
                                         
                                                          
  

                                             
                                              
                                                                
  

                                                     
                                                      
                                                                         
  

                                                    
                                                     
                                                           
                                                   
                                              
                                          
                                           
                                                
  

                                                            
                                                             
                                                
                                                         
  

                                                
                                                 
                                                
                                                         
  

                                                
                                                 
                                                
                                                         
  

                                                     
                                                      
                                                
                                                         
  

                                          
                                           
                                               
  

                                                        
                                                         
                                        
                                         
                                              
                                               
  

                                            
                                             
                                        
                                         
                                              
                                                            
                                                   
                                                              
  

                                               
                                                
                                        
                                           
                                            
                                                        
  

                                           
                                            
                                                       
  

                                                 
                                                  
                                                     
  

                                                
                                                 
                                                      
  

                                              
                                               
                                                    
  

                                            
                                             
                                                  
  

                                            
                                             
                                                        
  

                                           
                                            
                                                 
  

                                            
                                             
                                                  
  

                               
                                
                                                     
                                                     
                                                   
                                                          
  

                             
                              
                                                   
                                                   
                                                 
                                                        
  

                                         
                                          
                                                          
                                              
  

                                       
                                        
                                                        
                                          
  

                                    
                                     
                                                      
                                                              
                                                                 
                                                         
  

                                
                                 
                                                         
                                                     
                                                     
                                              
                                        
  

                              
                               
                                              
                                              
                                                       
                                             
                                                  
                                                           
                                                       
                                                                    
                                                            
                                               
                                                
                                                          
                                                       
                                                   
                                                   
  

                                       
                                        
                                                          
                                                           
                                                                       
                                                          
                                                        
  

                                        
                                         
                                     
                                                      
                                                
  

                                          
                                           
                                         
                                                        
                                                
  

                                             
                                              
                                                 
                                                      
                                                      
  

                                               
                                                
                                                  
                                                  
                                                              
                                                              
  

                                        
                                         
                                        
                                                     
  

                                    
                                     
                                                 
                                                            
  

                                         
                                          
                                                            
  

                                              
                                               
                                                    
  

                                               
                                                
                                                
  

                                          
                                           
                                           
                                              
                                        
                                         
  

                                                  
                                                   
                                                                
  

                                                      
                                                       
                                                            
  

                               
                                
                                                                  
                                                                     
                                                    
                                             
  

                                   
                                    
                                             
  

                                 
                                  
                                            
                                              
                                                   
                                               
                                                  
                                                  
                                             
  

                                          
                                           
                                                  
  

                               
                                
                                                 
                                                   
                                                 
                                                             
  

                                         
                                          
                                                  
                        
  

                                   
                                    
                                                    
                               
  

                                                     
                                                      
                                                  
                                                  
  

                                            
                                             
                                        
                                                           
  

                                        
                                         
                                                                
  

                                                     
                                                      
                                           
                                         
                                                
                                              
  

                                                                     
                                                                      
                                           
                                         
                                         
                                                
                                              
  

                                                           
                                                            
                                             
                                                                        
  

                                                    
                                                     
                                              
                                                           
  

                                           
                                            
                                                       
                                                      
  

                                             
                                              
                                           
                                              
                                        
                                         
  

                                                 
                                                  
                                                                     
  

                          
                           
                                                             
                                                 
                                                 
                                            
                                          
                                        
                                        
  

                                      
                                       
                                                
                                  
  

                                                 
                                                  
                                         
                                         
                                         
  

                                             
                                              
                                                 
  

                                                
                                                 
                                        
                                        
                                         
  

                                            
                                             
                                                           
  

                                           
                                            
                                        
                                        
                                         
  

                                           
                                            
                                        
                                        
                                         
  

                                       
                                        
                                                         
  

                                       
                                        
                                                         
  

                                                   
                                                    
                                                          
                                                         
                                                                
  

                                                            
                                                             
                                               
                                                           
                                            
                                        
  

                                                        
                                                         
                                                               
  

                                    
                                     
                                        
                                            
                                       
                                             
                                                       
  

                                       
                                        
                                           
                                           
                                          
  

                                             
                                              
                                         
                                            
                                              
                                              
                                                       
  

                                         
                                          
                                                              
  

                                       
                                        
                                  
                                              
                                                       
                                                   
                                            
                                              
                                                             
                                                  
                                                           
                                                       
                                             
                                                      
                                                  
                                        
                                                                         
                                                                         
                                                                      
                                          
                                         
                                                                        
  

                                                       
                                                        
                                                
                             
  

                           
                            
                                  
                            
                                                       
                                                   
                                            
                                              
                                                             
                                                  
                                                           
                                                       
                                             
                                                      
                                                  
                                        
                                                                         
                                                                         
                                                                      
                                          
                                         
  

                                  
                                   
                                                
                             
  

                                                       
                                                        
                                                
                                  
                                                      
  

                                          
                                           
                                                       
                                                   
                                                  
                                                       
                                                
                                                               
                                                  
                                                           
                                                       
                                               
                                  
                                                               
                                                   
                                                 
                                                      
                                                
                                                                         
                                                                         
                                                                      
                                                                           
                                          
  

                                                 
                                                  
                                                       
                                                   
                                                
                                                
                                                               
                                                  
                                                           
                                                       
                                               
                                  
                                                                     
                                                                
                                                      
                                                                         
                                                                         
                                                                      
                                          
  

                                                   
                                                    
                                                
                                                                   
  

                                                
                                                 
                                                                      
                                                                         
                                                                         
                                  
                                                       
                                                
                                                                     
  

                                            
                                             
                                                                   
  

                                                  
                                                   
                                  
                                                 
                                                
                                                                      
                                                                         
                                                                         
  

                                              
                                               
                                                                       
  

                                   
                                    
                                  
                                         
                                               
                                                  
                                                                 
  

                               
                                
                                  
                                        
                                     
                               
  

                                              
                                               
                                  
                                    
                                        
                                                       
                                                               
                                                            
                                              
                                                 
                                                          
  

                                            
                                             
                                                           
                                                
  

/** ENDPOINTS */
                                           
                                            
                                                
                                        
  

                                          
                                           
                                                
                                     
  

                                             
                                              
                                                
                                            
  

                                           
                                            
                                      
                                                            
  

                                        
                                         
                                  
  

                                                   
                                                    
                                  
                                        
  

                                                
                                                 
                                                                 
  

                                                  
                                                   
                                                               
                                                                 
                                                  
                                                          
                                                          
                                                              
  

                                              
                                               
                                                                      
  

                                                   
                                                    
                                  
                                               
                                                  
                                                           
                                                       
                                              
                                                            
                                                                                         
                                                  
                                                               
                                                           
                                                                             
                                          
                                            
                                                       
                                                   
                                                  
                                                           
                                                       
                                                                      
                                               
                                                       
                                                
  

                                                
                                                 
                                                
                                                   
  

                                                            
                                                             
                                                      
                                                                   
                                                      
                                                                   
                                                        
                                                                     
                                                        
                                                                     
                                                
                                                
                                                  
                                                  
  

                                                     
                                                      
                                               
                                                                                
  

                                                 
                                                  
                                                        
  

                                              
                                               
                                                     
                                                             
                                                 
                                                         
                                                        
                                                    
                                                                     
                                                                             
                                                                                 
                                                    
                                                       
                                                       
                                                
                                                        
                                                         
                                                        
                                                                    
                                                   
                                                       
                                                                           
                                                                               
                                                                        
                                                                            
  

                                                      
                                                       
                                                                  
  

                                                     
                                                      
                                        
                                        
  

                                          
                                           
                                  
                                        
                                             
                                                
                                            
                                                
                                                       
                                                           
                                                                   
                                                     
                                            
                                                 
                                               
                                                    
                                                            
                                                                    
                                                                      
                                                               
                                                       
                                                    
                                            
                                                                  
                                                             
                                                         
                                                                           
                                                                   
                                                                          
                           
  

                                              
                                               
                                                
                                                         
  

                                  
                                   
                                                
                                            
  

                                               
                                                
                                                
                                                            
  

                                    
                                     
                          
                                  
                                           
                                   
                       
                                 
                                                                  
  

                                      
                                       
                                                
                                  
                                           
                                 
  

                                                                               
                                                                                
                                                                                     
                                                                                        
                                                            
                                                            
                                                          
  

                                                                 
                                                                  
                                  
                                                                 
                                               
                                                    
                                         
  

                                                   
                                                    
                                  
                                                                                                     
                                                                      
                                     
  

                                         
                                          
                                                
                                                           
  

                                            
                                             
                                                     
                                            
                                        
                                         
                                                    
  

                                               
                                                
                                            
                                            
                                                        
                                                              
                                                        
                                                       
  

                                           
                                            
                                                           
  

                                               
                                                
                                                                         
  

                                        
                                         
                                                                   
  

                                                 
                                                  
                                                     
                                              
                                        
                                         
                                                    
  

                                                    
                                                     
                                                                                   
  

                                                      
                                                       
                                                                       
  

                                              
                                               
                                                                   
  

                                                     
                                                      
                                                    
                                                    
                                                    
                                                    
  

                                                 
                                                  
                                                
                                  
                                           
                                 
  

                                          
                                           
                                                
                                           
  

                                                  
                                                   
                                                
                                             
  

                                           
                                            
                                      
                                        
                                            
                                              
                                                     
                                                          
                                                              
                                                              
                                                          
                                                       
  

                                       
                                        
                                                        
  

                                       
                                        
                                                        
  

                                          
                                           
                                                  
  

                                                   
                                                    
                                                       
                                                             
                                                         
                                                               
                                                       
                                                             
                                                         
  

                                            
                                             
                                                                                 
                                                                     
                                                                                   
                                                                       
  

                                        
                                         
                                               
                                         
                                            
                                         
  

                              
                               
                                         
                                      
                                         
  

                                  
                                   
                                          
                                 
  

                                       
                                        
                                  
                                              
  

                                              
                                               
                                          
                                         
  

                                                     
                                                      
                                                          
  

                                                    
                                                     
                                                
                           
                                 
  

                                           
                                            
                                                
                                            
                                  
                                           
                                 
                                          
                                  
                                        
                                  
                                          
                                        
  

                                                   
                                                    
                                                
                                              
  

                                               
                                                
                                      
  

                                               
                                                
                                                    
  

                                       
                                        
                                                
                                  
  

                                          
                                           
                                                
                                  
                                          
                                            
                                  
                                           
  

                                         
                                          
                                  
                                          
                                  
                                        
                                          
                                        
                                            
                                  
                                           
  

                                                
                                                 
                                        
                                        
                                  
  

                                    
                                     
                                                
                          
                                 
  

                        
                         
                                  
                                        
                                               
                                                   
                                                         
                                                         
                                              
                                                             
  

                                      
                                       
                                                
                            
  

                                          
                                           
                                                
                                          
                                          
                                  
                                            
                                  
                                           
  

                                                  
                                                   
                                                
                                                  
  

                                               
                                                
                                        
                                                  
  

                                                        
                                                         
                                        
                                             
                                                                      
                                                               
  

                                                              
                                                               
                                        
                                             
                                                                      
                                                               
  

                                   
                                    
                                                                 
                                                                            
                                                                                   
  

                                          
                                           
                                       
                                         
  

                                          
                                           
                                          
  

                                             
                                              
                                  
                                          
                                  
                                        
                                          
                                            
                                  
                                           
                                         
                                              
                                   
                                                       
                       
  

                                     
                                      
                                                                 
  

                                          
                                           
                                                         
                                                         
                                                     
                                                      
  

                                          
                                           
                                                                   
                                                   
                                                             
                                                 
                                                     
                                                          
                                                                      
                                                               
                                                            
                                                                    
  

                             
                              
                                  
                                              
                                 
                             
  

                                     
                                      
                                                          
                                       
                                             
                                                                   
                                   
  

                                     
                                      
                                                                       
                                                                   
                                                                        
                                                                
  

                                                      
                                                       
                                                                   
                                                                                
                                                                                                
                                                                                   
                                                                           
                                                                                        
                                                                          
                                                                                       
                                                                       
                                                                                    
                                                
                                                             
                                                                    
                                                                    
                                                                
                                                                
  

                                               
                                                
                                  
                                           
                                           
                                  
                                                             
                                                         
                                               
                                                        
                                                    
                                                    
                                              
                                            
                                                                                         
  

                                             
                                              
                                          
                                          
                                  
                                  
                                            
                                  
                                           
                                  
                                        
  

                                           
                                            
                                               
                                                
                                          
                                       
  

                                     
                                      
                                  
                                         
                                                  
                                                
                                                          
                                                                 
                                                    
                                               
  

                                                 
                                                  
                                                         
  

                                              
                                               
                                                         
                                                
  

                                                 
                                                  
                                                
                                              
                                              
                                                   
                                                     
                                                  
                                              
                                                 
  

                                             
                                              
                                                
                                                   
  

                                                
                                                 
                                               
                                                  
                                               
                                            
                                               
  

                                            
                                             
                                                        
  

                                        
                                         
                                                 
  

                                      
                                       
                          
                                 
  

                                                      
                                                       
                                                
                                                             
  

                                           
                                            
                                                
                                                  
  

                                          
                                           
                                                
                                                
  

                                                          
                                                           
                                                 
  

                                                  
                                                   
                                      
                                              
                                                    
                                                
  

                                               
                                                
                                      
                                              
                                                    
                                            
                                               
  

                                                 
                                                  
                                      
                                              
                                                    
                                             
                                                   
  

                                                 
                                                  
                                      
                                              
                                                    
                                             
                                             
  

                                               
                                                
                                      
                                              
                                                    
                                                   
                                                
  

                                                
                                                 
                                      
                                              
                                                    
                                                        
  

                                                
                                                 
                                                          
                                                      
                                                    
                                                            
                                                  
                                      
                                          
  

                                                    
                                                     
                                      
                                              
                                                    
                                                   
  

                                                       
                                                        
                                            
  

                                                
                                                 
                                                          
                                                                     
                                                                    
  

                                                     
                                                      
                                           
                                          
                                                       
                                                                      
  

                                                       
                                                        
                                           
                                          
                                                       
                                                                      
  

                                                         
                                                          
                                                                      
                                                                       
                                                                
                                                  
  

                                                  
                                                   
                                                                 
                                                                       
                                                                      
  

                                                       
                                                        
                                           
                                          
                                                       
                                                                          
  

                                       
                                        
                                                
  

                                    
                                     
                                                       
                                                         
                                                       
                                                                 
                                                                                 
  

                                                 
                                                  
                                                       
                                                   
                                
                                  
                                                                 
                                                                                 
  

                                      
                                       
                                                 
                                               
                                                                 
                                                                        
                                                       
                                                   
  

                                  
                                   
                                               
                                                  
                                                  
                                                    
                                                    
                                                                   
                                                                           
                                                                         
                                                                             
                                                               
                                                                      
                                                                                    
  

                                        
                                         
                                      
  

                                                 
                                                  
                                                           
                                                                    
                                                                
  

                                                
                                                 
                                                             
                                          
                                         
                                                       
                                                                                     
                                                                                            
  

                                                      
                                                       
                                                             
                                          
                                         
                                                       
                                                                                   
  

                                                           
                                                            
                                                             
                                          
                                         
                                                       
                                                                                          
  

                                 
                                  
                                  
                                              
                                                
                                    
  

                                               
                                                
                                         
                                                                          
                                                                      
                                                                                                    
                                                                                                  
                                                                                                                 
                                                  
  

                                                                           
                                                                            
                                                                           
                                                                           
                                                                             
                                                                             
                                                                   
  

                                                  
                                                   
                                                               
                                                                                      
  

                                                                       
                                                                        
                                                  
  

                                                                
                                                                 
                                       
  

                                                                             
                                                                              
                                                                   
  

                                               
                                                
                                         
                                                                
                                                                                                    
                                                                                                  
                                                                                                                 
                                                                
                                                     
  

                                               
                                                
                                         
                                                                
                                                                                                    
                                                                                                  
                                                                                                                 
                                                                
                                                     
  

                                    
                                     
                                                     
                                                      
                                                      
                                                         
                                                         
  

                                            
                                             
                                                     
                                                      
                                                      
                                                         
                                                         
                                                          
                                                                 
  

                              
                               
                                               
                                           
                                               
                                                      
  

                              
                               
                                                
                                   
                                   
                                   
                                      
                                        
                                    
                                     
                                     
                                                                           
                                                                         
  

                                      
                                       
                                         
  

                                               
                                                
                                                                    
                                                                     
  

                                  
                                   
                                  
                                              
                                                  
                                                    
                                    
                             
                                                        
  

                                          
                                           
                                                   
  

                                             
                                              
                                                     
                                                       
                                                         
                                                         
                                                        
  

                                         
                                          
                                               
                                                               
                                                                  
                                                                  
  

                           
                            
                                  
                                              
                                        
                                                 
                                               
                                                      
                                                  
                                    
                                                                  
                                                           
  

                                                
                                                 
                                                     
                                                       
                                                         
                                                         
                                                        
                                                     
  

                            
                             
                                  
                                        
                                              
                                                       
                                                         
                                                               
                                                               
  

                                      
                                       
                                                
                                   
                                 
  

                                        
                                         
                                                
                                    
  

                                        
                                         
                                   
                       
                          
                                           
                                  
                                 
  

                            
                             
                                  
                                        
                            
                                                       
                                                   
                                                                      
                                                                         
                                                                         
                                
                      
                                              
  

                                                      
                                                       
                                          
                                          
                                                  
                                                     
  

                             
                              
                                         
                                                     
  

                                                         
                                                          
                                        
                                  
                                                       
  

                                                  
                                                   
                                                                  
  

                                                   
                                                    
                                                
                              
  

                                   
                                    
                                  
  

                                           
                                            
                                                
                                                       
                                 
                                          
                                  
                                        
                                  
                                          
  

                                         
                                          
                                                                             
                                                                                        
                                                                                          
                                                                                             
                                                                                                 
                                                                               
  

                                                 
                                                  
                                           
  

                                            
                                             
                                                            
  

                                         
                                          
                                                
                                        
  

                                            
                                             
                                   
                                        
                                        
                                  
                       
                                          
                                           
                                  
                                            
                                  
                                          
  

                                         
                                          
                                   
                                  
                       
                                  
                                        
                                          
                                           
                                  
                                            
                                          
  

                                   
                                    
                                           
                                              
  

                                            
                                             
                                                    
                                                  
  

                                
                                 
                                                             
                                                                                        
                                                                                     
                                                                                 
  

                                      
                                       
                                                
                                  
  

                                               
                                                
                                             
                                                         
                                                                    
                                                                     
                                                      
                                                              
  

                                                            
                                                             
                                                     
                                                          
                                                        
                                                               
                                                       
                                                              
                                                                 
                                                     
                                                          
  

                                              
                                               
                                                
                                                                 
                                                                 
                                                                 
                                                                   
  

                               
                                
                                                     
                                                       
                                                     
                                                   
                                                          
                                                      
                                                     
                                                         
                                             
                                                  
                                                            
                                                                    
                                                                    
                                                                     
                                                                 
  

                                          
                                           
                                                   
                                                 
                                       
                                               
                                              
  

                       
                        
                           
                                         
                                                         
                                                                      
                                       
                                                              
  

                               
                                
                          
  

                                     
                                      
                            
  

                                   
                                    
                                                
                            
  

                                         
                                          
                                     
                            
  

                                    
                                     
                            
  

                                             
                                              
                                            
  

                                              
                                               
                                                
                                                
  

                                                
                                                 
                                  
                                  
                                          
                                  
  

                                                           
                                                            
                                                        
                                                         
                                                
  

                                                 
                                                  
                                                                   
                                                
                                                                             
                                                                                     
  

                                                            
                                                             
                                                 
                                            
                                        
                                                          
                                                          
                                                      
                                                                             
                                              
                                                
  

                                               
                                                
                                               
                                          
                                    
                                                        
                                                    
                                                
                                                  
  

                                  
                                   
                                
                                  
  

                                                     
                                                      
                                                                         
                                                      
                                                               
                                                               
  

                          
                           
                                      
                                        
                                            
                                             
  

                                         
                                          
                                  
  

                                            
                                             
                                      
                                       
                                        
                                        
                                            
  

                                   
                                    
                                                  
                                             
                                                      
                                              
                                                         
                               
                                                      
                                                                   
                                                          
                                                        
                                                  
                                                                   
  

                                       
                                        
                                                
                                           
  

                               
                                
                                      
                                                
                                                       
                                                                    
  

                                        
                                         
                                            
  

                                     
                                      
                                             
                                                
                                                      
                                                         
                                                                         
  

                                       
                                        
                                          
                                          
                                  
                                            
                                  
                                           
                                  
                                  
                                      
                                        
  

                                        
                                         
                                                
                                      
  

                                       
                                        
                                           
  

                                         
                                          
                                  
                                           
                       
  

                                            
                                             
                                                         
  

                             
                              
                                              
  

                                  
                                   
                                              
  

                               
                                
                                        
  

                                
                                 
                                                  
                                                    
                                                          
  

                                       
                                        
                                                 
                                                                   
  

                            
                             
                                                
                      
  

                               
                                
                                                     
                                                
                                                         
                                                               
                                              
  

                                            
                                             
                                               
  

                                        
                                         
                                               
                                                
  

                                        
                                         
                                               
                                                                     
                                                                            
                                                                   
                                                                   
                                                                          
                                                                          
                                                                          
                                                                          
  

                                                              
                                                               
                                                        
                                                     
                                                    
                                                                         
                                                                         
                                                                      
                                                                        
                                                                     
                                                     
  

                                                                       
                                                                        
                                                      
                                                                 
                                                                              
                                                                       
                                                                                    
  

                                 
                                  
                                  
                                              
                                        
                                                 
                                                
                                                
                                                                                       
                                                                                                            
  

                                   
                                    
                                                
                                    
  

                                             
                                              
                                  
                                        
                                            
  

                                    
                                     
                                  
                                        
                            
                                            
                                                                
                                                                   
                                                                   
                                                                            
                                                                         
                                                                        
                                                                         
                                               
                                                        
                                         
                                                
                                                     
  

                                             
                                              
                                                
                                                        
  

                                                                  
                                                                   
                                                          
                                                        
                                                     
                                                 
                                            
  

                                                                 
                                                                  
                                  
                                        
                                            
                                                 
  

                                                 
                                                  
                                                                          
                                                                                       
                                                                       
                                                                      
                                                                      
                                                      
                                                                   
                                                                 
                                                                              
                                                                
                                                                            
                                                                                   
                                                                                      
                                                                                     
                                                                                     
  

                                                
                                                 
                                                                       
  

                                   
                                    
                                                                 
                                                            
                                                           
                                                        
                                                               
                                                                     
  

                                           
                                            
                                                     
                                                
  

                                             
                                              
                                                 
                                                              
                                                                 
                                                                              
                                                     
                                                                  
                                                      
                                                                   
                                                    
                                                                 
                                                        
                                                                     
                                                  
                                                               
  

                                      
                                       
                                          
                                          
                                       
  

                                 
                                  
                                      
                                               
  

                                            
                                             
                                          
                                            
  

                                  
                                   
                                               
                                         
  

                                           
                                            
                                                         
                                                        
                                                     
                                                     
                                                     
                                                      
                                                     
                                                      
                                                         
                                            
                                            
                                           
                                      
                                        
  

                                                    
                                                     
                                                
                                                      
  

                                                              
                                                               
                                                                  
                                                
  

                                              
                                               
                                        
                                              
                                                   
  

                                                        
                                                         
                                                      
                                                       
                                                       
                                                         
                                                        
  

                                                           
                                                            
                                              
                                                   
                                        
  

                                                 
                                                  
                                                
                                                
                                                     
                                                                   
                                                                      
  

                                                           
                                                            
                                                    
                                                    
                                                     
                                                                   
  

                                             
                                              
                                                                     
  

                                                     
                                                      
                                        
                                                      
                                                       
                                                       
                                                         
                                                         
  

                                                  
                                                   
                                                      
                                                       
                                                       
                                                         
                                                         
  

                                                               
                                                                
                                                
                                                
                                                
                                                     
                                               
                                                         
                                                               
                                                        
  

                                                      
                                                       
                                        
                                          
                                         
                                             
                                                          
                                                 
                                                  
                                                            
                                                             
                                                               
                                                        
  

                                                  
                                                   
                                                
                                                         
                                                                             
  

                                                        
                                                         
                                                              
                                                           
                                        
  

                                             
                                              
                                      
                                                      
                                          
                                                      
                                                     
                                                     
                                                   
                                            
                                              
                                                              
                                                       
                                                                  
                                                          
  

                                                     
                                                      
                                      
                                                      
                                            
                                                      
                                                     
                                                     
                                                   
                                            
                                              
                                                              
                                                       
                                                                  
  

                                                 
                                                  
                                                
                                                                  
  

                                                            
                                                             
                                            
                                          
                                        
                                              
                                                   
                                                  
                                                       
                                              
                                               
                                               
                                                       
                                                           
  

                                                        
                                                         
                                                
                                                                                 
  

                                               
                                                
                                                               
                                                    
  

                                                      
                                                       
                                                                               
                                                                 
                                      
                                           
  

                                  
                                   
                                                   
                                        
                                              
  

                                              
                                               
                                                       
                                                       
                                                         
                                                         
                                          
                                        
  

                                              
                                               
                                                       
                                                       
                                                         
                                                         
                                          
  

                                 
                                  
                                                      
                                            
                                            
  

                                 
                                  
                                            
                                        
                                         
  

                                    
                                     
                                              
                                              
  

                                                  
                                                   
                                                                     
  

                                             
                                              
                                      
                                                       
                                                              
                                                     
                                                       
                                                            
                                                                                          
  

                                                     
                                                      
                                                
                                                                          
  

                             
                              
                                             
                                  
  

                                                
                                                 
                                      
                                                
                                                  
                                                   
                                                            
                                                    
                                          
  

                                      
                                       
                                                     
                                                           
                                               
                                        
                                                       
                                       
  

                             
                              
                                             
                                           
  

                                      
                                       
                                                
                                         
                                       
  

                                                
                                                 
                                                      
                                                                                 
                                                      
                                                                                 
                                                      
                                                                                 
                                      
                                          
                                                
                                                   
                                                  
                                                            
                                                    
                                     
  

                                                 
                                                  
                                      
                                           
                                                
                                                  
                                                   
                                                            
                                                    
                                          
  

                                       
                                        
                                                     
                                               
                                        
                                          
                                       
                                                        
  

                                       
                                        
                                                  
                                                    
                                       
  

                                          
                                           
                                           
                                              
                                        
                                         
  

                                              
                                               
                                                                  
  

                                             
                                              
                                                                 
  

export const HorizonEventTypeEnumValues = Object.freeze({
  USER_LOGGED_IN: 'USER_LOGGED_IN', 
  NETWORK_SERVICE_DISCOVERED: 'NETWORK_SERVICE_DISCOVERED', 
  RESTFUL_API_DISCOVERED: 'RESTFUL_API_DISCOVERED', 
  CLOUD_RESOURCE_DISCOVERED: 'CLOUD_RESOURCE_DISCOVERED', 
  AWS_AUTHENTICATOR_CREATED: 'AWS_AUTHENTICATOR_CREATED', 
  POLICY_VIOLATION_CREATED: 'POLICY_VIOLATION_CREATED', 
  POLICY_VIOLATION_RESOLVED: 'POLICY_VIOLATION_RESOLVED', 
  POLICY_VIOLATION_EXCEPTION_ADDED: 'POLICY_VIOLATION_EXCEPTION_ADDED', 
  NETWORK_SERVICE_WENT_OFFLINE: 'NETWORK_SERVICE_WENT_OFFLINE', 
  NETWORK_SERVICE_WENT_ONLINE: 'NETWORK_SERVICE_WENT_ONLINE', 
  COMMENT_CREATED: 'COMMENT_CREATED', 
  COMMENT_DELETED: 'COMMENT_DELETED', 
  OPENAPI_DEFINITION_CREATED: 'OPENAPI_DEFINITION_CREATED', 
  RESTFUL_API_UPDATED: 'RESTFUL_API_UPDATED', 
  API_OPERATION_UPDATED: 'API_OPERATION_UPDATED', 
  API_OPERATION_NO_LONGER_ACCESSIBLE: 'API_OPERATION_NO_LONGER_ACCESSIBLE', 
  API_OPERATION_CREATED: 'API_OPERATION_CREATED', 
  CERTIFICATE_DISCOVERED: 'CERTIFICATE_DISCOVERED', 
  CERTIFICATE_CHAIN_DISCOVERED: 'CERTIFICATE_CHAIN_DISCOVERED', 
  CERTIFICATE_CHAIN_REMOVED: 'CERTIFICATE_CHAIN_REMOVED', 
  AZURE_AUTHENTICATOR_CREATED: 'AZURE_AUTHENTICATOR_CREATED', 
  AZURE_AUTHENTICATOR_UPDATED: 'AZURE_AUTHENTICATOR_UPDATED', 
  GCP_AUTHENTICATOR_CREATED: 'GCP_AUTHENTICATOR_CREATED', 
  GCP_AUTHENTICATOR_KEY_UPDATED: 'GCP_AUTHENTICATOR_KEY_UPDATED', 
  APIGEE_AUTHENTICATOR_CREATED: 'APIGEE_AUTHENTICATOR_CREATED', 
  POLICY_RULE_CREATED: 'POLICY_RULE_CREATED', 
  POLICY_RULE_DELETED: 'POLICY_RULE_DELETED', 
  IP_RANGE_CREATED: 'IP_RANGE_CREATED', 
  IP_RANGE_UPDATED: 'IP_RANGE_UPDATED', 
  WEB_APPLICATION_DISCOVERED: 'WEB_APPLICATION_DISCOVERED', 
  MULESOFT_AUTHENTICATOR_CREATED: 'MULESOFT_AUTHENTICATOR_CREATED', 
  POLICY_RULE_UPDATED: 'POLICY_RULE_UPDATED', 
  APIGEE_ON_PREM_AUTHENTICATOR_CREATED: 'APIGEE_ON_PREM_AUTHENTICATOR_CREATED', 
  ALERTS_INTEGRATION_CREATED: 'ALERTS_INTEGRATION_CREATED', 
  ALERTS_INTEGRATION_UPDATED: 'ALERTS_INTEGRATION_UPDATED', 
  ALERTS_INTEGRATION_DELETED: 'ALERTS_INTEGRATION_DELETED', 
  ASSET_GROUP_CREATED: 'ASSET_GROUP_CREATED', 
  ASSET_GROUP_DELETED: 'ASSET_GROUP_DELETED', 
  ASSET_GROUP_UPDATED: 'ASSET_GROUP_UPDATED', 
  ASSET_GROUP_MEMBERSHIP_CREATED: 'ASSET_GROUP_MEMBERSHIP_CREATED', 
  ASSET_GROUP_MEMBERSHIP_DELETED: 'ASSET_GROUP_MEMBERSHIP_DELETED', 
  POLICY_CREATED: 'POLICY_CREATED', 
  POLICY_DELETED: 'POLICY_DELETED', 
  POLICY_UPDATED: 'POLICY_UPDATED', 
  KONG_AUTHENTICATOR_CREATED: 'KONG_AUTHENTICATOR_CREATED', 
  KONG_AUTHENTICATOR_UPDATED: 'KONG_AUTHENTICATOR_UPDATED', 
  SCOPED_ACCESS_TOKEN_CREATED: 'SCOPED_ACCESS_TOKEN_CREATED', 
  SCOPED_ACCESS_TOKEN_DELETED: 'SCOPED_ACCESS_TOKEN_DELETED', 
  DOMAIN_NAME_OWNERSHIP_UPDATED: 'DOMAIN_NAME_OWNERSHIP_UPDATED', 
  RESTFUL_API_OWNERSHIP_UPDATED: 'RESTFUL_API_OWNERSHIP_UPDATED', 
  CLOUD_AUTHENTICATOR_CREATED: 'CLOUD_AUTHENTICATOR_CREATED', 
  CLOUD_AUTHENTICATOR_UPDATED: 'CLOUD_AUTHENTICATOR_UPDATED', 
  AUTO_REMEDIATION_ATTEMPT_CREATED: 'AUTO_REMEDIATION_ATTEMPT_CREATED', 
  AUTO_REMEDIATION_ATTEMPT_STARTED: 'AUTO_REMEDIATION_ATTEMPT_STARTED', 
  AUTO_REMEDIATION_ATTEMPT_SUCCEEDED: 'AUTO_REMEDIATION_ATTEMPT_SUCCEEDED', 
  AUTO_REMEDIATION_ATTEMPT_FAILED: 'AUTO_REMEDIATION_ATTEMPT_FAILED', 
  AUTO_REMEDIATION_ATTEMPT_BATCH_CREATED: 'AUTO_REMEDIATION_ATTEMPT_BATCH_CREATED', 
  AXWAY_AUTHENTICATOR_CREATED: 'AXWAY_AUTHENTICATOR_CREATED', 
  AXWAY_AUTHENTICATOR_UPDATED: 'AXWAY_AUTHENTICATOR_UPDATED', 
  RESTFUL_API_NO_LONGER_ACCESSIBLE: 'RESTFUL_API_NO_LONGER_ACCESSIBLE', 
  WEB_APPLICATION_NO_LONGER_ACCESSIBLE: 'WEB_APPLICATION_NO_LONGER_ACCESSIBLE', 
  CLOUD_RESOURCE_DELETED_FROM_CLOUD: 'CLOUD_RESOURCE_DELETED_FROM_CLOUD', 
  POLICY_VIOLATION_TRIAGING_EFFORT_CREATED: 'POLICY_VIOLATION_TRIAGING_EFFORT_CREATED', 
  POLICY_VIOLATION_TRIAGING_EFFORT_COMPLETED: 'POLICY_VIOLATION_TRIAGING_EFFORT_COMPLETED', 
  POLICY_VIOLATION_TRIAGING_EFFORT_DELETED: 'POLICY_VIOLATION_TRIAGING_EFFORT_DELETED', 
  TOOLKIT_SCANS_SCHEDULE_ENABLED: 'TOOLKIT_SCANS_SCHEDULE_ENABLED', 
  TOOLKIT_SCANS_SCHEDULE_UPDATED: 'TOOLKIT_SCANS_SCHEDULE_UPDATED', 
  TOOLKIT_SCANS_SCHEDULE_DISABLED: 'TOOLKIT_SCANS_SCHEDULE_DISABLED', 
  API_CREDENTIAL_CREATED: 'API_CREDENTIAL_CREATED', 
  API_CREDENTIAL_DELETED: 'API_CREDENTIAL_DELETED', 
  GRAPHQL_API_CREATED: 'GRAPHQL_API_CREATED', 
  GRAPHQL_API_NO_LONGER_ACCESSIBLE: 'GRAPHQL_API_NO_LONGER_ACCESSIBLE', 
  WEB_APPLICATION_WENT_ONLINE: 'WEB_APPLICATION_WENT_ONLINE', 
  CLOUD_RESOURCE_WENT_ONLINE: 'CLOUD_RESOURCE_WENT_ONLINE', 
  RESTFUL_API_WENT_ONLINE: 'RESTFUL_API_WENT_ONLINE', 
  GRAPHQL_API_WENT_ONLINE: 'GRAPHQL_API_WENT_ONLINE'
});


                                                                              

export const FileUploadStatusEnumValues = Object.freeze({
  PROCESSED: 'PROCESSED', 
  PENDING: 'PENDING', 
  INVALID: 'INVALID'
});


                                                                              

export const SevenhellEventTypeEnumValues = Object.freeze({
  COMMENT: 'COMMENT', 
  PERMANENTLY_CLOSE_ISSUE: 'PERMANENTLY_CLOSE_ISSUE', 
  CLOSE_TARGET: 'CLOSE_TARGET', 
  CHANGE_PRIORITY: 'CHANGE_PRIORITY', 
  RE_OPEN_ISSUE: 'RE_OPEN_ISSUE', 
  RE_OPEN_TARGET: 'RE_OPEN_TARGET', 
  ADD_NEW_APP: 'ADD_NEW_APP'
});


                                                                                  

export const ScanRunsTypeEnumValues = Object.freeze({
  WEB_APPLICATION_XSS: 'WEB_APPLICATION_XSS', 
  CLOUD_RANSOMWARE: 'CLOUD_RANSOMWARE'
});


                                                                      

export const ScanRunsStatusEnumValues = Object.freeze({
  COMPLETED: 'COMPLETED', 
  ONGOING: 'ONGOING', 
  STARTED: 'STARTED', 
  QUEUED: 'QUEUED', 
  FAILED: 'FAILED'
});


                                                                          

                       
                        
                                    
                              
                             
                                                
                                                
                                                 
                                                   
                                                              
                                                     
                                                                
                                                                     
  

                                
                                 
                                                
                            
  

                                        
                                         
                                                
  

                            
                             
                                              
                                                 
                            
                                         
  

                                    
                                     
                                                
                                     
  

export const FingerprintedResourceCategoryEnumValues = Object.freeze({
  FINGERPRINTED_AWS_S3_BUCKET: 'FINGERPRINTED_AWS_S3_BUCKET', 
  FINGERPRINTED_GCP_CLOUD_STORAGE_BUCKET: 'FINGERPRINTED_GCP_CLOUD_STORAGE_BUCKET', 
  FINGERPRINTED_DOCKER_REGISTRY: 'FINGERPRINTED_DOCKER_REGISTRY', 
  FINGERPRINTED_REDIS_DATABASE: 'FINGERPRINTED_REDIS_DATABASE', 
  FINGERPRINTED_MONGODB_INSTANCE: 'FINGERPRINTED_MONGODB_INSTANCE', 
  FINGERPRINTED_AZURE_STORAGE_ACCOUNT_BLOB_CONTAINER: 'FINGERPRINTED_AZURE_STORAGE_ACCOUNT_BLOB_CONTAINER', 
  FINGERPRINTED_CASSANDRA_DB_DATABASE: 'FINGERPRINTED_CASSANDRA_DB_DATABASE', 
  FINGERPRINTED_COUCHDB_DATABASE: 'FINGERPRINTED_COUCHDB_DATABASE', 
  FINGERPRINTED_ELASTICSEARCH_DATABASE: 'FINGERPRINTED_ELASTICSEARCH_DATABASE', 
  FINGERPRINTED_SOLR_DATABASE: 'FINGERPRINTED_SOLR_DATABASE'
});


                                                                                                        

                                                              
                                                               
                                                                       
                                                                  
                                                                                  
                                                       
                                                   
  

                                        
                                         
                                                               
                                                                  
                                                                                  
                                                            
  

                                               
                                                
                                                                                                  
                                            
  

                                        
                                         
                                                                      
  

                                      
                                       
                                                
                                  
  

                                                     
                                                      
                                                
                                                  
  

                               
                                
                                  
                                        
  

                                            
                                             
                                                    
                                                    
                                                               
                                                          
                                                                     
                                                         
                                                         
                                                        
                                                           
  

                                          
                                           
                                                    
                                                              
                                                                             
                                                                         
                                                                  
                                                            
                                                          
                                                         
                                                                                      
                                                                           
                                                            
                                                            
  

                                                      
                                                       
                                  
                                            
                                        
  

                                                
                                                 
                                                            
                                                         
                                                   
  

                                                  
                                                   
                                                                          
                                                         
                                                                             
  

                                                  
                                                   
                                                            
                                                            
  

                                                  
                                                   
                                                    
                                                              
                                                 
                                                                               
                                                                                      
                                                                                         
                                                                                         
  

                                     
                                      
                                                             
                                                           
                                                                    
  

                                 
                                  
                                                      
                                                 
  

                                       
                                        
                                      
                                         
                                            
  

                                 
                                  
                                  
                                        
                                         
                      
                            
                                                       
                                                   
                                            
                                              
                                                               
                                
                                                           
                                                       
                        
                                                                         
                                                                         
                                                                      
                                                                  
                                                      
                                                  
                         
                                                         
                                                                           
                                                                          
                                                                         
                                                                         
                                              
                                             
                                              
                                                               
                                                             
                                                           
                                                  
                                                     
  

                                 
                                  
                                                
                                   
  

                                                  
                                                   
                                                 
  

                                       
                                        
                                                     
                                            
                                        
                                         
                                                    
  

                                                
                                                 
                                                                 
  

                                                        
                                                         
                                                                          
  

                                                   
                                                    
                                                                    
  

                                           
                                            
                                  
                                        
                                              
                                                   
                                                         
                                         
  

                                        
                                         
                                                         
                                              
                                                  
                                             
                                                          
                                                      
                                                      
                                                                   
                                          
                                                      
  

                                           
                                            
                                      
                                              
                                                    
                                                               
                                                  
                                                           
                                                                 
                                                                  
                                                             
                                                              
                              
                                                         
                                               
                                                                
                                                       
                                                              
                                                                                 
                                                                
                                                              
  

                                        
                                         
                                                
                                                        
  

                                           
                                            
                                        
                                                           
                                                 
                                                    
                                                 
                                                                 
                                                                  
                                                              
                                                          
                                               
  

                                                          
                                                           
                                                       
                                                                    
                                                                
  

                                                       
                                                        
                                             
                                                
  

                                                    
                                                     
                                                                                        
                                                                                        
                                                                                          
                                                                                          
                                                                   
  

                        
                         
                                  
                                          
                                       
                                           
                                            
                                                          
                                                            
  

                           
                            
                                  
                                        
                                            
                                                          
                                                            
  

                                 
                                  
                              
  

                                              
                                               
                                           
                                            
  

                                     
                                      
                                                
                                     
  

                                       
                                        
                                                
                              
  

                                           
                                            
                                                      
                                                       
                                                           
  

                                      
                                       
                                                         
                                                           
                                                                
                                                                 
  

                                       
                                        
                                                                   
                                                                    
                                                                     
  

                                   
                                    
                                                    
                                                    
                                                      
  

                                       
                                        
                                                    
                                                       
                                                       
                                                            
  

                                       
                                        
                                               
                                               
                                       
                                               
  

                                                
                                                 
                                          
  

                               
                                
                                                   
  

                                                                            
                                                                             
                                      
                                               
  

                                                                 
                                                                  
                                      
                                         
                                               
                                                     
                                                                     
                                                                                    
  

                                                      
                                                       
                                      
                                               
                                           
                                            
                                             
                                                                       
  

                                                         
                                                          
                                      
                                        
                                                        
  

                                                
                                                 
                                                            
  

                                                    
                                                     
                                             
                                                                  
                               
                                                                    
  

                                                                             
                                                                              
                                                    
                                          
                                                                     
  

                                                                  
                                                                   
                                         
                                                       
  

                                                           
                                                            
                                      
                                                 
                                                                                             
                                                                               
  

                                                 
                                                  
                                                          
                                                                 
  

                                                
                                                 
                                                           
                                                   
                                                    
                                                                    
  

                                       
                                        
                                                         
                                                                
                                                                
                                                            
                                                              
                                                            
                                                      
  

                                                        
                                                         
                                            
                                                         
                                                               
                                                
  

                                    
                                     
                                                                                                   
                                           
  

                                    
                                     
                                               
  

                                                      
                                                       
                                         
                                                  
                                               
                                                      
  

                                                    
                                                     
                                                                                           
  

                                                  
                                                   
                                         
                            
                                               
                                                      
  

                                                
                                                 
                                                                                   
  

export const AlertsIntegrationsTypeEnumValues = Object.freeze({
  MSFT_TEAMS: 'MSFT_TEAMS', 
  CLIQ: 'CLIQ', 
  SLACK: 'SLACK', 
  WEBHOOK: 'WEBHOOK', 
  AWS_SECURITY_HUB: 'AWS_SECURITY_HUB', 
  JIRA: 'JIRA', 
  SERVICE_NOW: 'SERVICE_NOW', 
  DATADOG: 'DATADOG'
});


                                                                                          

export const AlertsIntegrationsStatusEnumValues = Object.freeze({
  ENABLED: 'ENABLED', 
  DISABLED: 'DISABLED', 
  CONNECTION_ERROR: 'CONNECTION_ERROR'
});


                                                                                              

                                  
                                   
                                  
                                        
                                               
                                       
                                                            
  

                                                                 
                                                                  
                                       
                                        
                                                      
  

                                                                    
                                                                     
                                            
                                              
                                        
                                             
                                                                                           
                                                                                                 
                                                                         
                                                                                  
  

                                                      
                                                       
                                                
                                                                          
  

                                                    
                                                     
                                                
                                               
                                              
                                                                                 
  

                                                              
                                                               
                                                                      
                                                                        
                                                                             
                                                                           
                                                                             
                                                                             
                                                                               
                                                                            
                                                                        
                                                                               
  

                                                                   
                                                                    
                                                                      
                                                                        
  

                                                  
                                                   
                                       
                                                                                      
                                                                         
                                                                  
                                                                       
                                                                                   
  

                                                               
                                                                
                                        
                       
                                                    
  

                                                           
                                                            
                                                                                                   
  

                                                                              
                                                                               
                                        
                                      
  

                                                                                   
                                        
                                      
  

                                                                   
                                                                    
                                              
                                                
                                                                                 
                                                                                               
                                                                                                  
                                                                                 
                                                                                               
                                                                                 
                                                                               
  

                                                        
                                                         
                                                  
                                              
                                              
                                                 
                                                
                                                        
                                                                                              
                                                                                              
                                                                                           
                                                                           
                                                                               
                                                                       
                                                                             
                                                                             
  

                                     
                                      
                                                  
                                              
                                              
                                                                           
  

                                  
                                   
                                                  
                                             
                                                                           
                                                               
                                                                 
                                                               
  

                                         
                                          
                                  
                                        
                                               
                                       
                                                            
                                                                      
                                                                         
                                                                   
                                                                
                                                          
                                              
                                       
  

                                                           
                                                            
                                                          
                                                               
                                                               
                                         
  

                                              
                                               
                                                
                                                 
  

                                                                 
                                                                  
                                               
                                                      
                                                    
                                                
                                        
                                                          
                                                                             
                                          
                                                  
  

export const IosSdkEventsEnumValues = Object.freeze({
  ios_sdk_events: 'ios_sdk_events', 
  android_sdk_events: 'android_sdk_events'
});


                                                                      

export const TrustKitProtectionStatusValues = Object.freeze({
  PROTECTED_WITH_ACTIVE_MODE: 'PROTECTED_WITH_ACTIVE_MODE', 
  PROTECTED_WITH_PASSIVE_MODE: 'PROTECTED_WITH_PASSIVE_MODE', 
  UNPROTECTED: 'UNPROTECTED'
});


                                                                                      

                                              
                                               
                                      
                                          
                                            
                                       
  

                                                       
                                                        
                                                
                                                                    
  

                                                          
                                                           
                                                                           
  

                                                              
                                                               
                                       
                                               
                                                 
  

                                     
                                        
  

                                    
                                                
  

                                         
                                           
  

                                           
                                           
  

                                    
                                           
  

                                     
                                                          
  

                                                
                                                 
                                         
                                                       
  

                                              
                                               
                                         
                                                         
                                                 
                                                    
  

                                              
                                               
                                         
                                                    
  

                                              
                                               
                                           
                                         
                                                    
  

                                         
                                          
                                      
                                        
  

                                         
                                          
                                                          
                                         
                                         
                                         
  

                                             
                                              
                                           
                                                            
  

                                                   
                                                    
                                         
                                                     
                                                         
                                                 
                                                            
  

                                        
                                         
                                                
                                                
                                                  
                                               
                                               
                                        
                                              
                                                
  

                                           
                                            
                                          
                                        
                                              
                                                
                                            
                                               
                                          
  

                                                 
                                                  
                                        
                                        
                                              
  

                                             
                                              
                                                                     
  

                                                                 
                                                                  
                                         
                                        
  

                                                            
                                                             
                                                                  
                                        
                                                 
  

                                                            
                                                             
                                          
                                              
                                         
                                             
  

                                                 
                                                  
                                                               
                                                                
                                                 
  

                                               
                                                
                                                                       
  

                                       
                                        
                                                       
  

                                             
                                              
                                        
                                                 
                                                 
  

                                         
                                          
                                                       
  

                                                  
                                                   
                                             
                                               
                                               
                                               
                                              
                                           
                                              
                                                           
                                              
                                                          
  

                                              
                                               
                                                
                                                           
  

                                  
                                   
                                                  
  

                                          
                                           
                                                  
                                                  
                                                  
                                                            
  

                                      
                                       
                                                
                                                
  

                                                      
                                                       
                                                        
                                                           
  

                                                                       
                                                                        
                                       
                                                
                                                     
  

                                                  
                                                   
                                                
                                                 
                                               
                                                    
  

                                                      
                                                       
                                                
                                                 
                                                   
                                                    
  

                                           
                                            
                                                
                                              
  

                                                 
                                                  
                                        
                                                 
                                                 
  

                                             
                                              
                                                           
  

                                     
                                      
                                                   
  

                                               
                                                
                                                    
                                              
  

                                         
                                          
                                                           
  

                                                
                                                 
                                                
                                                
                                              
  

                                                  
                                                   
                                                         
                                                   
                                              
  

                                                               
                                                                
                                                  
                                                   
                                                
                                         
  

                                                    
                                                     
                                                    
                                              
  

                                                          
                                                           
                                                    
                                                   
                                         
  

                                              
                                               
                                                                          
  

                                                  
                                                   
                                                                                  
  

                                         
                                          
                                                          
  

                                           
                                            
                                                               
                                                                              
  

                                             
                                              
                                                                  
                                                                                 
  

                                                        
                                                         
                                                                    
                                                                       
                                                                                   
  

                                                              
                                                               
                                                    
                                                           
                                                      
                                                              
  

                                             
                                              
                                  
                                        
                                            
                                             
                                              
                                                    
                                                            
                                                      
                                                     
                                                                 
                                                  
                                                      
                                                     
  

                                         
                                          
                                                    
                                                             
  

                                                 
                                                  
                                        
                                        
                                            
                                              
                                                
  

                                             
                                              
                                                             
  

                                      
                                       
                                                    
                                                           
                                                              
                                                      
  

                                 
                                  
                                         
                                        
                              
                                              
                                                    
                                              
                                           
                                                              
                                                        
  

                                   
                                    
                                                     
                                                
                                       
  

                               
                                
                                            
                                                                  
                                                                     
  

                                             
                                              
                                        
                                    
  

                                    
                                     
                                                      
                                                          
  

                           
                            
                                                                      
                                                                        
                                                                       
  

                                         
                                          
                                                                 
                                                                    
                                                                                         
                                                                                                          
  

                                     
                                      
                                                                
                                                                                    
                                                                                                     
  

                                       
                                        
                                                                        
                                                                                         
  

                                 
                                  
                                                            
                                                              
                                                                                      
                                                                                                       
  

                                      
                                       
                                                                       
                                                             
                                                             
                                                                                        
  

                              
                               
                                                    
                                                    
  

                              
                               
                                                                             
                                                                             
                                                                            
                                                                            
  

                              
                               
                                                                      
                                                                     
  

                                  
                                   
                                
                                 
                                   
                                 
                               
                                   
                                     
                                       
                                     
  

                            
                             
                                                              
                                                        
                                               
                                                         
                                                
                                        
                                                           
  

                                  
                                   
                                                              
                                                        
                                               
                                                         
                                  
                                                           
  

                                            
                                             
                                                  
                              
                                                           
                                                   
                                                        
                                         
                                          
                                    
                                    
                                           
                                              
                                                 
  

                                             
                                              
                                                                                         
                                                                                  
                                                                                                   
                                                                                                                    
  

                                                     
                                                      
                                                                               
                                                                                         
                                                                         
                                                                                                          
  

                                                   
                                                    
                                                                               
                                                                                         
                                                                         
                                                                                                          
  

                               
                                
                                                   
                                                  
                                                  
                                                                   
  

                                       
                                        
                                                                        
                                                                                                         
                                                                                        
                                                                      
  

                                  
                                   
                                                                           
                                                           
                                                         
  

                                     
                                      
                                                                     
                                                                      
                                                                                      
  

                                       
                                        
                                                                    
                                                                            
                                                                           
                                                                                            
  

                                         
                                          
                                                          
                                                      
  

                                     
                                      
                                                                                             
                                                                                             
                                                                            
                                                                            
                                                                             
                                                                             
  

                                    
                                     
                                                       
                                                                        
  

                                       
                                        
                                                     
                                                                    
                                                                     
  

                                                
                                                 
                                                             
                                           
                                                
                                                                    
                                             
                                                                                  
                                                                                      
                                                        
                                           
                                           
                                         
                                                 
                                                        
  

                                                       
                                                        
                                                     
                                                        
                                                
                                                      
                                                    
  

                                           
                                            
                                                
                                                
                                                
                                                          
                                                       
                                                                
  

                                     
                                      
                                                      
                                          
  

                                           
                                            
                                               
                                                              
  

                                    
                                     
                                                      
                                                    
                                                                 
  

                                       
                                        
                                
                                                    
                                                           
                                                      
                                                              
                                                     
                                                         
                                                         
                                                         
                                                                       
                                                       
                                                                
  

                                         
                                          
                                                         
                                                
  

                                           
                                            
                                                                               
                                                                                
                                                                              
                                                                                      
                                                                                
  

                                               
                                                
                                                                
                                                                        
                                                               
                                                                          
                                                                         
                                                                
  

                                         
                                          
                                  
                                        
                            
                                                       
                                                   
                                                              
                                                               
  

                                                 
                                                  
                                                
                                                      
  

                                            
                                             
                                                     
                                                         
                                                         
                                                             
                                                             
  

                                           
                                            
                                                
                                              
                                             
                                              
                                                   
                                                       
  

                                      
                                       
                                                
                                                     
  

                                                                            
                                                                             
                                            
                                        
                                         
  

                                                              
                                                               
                                                              
                                                           
                                        
  

                                                      
                                                       
                                      
                                        
                                              
                                                   
                                       
                                     
                                            
                                                 
                                        
                                          
                                               
                                                  
                                                             
                                                            
                                                     
                                                          
                                                                 
                                                                                              
  

                                                 
                                                  
                                                
                                      
                                              
                                            
                                                      
                                                        
                                        
                                              
                                                   
                                       
                                     
                                            
                                                 
                                                 
                                                  
                                                
                                               
                                                          
                                                               
                                                        
  

                                           
                                            
                                                       
                                        
  

                                       
                                        
                                                       
  

                                                 
                                                  
                                      
                                              
                                            
                                        
                                              
                                                   
                                       
                                     
                                            
                                                 
                                               
                                                               
                                                          
                                                        
                                                      
  

                                             
                                              
                                                
                                                                    
  

                                          
                                           
                                      
                                          
                                        
                                              
                                                   
                                       
                                     
                            
                                                 
  

                                               
                                                
                                                
                                                           
  

                                        
                                         
                                                
                                                    
  

                                         
                                          
                                                
                                                     
  

                                        
                                         
                                                
                                                   
  

                                          
                                           
                                                
                                                     
  

                                                             
                                                              
                                    
                                                               
  

                                                      
                                                       
                                    
                                                          
  

                                                       
                                                        
                                    
                                              
  

                                                      
                                                       
                                    
                                                 
  

                                                        
                                                         
                                    
                                                  
  

                                                           
                                                            
                                                   
                                                                    
                                                                              
                                                               
                                                              
                                                               
                                                               
                                                                
                                                                
  

                                                         
                                                          
                                                          
                                                           
                                                            
                                  
  

export const ApiProtectRestfulApiSummaryFieldConnectorTypeEnumValues = Object.freeze({
  NETWORK: 'NETWORK', 
  CLOUD_ACCOUNT: 'CLOUD_ACCOUNT', 
  API_PROTECT_SDK: 'API_PROTECT_SDK'
});


                                                                                                                                        

                                                         
                                                          
                                                            
                                                        
                                                        
                                                    
                                                                      
                                                  
                                                 
  

                                                
                                                 
                                  
                                        
                                       
                                            
                                                
                                                          
                                                           
                                                           
                                                                        
                                            
                                                  
                                                         
                                                       
                                                              
                                              
                                                       
  

                                             
                                              
                                                
                                                         
  

                                        
                                         
                                           
                                                     
                                               
  

                                          
                                           
                                       
                                                         
                                                         
                                                        
  

                                                           
                                                            
                                      
                                        
                                                           
                                       
                                        
                                          
                                                
                                                  
                                                
                                                          
                                     
                                               
                                                
  

                                                    
                                                     
                                  
                                        
                                              
                                                
                                         
                                                   
                                                               
                                                              
                                                               
                                                               
                                                              
                                                             
                                                              
                                                             
                                                              
                                                             
                                                                
                                                                
                                                                    
                                                                  
                                                                   
                                                                           
                                                                    
                                                                                 
                                                                              
                                                                            
                                                                                
                                                                                
  

                                          
                                           
                                  
                                            
                                      
                                                                 
                                                       
                                                                   
                                                  
  

                                                                
                                                                 
                                                
                                                            
  

                                                       
                                                        
                                                      
                                                      
                                                           
  

                                             
                                              
                                                  
                                                  
                                                           
                                                                  
  

                                                                     
                                                                      
                                                           
  

                         
                          
                                                
                                                 
  

                                   
                                    
                          
                                      
  

                                            
                                             
                                                     
  

                                      
                                       
                                                
                                                      
                                                            
  

                              
                               
                                    
                                                 
                                                
                                               
                                                 
                                                                     
                                                           
  

                                          
                                           
                                                
                                  
  

                                                          
                                                           
                                                         
                                                           
                                                    
                                                             
                                                         
  

                                    
                                     
                                          
                                                    
                                                       
                                                   
                                                            
  

                                            
                                             
                                              
  

                                                
                                                 
                                                         
                                                           
  

                                               
                                                
                                                    
  

                                          
                                           
                                             
                                             
  

                                        
                                         
                                                      
                                               
                                                  
                                               
                                                  
  

                                    
                                     
                                             
                                        
  

                                                           
                                                        
  

                                              
                                                                  
                                                
  

                                                     
                                                 
                                               
                                                     
  

                                                          
                                                           
                                               
                                                            
                                                     
  

                                                                 
                                                                  
                                                      
                                                         
                                               
                                                  
                                                                
  

                                                                 
                                                                  
                                             
                                                          
                                                                                  
  

                                                                 
                                                                  
                                                                                                    
                                                                          
  

                                                         
                                                          
                                                                                                    
                                                                          
                                                         
  

                                                         
                                                          
                                                               
                                                                   
                                                              
                                                       
                                                                                   
                                                                                          
                                                                                                       
                                                                                    
                                                                                         
                                                                                              
                                                                                   
                                                                                     
                                                                                         
                                                                                     
  

                                                                                     
                                                                                      
                                                   
                                                    
                                            
                                        
                                                           
  

                                                         
                                                          
                                            
                                        
                                              
                                            
  

                                                                       
                                                                        
                                             
                                                                                                          
                                            
                                                   
                                       
                                        
  

                                                                       
                                                                        
                                              
                                                       
                                        
  

                                                                  
                                                                   
                                                
                                                                         
  

                                                                             
                                                                              
                                              
                                              
                                             
                                               
                                                        
                                    
  

                                                                         
                                                                          
                                           
                                              
                                                                        
                                    
  

                                                                    
                                                                     
                                                
                                                                             
  

                                            
                                             
                                        
                                           
                                                         
                                                     
                                              
  

                                                     
                                                      
                                                
                                                   
  

                                                    
                                                     
                                                
                                                                                  
  

                                           
                                            
                                                                                            
                                                                      
                                                                 
                                                                         
                                                                         
                                                                   
                                                                              
                                                                  
  

                                                                       
                                                                        
                                        
                                                
  

                                                                  
                                                                   
                                                
                                                                                   
                                                   
                                                    
                                               
                                                         
                                            
                                                 
                                                             
                                                      
                                                            
                                                      
                                        
                                            
                                                    
                                                          
                                                   
                                                       
                                       
                                        
                                                      
                                                                      
  

                                                            
                                                             
                                                
                                                                                      
  

                                            
                                             
                                                    
                                                           
  

                                                              
                                                               
                                            
                                        
  

                                                          
                                                           
                                                      
                                                      
                                                        
                                                         
  

                                                   
                                                    
                                             
                                                  
                                                 
                                                  
                                                      
  

                                       
                                        
                                        
                                                  
                                                 
                                               
                                                   
                                                 
                                                          
                                                 
                                                              
                                         
                                                            
                                        
  

                                             
                                              
                                                  
                                               
                                        
                                              
                                                            
                                                
                                                              
                                         
                                                    
                                                             
  

                                           
                                            
                                        
                                               
                                              
                                      
                                                    
                                                              
                                         
                                                            
                                        
  

                                                         
                                                          
                                        
                                         
                                             
                                                      
                                               
                                                  
                                                    
                                                     
                                               
                                                              
  

                                                         
                                                          
                                           
                                        
  

                                                    
                                                     
                                           
                                        
  

                                                    
                                                     
                                            
                                                          
                                                     
  

                                                   
                                                    
                                            
                                                
  

                                                                    
                                                                     
                                             
                                           
                                         
                                                 
  

                                                                
                                                                 
                                                
                                                                                             
  

                                                          
                                                           
                                                          
                                                
  

                                                       
                                                        
                                                
                                                     
  

                                                                            
                                                                             
                                                         
                                                           
                                                    
                                                        
                                                    
  

                                                   
                                                    
                                                   
                                              
                                        
                                         
                                                     
  

                                                               
                                                                
                                        
                                         
                                              
                                                   
                                     
                                                                     
  

                                                        
                                                         
                                                                          
  

                                                                
                                                                 
                                                
                                                                    
  

                                   
                                    
                                                                     
                                                      
                                                            
                                                           
                                                               
                                                              
                                                           
                                                  
  

                                            
                                             
                                  
                                        
                                         
                                              
                                                   
                                                       
  

                               
                                
                                  
                                                                 
                                                               
                                                           
                                                                  
                                                              
                                              
                                                  
                                               
                                                     
                                                    
                                                    
                                                                  
                                                             
                                                     
                                                        
  

                                              
                                               
                                                
                                    
  

                                             
                                              
                                                   
                                                      
                                                          
                                               
                                             
                                                 
                                               
  

                                             
                                              
                                                   
                                                      
                                                          
                                                    
                                               
  

                                                     
                                                      
                                  
                                                                 
                                                               
                                                           
                                                                  
                                                              
                                              
                                                  
                                               
                                                     
                                                    
                                                    
                                                               
                                                         
                                                                   
                                                                       
                                                         
  

                                                  
                                                   
                                                            
  

                                          
                                           
                                                       
                                          
                                           
                                                    
                                              
  

                                                  
                                                   
                                                                          
  

                                             
                                              
                                                          
                                                             
                                                
  

                                                     
                                                      
                                                          
                                                             
                                                
  

                                          
                                           
                                           
                                              
                                        
                                         
  

                                              
                                               
                                                                 
  

                                                 
                                                  
                                                     
                                                           
                                                             
                                                                   
                                                    
                                                          
                                             
                                                   
                                                       
  

export const MobileProtectSecurityFindingsHackerToolkitValues = Object.freeze({
  REMOTE_P1_EXPLOITS: 'REMOTE_P1_EXPLOITS', 
  GATEKEEPER_GAMBLING: 'GATEKEEPER_GAMBLING', 
  KEYS_TO_THE_KINGDOM: 'KEYS_TO_THE_KINGDOM', 
  APP_STORE_ANSWERS: 'APP_STORE_ANSWERS', 
  COFFEE_SHOP_COMPROMISE: 'COFFEE_SHOP_COMPROMISE', 
  SOFTWARE_COMPOSITION_ANALYSIS: 'SOFTWARE_COMPOSITION_ANALYSIS', 
  MOBILE_PHISHING: 'MOBILE_PHISHING', 
  HACK_AND_EXTRACT: 'HACK_AND_EXTRACT', 
  LEAKY_APIS: 'LEAKY_APIS', 
  HACK_ME_AMADEUS: 'HACK_ME_AMADEUS'
});


                                                                                                                          

                     
                      
                                                                              
                                                                                 
                                                                        
                                                                                         
                                                                                                      
                                                                                                          
                                                                          
                                                          
                                                           
                                                                                     
                                                                  
                                                         
                                                                        
                                             
                                                     
                                                                      
                                                                   
                                                                     
                                             
                                                 
                                                  
                                                                                     
                                                                     
                                              
                                              
                                                           
                                           
                                    
                                                         
                                                  
                                                    
                                                  
                                                     
                                                                             
                                               
                                                    
                                                        
                                                       
                                                      
                                                             
                                                                   
                                                       
                                                                            
                                                                     
                                
                                     
                                                    
                                                  
                                                   
                                                                  
                                                               
                                                                                   
                                                                                      
                                                       
                                                                    
                                                              
                                                                     
                                                                  
                                                                        
                                               
                                                                   
                                               
                                                                    
                                                                            
                                                                
                                                                                     
                                                                                             
                                                                                         
                                                                
                                     
                                                    
                                    
                                                                            
                                                            
                                                 
                                      
                                                                     
                                                        
                                                            
                                                                 
                                                                    
                                            
                                                                 
                                                                            
                                               
                                                                    
                                            
                                                  
                                                                     
                                                     
                                                     
                                                                                     
                                                     
                                                                      
                                                                        
                                                                                    
                                             
                                             
                                                    
                                                       
                                                                      
                                                      
                                                                 
                                                 
                                                         
                                                            
                                                                
                                                        
                                                                  
                                                      
                                                        
                                                 
                                             
                                                   
                                               
                                                                    
                                                  
                                            
                                                  
                                                 
                                                    
                                                
                                                     
                                                      
                                                     
                                                                
                                                             
                                                           
                                                                
                                                            
                                                                  
                                                  
                                                         
                                      
                                           
                                     
                                                    
                                                               
                                                               
                                                         
                                                                 
                                                   
                                                               
                                              
                                                             
                                                         
                                           
                                                
                                                
                                                                       
                                                           
                                                              
                                                                     
                                                                
                                                                               
                                                         
                                                               
                                                           
                                               
                                                 
                                             
                                                                                     
                                                       
                                                     
                                           
                                                 
                                                        
                                                 
                                               
                                                       
                                     
                                         
                                      
                                                           
                                                                           
                                                                               
                                                                                         
                                                                             
                                      
                                                        
                                                          
                                           
                                                                           
                                               
                                                   
                                
                                                                         
                                                                    
                                                                
                                                                       
                                                   
                                                     
                                
                                                           
                                            
                                                
                                             
                                            
                                                                          
                                                               
                                                                         
                                                                    
                                               
                                                                                      
                                                                              
                                                          
                                                    
                                                    
                                    
                                                              
                                                                    
                                                          
                                                             
                                                                              
                                                                                       
                                                                                   
                                                                                 
                                                                              
                                                                                        
                                                                                             
                                                                                                           
                                           
                                                                 
                                             
                                                            
                                                          
                                      
                                
                     
                                                             
                                           
                                                
                                                   
                                                     
                                               
                                                              
                     
                                                                       
                                                                          
                            
                                                         
                                                                                        
                                                 
                                                                  
                                                           
                                    
                                                                               
                                                                      
                                                           
                                        
                                                                            
                                                               
                                                     
                                                                    
                                                 
                                                                      
                                               
                                                                      
                                                                     
                                                    
                                                       
                                              
                                           
                                   
                                                                        
                                                                       
                    
                                                                   
                                                      
                                                  
                                   
                                               
                                                               
                                                            
                                                                  
                                                                   
                                                                                         
                                                                        
                                                           
                                   
                    
                            
                                          
                                            
                                  
                                         
                                                             
                                                  
                                                                             
                                                    
                                                                   
                                                          
                                                                 
                                                              
                                                                     
                                                                  
                                                                            
                                                         
                                                 
                                                            
                                                                    
                                                 
                                                  
                                                         
                                                          
                                                     
                                                         
                                                                                     
                                                                              
                                                           
                                                            
                                                                
                                            
                                             
                                        
                                                  
                                                             
                                                         
                                            
                                                                   
                                                                                  
                                          
                                                          
                                                                         
                                                                  
                                                                                 
                                                                   
                                                                     
                                                                  
                                                                      
                                                                            
                                                          
                                                                         
                                                                                        
                                                                                                   
                                                                                                              
                                                       
                                                           
                                                     
                                                                                        
                                                                                  
                                   
                                             
                                    
                                                                      
                                        
                                              
                                                 
                                                                
                                                                          
                                                                        
                                                                        
                                                                           
                                                                           
  


                                                        
                                            
  


                                                                 
                                            
                                                                                      
                                                                          
                                                                             
                                                                         
                                              
  


                                                                        
                                    
                                            
  


                                                                          
                                    
                                            
  


                                                         
                                                    
                                            
  


                                                         
                                              
                                            
  


                                               
                                              
                                            
  


                                          
                                                    
                                                                     
                                                                
                                                               
                                              
                                            
  


                                                  
                                              
                                               
                                             
                                            
  


                                          
                                                            
  


                                          
                                                            
  


                                                  
                                                            
                                                                           
  


                                                      
                                                            
                                                                           
  


                                                            
                                  
  


                                          
                                  
  


                                            
                                                                           
  


                                           
                                                                           
                                                          
  


                                                                  
                                  
                                            
  


                                                      
                                  
  


                                         
                                                             
  


                                              
                                          
  


                                           
                                            
                                                            
  


                                               
                                                    
                                            
                                                        
                                                            
                                                          
  


                                               
                                          
                                            
                                                            
                                                                
  


                                               
                                          
                                            
                                                                
                                                                      
                                                      
                                                            
                                                                
  


                                                           
                                          
                                           
  


                                                        
                                            
                                            
                                           
  


                                                     
                                                    
  


                                                                  
                                                    
  


                                                              
                                        
                                                    
                                            
  


                                               
                                  
                                            
  


                                                      
                                  
                                            
  


                                                   
                                                    
  


                                                       
                                                    
  


                                                     
                                                    
  


                                                        
                                                    
  


                                           
                                                    
  


                                                 
                                                    
  


                                           
                                                    
  


                                                           
                                                    
  


                                                                   
                                                    
  


                                                    
                                                
                                                    
                                            
  


                                                                    
                                        
                                                    
                                            
  


                                                                            
                                        
                                                    
                                            
  


                                                                 
                                        
                                                    
                                            
  


                                                    
                                        
                                                    
                                            
  


                                            
                                                            
  


                                                      
                                  
                                            
  


                                                  
                                                               
  


                                                    
                                  
  


                                                              
                                  
  


                                              
                                        
                                            
  


                                                         
                                        
                                            
  


                                                          
                                            
                                                            
                                                    
  


                                                 
                                            
                                                    
                                                
                                                   
                                                     
                                                       
                                                
                                              
                                            
  


                                                       
                                            
                                        
  


                                          
                                            
                                                         
                                                                           
                                                                       
                                                               
  


                                             
                                            
                                    
                                                                   
                                                                           
                                                                      
                                                               
                                                    
                                                        
                                               
                                                                           
                                                                       
                                                               
                                                       
                                                               
                                                                                         
                                                                                
                                                                       
                                                                                    
                                                
                                                                             
                                                                 
                                                                           
                                                                      
                                                                                      
                                                                       
                                                                            
                                                                 
  


                                              
                                                                 
  


                                              
                                                                 
  


                                               
                                            
  


                                                        
                                         
                                            
                                                            
                                                            
                                                    
                                                                                   
                                                                   
                                                                              
                                                              
  


                                                        
                                                   
                                            
  


                                                                   
                                                       
  


                                          
                                                                           
  


                                              
                                                                           
  


                                               
                                                                           
  


                                                            
                                                                           
  


                                               
                                                         
                                            
                                                                       
                                                                  
                                                                       
                                                         
                                                        
                                                                
                                                   
  


                                                  
                                            
                                              
                                             
                                                        
                                                                
                                                   
                                                                  
                                                                 
                                                                  
                                                               
                                                                 
                                                                                 
                                                               
                                                                        
                                                                  
                                                                       
                                                                        
                                                                       
                                                            
                                                                                      
                                                                 
                                                                            
  


                                                    
                                    
                                             
  


                                            
                                                            
  


                                             
                                            
                                                            
  


                                           
                                            
                                                                       
                                                                       
  


                                               
                                    
  


                                         
                                            
  


                                            
                                            
  


                                            
                                    
  


                                              
                                                                  
                                            
                                                                    
  


                                          
                                               
                                            
                                                              
  


                                            
                                           
                                            
                                                              
  


                                               
                                              
                                            
                                                                 
                                                              
  


                                              
                                                
                                    
  


                                                 
                                    
  


                                              
                                    
                                             
  


                                                 
                                                     
  


                                                    
                                  
  


                                        
                                            
                                               
                                                
                                                                                   
                                                                                
                                                    
                                                           
                                                                  
                                                                
                                                    
                                       
                                                                                
                                                                   
                                                          
                                                            
                                                                    
                                              
  


                                                
                                    
  


                                      
                                            
                                                            
                                                    
                                                                
  


                                         
                                            
                                                    
                                                                
                                                                       
  


                                     
                                    
                                                            
  


                                              
                                            
  


                                                 
                                            
                                                     
                                              
                                                    
                                                                       
  


                                                    
                                    
                                             
  


                                                
                                    
  


                                                    
                                    
  


                                             
                                    
                                             
  


                                                   
                                    
  


                                                           
                                                    
  


                                                   
                                               
  


                                         
                                            
  


                                            
                                            
                                          
                                              
                                                            
  


                                            
                                  
  


                                                        
                                            
                                  
  


                                             
                                           
  


                                                       
                                            
  


                                                  
                                                            
                                            
  


                                           
                                            
                                                                 
                                                                  
  


                                            
                                            
                                             
                                                              
                                                          
                                               
                                                    
                                                            
                                                     
                                   
  


                                                  
                                    
  


                                                                      
                                            
                                    
  


                                               
                                    
  


                                         
                                            
                                        
                                          
  


                                            
                                    
  


                                                
                                    
  


                                                            
                                                            
  


                                       
                                            
  


                                                 
                                            
  


                                      
                                                
                                        
  


                                                 
                                            
                                                         
  


                                         
                                            
                                          
                                                    
                                                                     
                                                            
                                                                  
  


                                                          
                                  
                                            
  


                                              
                                  
  


                                   
                                            
                                             
                                                        
                                               
                                                
                                                    
                                                                  
                                                          
                                                    
                                                            
                                                         
                                                          
                                                         
                                                             
                                                                                
                                                                        
                                                                   
                                              
                                              
  


                                                             
                                            
                                            
  


                                                      
                                    
  


                                                    
                                                         
  


                                                        
                                                               
  


                                               
                                            
                                                                        
                                                       
                                                                        
                                                            
  


                                              
                                    
  


                                   
                                    
  


                                                 
                                    
  


                                           
                                                    
                                            
                                              
  


                                             
                                        
                                                    
                                            
                                              
  


                                          
                                                            
  


                                        
                                                            
  


                                                    
                                         
  


                                                        
                                  
  


                                               
                                                            
  


                                                
                                            
                                                            
                                                    
                                                
  


                                                
                                                
  


                                     
                                                    
                                                                                
                                                                                   
                                                
                                                                                  
                                                                     
                                
                                              
                                    
                                            
  


                                                     
                                                    
                                                              
                                                                                 
                                                                                   
                                              
                                            
                                            
  


                                                        
                                             
  


                                                   
                                            
                                                                
  


                                                             
                                                                 
                                            
  


                                                                            
                                          
  


                                                                      
                                                            
  


                                                                
                                                        
                                                                  
                                                                         
  


                                                                             
                                                            
  


                                                                             
                                          
                                                         
                                            
                                            
  


                                                                                 
                                          
                                            
                                                           
  


                                         
                                                                
                                                                      
  


                                                     
                                                         
  


                                          
                                                                 
                                                            
  


                                   
                                            
  


                                   
                                  
  


                                                  
                                            
                                                    
                                                
                                                   
                                                     
                                                       
                                                
                                              
                                            
  


                                         
                                            
                                
                                
                                                    
                                              
                                               
                                          
                                       
                                        
                                                 
  


                                            
                                            
  


                                                   
                                      
  


                                                          
                                  
  


                                                          
                                            
  


                                       
                                  
                                             
  


                                                         
                                  
  


                                                                   
                                      
  


                                            
                                            
                                                          
                                           
                                                  
                                                     
  


                                                      
                                         
  


                                                 
                                            
                                              
                                                            
                                                          
                                                            
                                        
                                                   
                                                               
                                     
                                               
                                                          
                                        
                                                         
                                                   
                                                          
                                                                           
                                             
                                                                      
  


                                         
                                      
                                             
  


                                                               
                                  
  


                                                   
                                            
                                              
                                              
  


                                                 
                                  
                                                     
  


                                                              
                                  
  


                                                    
                                         
  


                                               
                                            
  


                                                      
                                            
  


                                            
                                            
  


                                                       
                                            
                                             
  


                                                             
                                             
  


                                                       
                                        
                                             
  


                                                
                                        
                                            
  


                                                 
                                            
                                                                  
  


                                                       
                                            
  


                                                     
                                        
  


                                          
                                                  
                                            
                                            
                                  
                              
                                                 
                                              
  


                                  
                                              
                                            
                                            
                                                        
                                                            
  


                                              
                                  
                                              
                                            
                                          
                                              
  


                                                      
                                            
                                                              
                                              
                                               
                                                   
                                                            
                                                   
  


                                                       
                                  
  


                                                        
                                                 
  


                                                             
                                        
                                                    
                                            
  


                                                    
                                                    
  


                                       
                                            
                                                  
                                                    
                                                          
  


                                 
                                    
  


                                 
                                       
                   
  


                                         
                                                            
  


                                    
                                                            
  


                                           
                                                            
  


                                                   
                                              
                                         
                                                                 
                                                                        
                                                                    
                                                                   
                                                                    
                                                                      
                                                                   
  


                                             
                                            
                                         
  


                                                           
                                         
  


                                              
                                  
  


                                                      
                                  
                                       
  


                                                 
                                                            
  


                                                        
                                                            
  


                                                   
                                                            
  


                                                          
                                                            
  


                                                     
                                                            
  


                                                          
                                             
                                            
  


                                                
                                                            
  


                                            
                                            
  


                                                  
                                      
  


                                                      
                                      
  


                                            
                                      
  


                                                 
                                      
  


                                                
                                         
                                                            
  


                                                     
                                      
  


                                              
                                      
  


                                                
                                      
  


                                                               
                                             
                                            
  


                                                           
                                             
                                            
                                            
  


                                                    
                                             
  


                                                    
                                             
  


                                         
                                             
                                            
  


                                          
                                            
                                                                 
                                             
  


                                         
                                          
  


                                                
                                                        
                                                            
                                            
  


                                         
                                            
  


                                                      
                                           
  


                                                              
                                           
  


                                        
                                                    
                                                                  
                                                                   
                                                                       
                                                        
                                                            
  


                                                 
                                                            
  


                                                                
                                                            
  


                                                     
                                            
  


                                                 
                                            
                                                            
  


                                                         
                                    
  


                                                                 
                                    
                                           
  


                                                                       
                                  
  


                                                                             
                                    
                                            
  


                                               
                                                            
  


                                                 
                                                                        
                                            
  


                                                
                                  
  


                                  
                                            
                                         
                                                            
  


                                      
                                      
  


                                                       
                                                            
  


                                        
                                                            
  


                                          
                                                            
  


                                                      
                                                            
                                              
                                            
                                              
                                                                 
                                                                      
                                                    
                                                               
  


                                                         
                                    
  

                                          
                                        
                                        
  

                                      
                                                      
  

                                               
                                                                 
                                                        
  

                                             
                                                            
  

                                        
                             
  

                                             
                                            
                                          
                                                       
  

                                       
                                                           
                                       
                                                 
  

                                       
                                                       
                                       
                                                 
  

                                           
                                             
                                             
                                            
                                                      
  

                                                         
                                            
  

                                                  
                                                                     
                                                                      
  

                                                            
                                          
                                                       
                                           
                                                                        
  

                                                  
                                       
  

                                                           
                                                                      
                                                                       
                                                                
                                                  
  

                                                    
                                                                 
                                                                       
                                                                        
  

                                                              
                                          
                                                       
                                           
                                                                            
  

                              
                       
  

                                               
                                                     
                                          
  

                                              
                                        
  

                                  
                                         
                                                           
  

                                        
                                                           
  

                                                    
                                        
                                           
  

                                            
                                        
                                                           
                                                 
  

                                                               
                                                           
  

                                            
                                          
                                                         
                                                    
                                                                         
  

                                             
                                               
                                                        
                                                 
                                                                  
  

                                                       
                                                                      
                                                                        
                                                                           
                                                                           
                                                                             
                                                                             
                                                                               
                                                                            
                                                                        
                                                                               
  

                                                            
                                                                      
                                                                        
  

                                           
                                       
                                                                  
                                                                         
                                                                                      
                                                                
                                                                            
  

                                                   
                                       
                                                          
                                                              
                                                             
  

                                                           
                                              
                                                
                                                                                      
                                                                                      
                                                                                      
                                                                                    
                                                                                                    
                                                                                                    
                                                                                                       
  

                                                
                                                  
                                              
                                              
                                                 
                                                
                                                        
                                                                                                   
                                                                                                   
                                                                                                
                                                                           
                                                                       
                                                                             
                                                                       
                                                                             
  

                                     
                                                  
                                              
                                              
                                                                           
  

                                  
                                                  
                                             
                                                                           
                                                               
                                                                 
                                                               
  

                                              
                                          
                                               
                                                                      
                                                                         
                                                            
                                                        
                                                  
                                              
                                       
  

                                                          
                                                  
                                              
                                              
                                                      
  

                                                        
                                                  
                                              
                                              
                                                 
                                                
                                                      
  

                                   
                                            
                                                                     
                                                                   
  

                                       
                                            
  

                                  
                                                                       
  

                                                
                                                         
  

                                      
                                               
                                                  
                                                            
                                              
                                                            
                                                      
                                                      
                                                               
                                                          
                                        
  

                                              
                                                            
                                                                             
  

                                             
                                                                  
                                         
                                                                            
                                                                        
                                                                  
                                                               
                                                                                                        
                                                                                                                     
                                                                                                      
  

                                                                        
                                                                           
                                                                           
                                                                             
                                                                             
                                                                   
  

                                                            
                                                               
                                                                                   
  

                                                                           
                                                  
  

                                                                                 
                                                                   
  

                                                                    
                                       
  

                                              
                                         
                                                                          
                                                                                                        
                                                                                                                     
                                                                                                      
                                                                
                                                   
  

                                           
                                      
                                             
                                                           
  

                                                         
                                                    
  

                                                          
                                                               
                                                                       
                                                                                     
  

                                        
                       
  

                                                      
                                               
  

                                                
                                                
  

                                             
                                            
                                               
  

                                               
                                             
                                                   
                                               
  

                                               
                                             
                                             
                                                 
  

                                             
                                                   
                                                 
  

                                              
                                                        
                                                    
  

                                                          
                                                   
  

                                                        
                                              
  

                                                         
                                              
                                             
  

                                              
                                                      
                                                                       
                                                            
                                                                    
                                                                     
  

                                                  
                                                   
                                                    
                                                    
  

                                             
                                            
                                                
                                                
                                                
                                                
                                                                   
  

                                           
                                               
                                       
                                                       
                                                     
                                                                   
  

                                    
                                        
                                               
  

                                                    
                                           
  

                                                  
                                           
                                          
  

                                                    
                                                           
                                                
                                                                  
                                                                
  

                                              
                                                               
                                          
                                                       
                                           
                                                                                        
                                                                                               
  

                                                
                                                        
                                                                   
                                                                         
  

                                                        
                                                 
                                                                  
                                               
                                                    
                                                          
                                                    
  

                                      
                                                  
                                        
                                               
  

                                      
                                                  
                                        
                                               
  

                                               
                                              
  

                                
                                                         
                              
                                                            
                                                            
                                                               
                                                            
                                                            
                                                              
                                                                     
                                                                     
                                                                           
                                                             
                                                               
                                                     
                                                                              
                                                           
                                              
                                             
                                               
                                                        
             
  

                             
                                           
  

                                  
                                           
  

                               
                                 
                              
                                                            
                                                            
                                                             
                                                               
                                                               
                                                            
                                                            
                                                              
                                                                     
                                                                     
                                                      
                                                     
                                                           
                                              
                                             
                                               
                                                        
             
                                                                              
  

                                          
                                                                           
                                                                   
  

                                                          
                                                               
                                                                   
                                                              
                                                       
                                                                                   
                                                                                          
                                                                                                       
                                                                                    
                                                                                         
                                                                                              
                                                                                   
                                                                                     
                                                                                         
                                                                                     
  

                                             
                                                            
  

                                        
                                                        
  

                                                 
                                                                               
                                                                                
                                                                              
                                                                                      
                                                                                
  

                                                    
                                                                      
                                                                        
                                                               
                                                                          
                                                                         
                                                                
  

                                                                        
                                                               
  

                                                                  
                                                   
  

                                                                   
                                              
  

                                                          
                                                           
                                               
  

                                                                  
                                                 
  

                                                                    
                                                  
  

                                          
                                          
                                                    
                                                       
                                                     
                                                              
  

                                                
                                             
  

                                                          
                                  
                                                             
  

                                                        
                                                
  

                             
                                       
                                           
                                            
                                                          
  

                                 
                                    
  

                                             
                                       
  

                                                 
                                                                             
  

                                             
                                                           
                                                                 
  

                                         
                                                                 
                                                               
                                                                        
  

                                                        
                                            
                                        
                                                
  

                                                           
                                               
                                              
                                           
  

                                           
                                            
                                                       
                                                                                            
                                                                                          
  

                                           
                                                  
                                                      
                                                  
                                                              
  

                                              
                                                 
                                              
                                              
                                              
  

                                                  
                                            
                                              
                                              
  

                                                   
                                            
                                              
                                              
  

                                                             
                                      
                                            
                                              
                                              
  

                                              
                                              
                                                            
                                                           
                                                              
  

                                          
                                         
  

                                       
                                              
                                                 
  

                                   
                                          
  

                                        
                                              
                                           
  

                                    
                                           
  

                                                      
                                              
                                              
                                              
                                                       
                                                        
                                                      
                                                  
                                        
                                                
                                                      
                                       
                                         
                                             
                                                       
                                                   
  

                                                
                                              
                                              
                                              
                                                       
                                                        
                                                      
                                                  
                                        
                                                
                                                      
                                       
                                         
                                             
                                                       
                                                   
  

                                                     
                                                                  
  

                                                       
                                                      
  

                                                       
                                             
                                              
  

                                           
                                                                                         
                                        
                                            
                                        
                                         
  

                                                            
                                                                     
  

                                           
                                                                                         
                                                      
                                            
                                            
  

                                              
                                                                                         
                                            
                                            
  

                                          
                                                
                                                       
                                                                        
                                                                                                    
                                                               
                                                                      
  

                                                              
                                      
                                                              
  

                                         
                                                     
                                              
                                                              
                                                              
                                                                     
                                                                     
                                                                                               
  

                                                                    
                                                  
  

                                                        
                                                         
                                                    
                                                 
                                          
                                                                         
  

                                       
                                                    
  

                                                 
                                                            
  

                                                 
                                                                            
                                    
                                  
                                                           
  

                                                
                                                                      
                                                          
                                                                      
                                                             
                                                         
                                                  
                                                  
                                                                  
                                                              
                                                                      
                                                                                 
                                                                     
                                                                                
                                                                            
                                                                            
                                                                      
                                                                           
                                                                   
                                                                
                                                                                    
                                                                           
                                                                              
                                                                
                                                                             
                                                               
                                                                
                                                           
                                                         
                                                     
                                                      
                                                                 
  

                                                
                                                 
                                        
                                                
                                                
  

                                               
                                                                   
                                                      
                                                      
  

                        
                         
                                    
                                           
                                       
                                                                                   
                                               
                                                  
                                                 
                                                        
                                                      
                                                                              
                                                                               
                                                           
                                   
                                                                  
                                                                
                                                 
                                                   
                                                 
                                  
                                                           
                                       
                                                                                 
                                                                                   
                                                       
                                                               
                                                
                                          
                                                   
                                        
                                                            
                                                         
                                                               
                                                                                        
                                                                                          
                                                                   
                                                                                     
                                                             
                                                                 
                                                                 
                                                             
                                                               
                                                               
                                                
                                                                       
                                          
                                                     
                                          
                                 
                                             
                                                   
                                                                           
                                                                                  
                                                             
                                               
                                      
                                                 
                                                
                                              
                                            
                                                             
                                                                                
                                                                
                                                                   
                                    
                                    
                                                       
                                                                                  
                                                                  
                                         
                                                                                                   
                                          
                                             
                                                                        
                                                      
                                                                        
                                          
                                      
                                       
                                         
                                      
                                                   
                                                                     
                                                               
                                          
                                                                      
                                                                    
                                          
                                          
                                                          
                                                             
                     
                     
                                                            
                                                             
                                                           
                                                            
                                                                       
                                                             
                               
                                                  
                                                        
                                                       
                                                 
                                                                 
                                                                  
                                                                
                                                                   
                                                                
                                                                     
                                              
                                         
                                     
                                          
                                                    
                                              
                                                             
                                                      
                                                                  
                                                                                                      
                                                                                        
                                                                                          
                                                                                       
                                                                                  
                                                                                           
                                                                                                                             
                                            
                                                 
                                                                
                                                                  
                                                 
                                              
                                                        
                                                                      
                                                             
                                                                                
                                                                     
                                                                          
                                                                  
                                                                        
                                                
  


                                              
                             
                                  
  


                                                     
                                    
                                  
  


                                                 
                                  
                                                     
  


                                                        
                                  
                                  
  


                                              
                             
  


                                                
                              
  


                                               
                                             
                              
  


                                                    
                                  
                                              
  


                                                      
                                  
                                                
  


                                                                 
                                                  
  


                                                                  
                                    
                                                  
  


                                                                   
                                    
  


                                  
                                    
                                               
                                 
  


                                                        
                                          
  


                                                            
                                        
  


                                                      
                                  
                                       
  


                                                
                                   
  


                                               
                                    
                                                
                                  
  


                                               
                                    
                                  
  


                                                     
                                      
  


                                               
                                        
                                      
  


                                                                 
                                                       
  


                                                                   
                                                         
  


                                               
                                      
  


                                                       
                                         
                                       
  


                                                          
                                         
  


                                            
                            
  


                                                 
                                
  


                                           
                                  
                           
  


                                                        
                                  
                                         
  


                                                   
                                  
                                    
  


                                                      
                                        
  


                                                                    
                                  
                                                    
  


                                                                     
                                                     
  


                                                        
                                         
  


                                                                  
                                               
  


                                                     
                                      
  


                                                       
                                        
  


                                                       
                                        
  


                                                     
                                      
  


                                                      
                                       
  


                                                      
                                       
  


                                                        
                                          
  


                                                          
                                           
  


                                                   
                                    
  


                                                      
                                      
  


                                           
                             
  


                                           
                                  
  


                                                             
                                                   
                                               
  


                                                           
                                           
  


                                                                  
                                       
  


                                                                         
                                       
  


                                                                  
                                         
  


                                              
                               
  


                                              
                                  
  


                                                         
                                      
                                        
  


                                                        
                                      
                                            
  


                                             
                                  
                               
  


                                                    
                                  
                                   
  


                                                     
                                      
  


                                                    
                                          
  


                                                       
                                      
                                      
  


                                                                 
                                      
  


                                              
                             
  


                                          
                                    
                          
  


                                                  
                                                
                                    
  


                                                        
                                    
  


                                                   
                                    
  


                                                                         
                                      
                                                    
  


                                                      
                                  
                                         
  


                                                         
                                  
  


                                                               
                                       
  


                                                              
                                  
                                       
  


                                                              
                                  
                                        
  


                                                        
                                  
                                      
  


                                                 
                           
  


                                                 
                                  
                                 
  


                                                 
                                  
  


                                                             
                                             
  


                                                                     
                                                   
                                         
                                   
  


                                                                       
                                                   
                                         
  


                                                           
                                     
  


                                                                              
                                        
                                                        
  


                                                                            
                                        
  


                                                          
                                        
  


                                                           
                                        
                                     
  


                                        
                         
  


                                        
                                  
                        
  


                                                             
                                  
  


                                                              
                                
  


                                                           
                                           
  


                                                            
                                            
                                            
  


                                                                       
                                                      
  


                                                             
                                            
  


                                       
                                      
  


                                                            
                                       
  


                                                          
                                      
                                               
  


                                                         
                                         
  


                                                                  
                                                 
  


                                                              
                                              
                                
  


                                                                 
                                                 
                                
  


                                                              
                                          
  


                                                                   
                                                 
                                
  


                                                      
                                                      
  


                                                        
                                      
                            
  


                                                  
                                      
                                   
  


                                                            
                                      
                                           
  


                                             
                                    
                               
  


                                                     
                               
  


                                                          
                                             
  


                                                                                
                                                                 
  


                                                                         
                                                           
  


                                                                          
                                                            
  


                                                                        
                                                           
  


                                                                        
                                       
                                                   
  


                                                                          
                                                             
  


                                                                                      
                                               
                                                   
  


                                                    
                                      
  


                                                      
                                       
  


                                                 
                                                   
  


                                                     
                                                 
  


                                                      
                                  
                                       
  


                                                      
                                  
  


                                                      
                                 
  


                                                                 
                                           
  


                                                    
                                                     
  


                                                             
                                                   
  


                                                             
                                    
                                        
  


                                                                  
                                            
  


                                                             
                                   
  


                                                             
                                         
  


                                                        
                                                              
  

                                                                                                      

                                                                


                                                      
                               
                                    
                                                       
                                   
                                                   
                                                          
        
      
   

                                                              


                                                    
                               
                                   
                                                          
                                   
                                                   
                                                          
        
      
   

                                        
                                           
                                   
                                           
                                                 
      
   

                                       
                                           
                                   
                                           
                                        
      
   

                                      
                                           
                                                                                                                                                                                                                                                                                                                                                 
                                   
                                           
                                                 
      
   

                                                         
                                                    
                                                        
   

                                                          
                                                           
  


                                                  
                               
                                 
                                                       
                                   
                                                   
                                                          
        
      
   

                                             
                                       
                   
  


                                     
                               
                   
                                           
                                          
      
   

                                                               
                                                           
  


                                                       
                               
                                 
                                                       
                                   
                                                   
                                                          
                                   
                                             
                                                                
                                         
                                              
                                                         
                                            
                                                   
                                                   
            
          
         
      
   

                                                              
                                            
  


                                                      
                               
                                 
                                                       
                                   
                                                   
                                                          
                                   
                                             
                                                                
                                            
         
      
   

                                                                


                                                      
                               
                                    
                                                       
                                   
                                                   
                                                          
        
      
   

                                              


                                    
                               
                                 
                                                       
                                   
                                                   
                                                          
        
      
   

                                                  


                                        
                               
                                 
                                                       
                                   
                                                   
                                                          
        
      
   

                                                                           
                                                            
                                                                                               
   

                                                               
                                                            
                                                                                           
   

                                                 
                                      
  


                                         
                                  
                                           
                                                       
                                                             
                                            
                                                    
                                                     
         
      
   

                                                         
                                  
  


                                                 
                               
                                                 
                                                       
                                            
                                                    
                                                     
         
      
   

                                                                     
                                                    
  


                                                             
                               
                                             
                                                  
                                  
                                            
                               
                                              
                                       
                                                      
                                                                    
                                  
                                                      
                                                                    
                                      
                                                      
                                                                    
                                     
                                                              
                                                                                                               
            
          
         
      
   

                                                            


                                                  
                               
                                     
                                                        
                                         
                                                        
                                                                                                                                                   
         
      
   

                                                                                


                                                                      
                               
                                 
                                                      
                                    
                                            
                                                                   
         
      
   

                                                               
                                               
  


                                                       
                               
                                     
                                                      
                                        
                                                
                                                                                                                                           
         
      
   

                                                                   
                                                     
                                            
  


                                                           
                               
                                 
                                                       
                                   
                                                   
                                                          
                                   
                                             
                                                                                                                                                      
                               
                                        
                                                             
                                    
                                            
                                                                   
         
      
   

                                                                   


                                                         
                               
                              
                                                  
                                  
                                            
                                                      
                               
                                              
                                       
                                                      
                                                                   
            
          
         
      
   

                                                      
                                    
                                               
                                 
  


                                              
                                  
                 
                                                    
                                    
                                                   
                                                                                                                                                                              
        
      
   

                                                             
                                    
  


                                                     
                               
                                  
                                                         
                                   
                                            
                                         
                               
                                        
                                     
                               
                                        
                                     
                                   
                                            
                                                                                                                                
                              
                                              
                                                          
                                         
                                                 
                                                                                                                                                                                                                                   
                                           
                                            
                                                                        
                                                  
                                             
                                                                  
             
          
         
      
   

                                                                 
                                    
  


                                                         
                               
                                      
                                                             
                                           
                                                   
                                                                                                                                                                                         
                              
                                                           
                                                                                                     
          
         
      
   

                                                   


                                         
                               
                                               
                                                                  
                                   
                                                   
                                                          
                                      
                                                           
                                                             
                                  
                                                
                                                                                                    
                                         
                                                      
                                                                                                                                                                
                              
                                             
                                                                                                             
                                
                                               
                                                                                                               
                                
                                               
                                                                                                                       
                                   
                                                  
                                                                                                                                 
                               
                                              
                                                                                                            
                              
                                             
                                                                                                                                            
          
         
      
   

                                                                  
                                                
                                    
  


                                                          
                               
                                
                                                       
                                 
                                          
                                                                                
                                              
                                                   
                                                                                           
         
      
   

                                                          
                                    
                                                
                                  
  


                                                  
                                  
                              
                                                     
                               
                                        
                                                                                                                                                                                                
                              
                                                       
                                                                                               
          
                                    
                                            
                                                                          
         
      
   

                                                          
                                    
                                  
  


                                                  
                                  
                              
                                      
                                                                                                                                                                                              
                            
                                                     
                                                                                             
        
      
   

                                                        
                                               
  


                                                
                               
                                     
                                                      
                                        
                                                
                                                                                                                                           
         
      
   

                                                 
                                 
  


                                         
                                  
                               
                                                      
                                        
                                                
                                                                                                                                           
         
      
   

                                                                
                                            
  


                                                        
                               
                        
                                                
                                   
                                                   
                                                          
                          
                                    
                                                          
         
      
   

                                                                   
                                            
                                                         
  


                                                           
                               
                            
                                                  
                                   
                                                   
                                                          
                              
                                        
                                                                                                                                                                                                
                              
                                                       
                                                                                               
          
         
      
   

                                                                         


                                                               
                               
                                 
                                                      
                                    
                                            
                                                                          
         
      
   

                                                        
                                    
  


                                                
                               
                                  
                                                         
                               
                                        
                                                                                                                                                                                                                                        
                                            
                                                    
                                                                                                         
                                     
                                            
                                                                                        
           
                                   
                                            
                                                                                                                                                                                                     
                                          
                                                 
                                                 
          
                                   
                                            
                                                                                                                                                                                      
                          
                                          
                                                                                    
                                             
                                                                    
                                                                                      
           
                         
                                   
                                                                                      
                     
                                    
                                                 
                              
                                             
                                                                                 
                                
                                               
                                                                                                                          
                                          
                                      
                                            
                                    
                                            
                                   
                                                  
                                                  
              
            
                               
                                              
                                                                      
                           
                                          
                                                                       
                             
                                            
                                                                  
                       
                                       
                                                                                                                       
          
                               
                                        
                                                   
                                    
                                             
                                                                                                                                          
                                        
                                    
                                          
                                  
                                          
                                                      
                                 
                                                
                                                 
                                                     
                                                             
                                                                                                                                     
               
            
                                        
                                              
                            
                                            
                                           
            
                                    
                                          
                                             
                                      
                                            
                                                                                     
                                       
                                             
                                                                
                                        
                                              
                                                 
          
                                     
                                              
                                            
                                        
                                                                
                                                                                        
                                 
                                           
                                                                                                                                                                                                           
           
                                  
                                           
                                                                                                                                      
                                   
                                           
                                                         
                                        
                                                
                                                          
                                          
                                                 
                                                                                      
                                           
                                                 
                                                                                      
            
           
         
      
   

                                                                          


                                                                
                               
                              
                                                  
                                  
                                            
                                                                                                                          
         
      
   

                                                                      
                                                
                                    
  


                                                              
                               
                                
                                                       
                                 
                                          
                                                                                
                                              
                                                   
                                                                                           
         
      
   

                                                       
                                                         
  


                                               
                               
                                 
                                                       
                                   
                                                   
                                                          
                                   
                                             
                                                                
                                            
         
      
   

                                                                       
                                            
                                              
                                                                       
                                                                  
  


                                                               
                               
                                 
                                                       
                                   
                                                   
                                                                           
                                   
                                             
                                                                
                                            
                               
                                        
                                                                             
                               
                                              
                                                     
          
         
      
   

                                                    
                                         
                                                                                                                                      
                               
                                      
                                                  
                             
                                            
                                                    
                                                 
                                                         
                                                                                                                                 
           
        
                                  
                                        
                                                               
                        
                                        
                                           
        
                                   
                                         
                                                                   
                                    
                                          
                                        
                        
                                        
                                       
        
                                    
                                          
                                                    
      
   

                                                                   
                                    
                                            
  


                                                           
                               
                                 
                                                       
                                   
                                                   
                                                          
                                   
                                             
                                                                                                                                                      
         
      
   

                                                                        
                                    
  


                                                                
                               
                              
                                                     
                               
                                        
                                                 
                                             
                                                                                                                        
           
         
      
   

                                                           
                                  
  


                                                   
                               
                                              
                                                         
                                                                                                                                                                                                                                                                                                     
                          
                                                                      
                                                                                                               
        
      
   

                                                      


                                            
                               
                                   
                                                         
                                              
                                                        
                                                                                          
         
      
   

                                                            
                                                            
                                                                           
  


                                                    
                               
                                    
                                                                    
                                   
                                                   
                                                                           
                                           
                                                                          
                                                                                                                                                                                                                                                                                         
         
      
   

                                                          
                                                            
                                                                           
  


                                                  
                               
                                        
                                                             
                                   
                                                   
                                                                           
                                   
                                                                   
                                                                                                                                                                                                                                                                                                               
         
      
   

                                                
                                                            
  


                                        
                               
                            
                                                   
                               
                                                  
                                                                                                               
                              
                                                  
                                                                                                               
                             
                                                  
                                                                                                               
        
      
   

                                                 
                                                            
  


                                         
                               
                            
                                                           
                                 
                                                                   
                                                                                                                                                                         
                                 
                                                              
                                                                                                 
           
                          
                                                                
                                                                                                                                                             
                                 
                                                              
                                                                                                 
           
        
      
   

                                              
                                                
                                    
  


                                      
                               
                                
                                                       
                                 
                                          
                                                                                
                                              
                                                   
                                                                                           
         
      
   

                                                      
                                    
  


                                              
                               
                                   
                                                               
                                                             
                        
                                                      
                                                   
        
      
   

                                                    
                                            
                                                                  
  


                                            
                               
                             
                                                    
                                   
                                                   
                                                                           
                                
                                          
                                                                                                                                                                                           
         
      
   

                                                 
                                              
                                            
                                                                 
                                                              
  


                                         
                               
                                 
                                                       
                                   
                                                   
                                                                           
                                
                                            
                                                                                                                                                                                                                                                                                                                                                                 
                                         
                                                           
                                                                                                                                                                            
          
         
      
   

                                                                   
                                         
  


                                                           
                                  
                                       
                                                              
                                                                                                                  
      
   

                                                          
                                            
                                  
  


                                                  
                               
                                          
                                                               
                                   
                                                   
                                                                           
                        
                                          
                                                                                                                                                                                                                                                                                           
         
      
   

                                             
                                            
  


                                     
                               
                           
                                                  
                                   
                                                   
                                                                           
                              
                                         
                                                                                                                                                                           
                              
                                                
                                                                                                                                    
                                              
                                                             
                                                                                                                                                                                   
          
                        
                                              
                                                                                                                
         
      
   

                                               
                                            
                                          
                                              
                                                            
  


                                       
                               
                              
                                                    
                                   
                                                   
                                                                           
                              
                                          
                                                                                                                    
                                                
                                                         
                                                                                                                                                                         
                                        
                                                                
                                                                                                                                                                                                             
          
         
      
   

                                               
                                  
  


                                       
                               
                              
                                                    
                               
                                         
                                                                                                                                                                           
                              
                                                
                                                                                                                                    
                                              
                                                             
                                                                                                                                                                                   
          
                           
                                    
                                                                                                                                                                                                                                                                   
                              
                                                
                                                                                                                                    
          
                                    
                                            
                                                                                                                                                     
                               
                                        
                                                                                                                                                                                                                                                           
                        
                                              
                                                                                                                                  
         
      
   

                                                 
                               
  


                                         
                                  
                             
                                                    
                               
                                         
                                                                                                                                                
                              
                                                
                                                                                                                                    
                                              
                                                             
                                                                                                                                                                                   
          
         
      
   

                                                
                                  
                               
  


                                        
                                  
                            
                                                    
                               
                                         
                                                                                                                                                
                              
                                                
                                                                                                                                    
                                              
                                                             
                                                                                                                                                                                   
          
         
      
   

                                                 
                                  
  


                                         
                                  
                               
                                         
                                            
      
   

                                                
                                      
                                        
  


                                        
                                  
                                          
                                         
                                            
      
   

                                               
                                      
                                            
  


                                       
                                  
                                         
                                         
                                            
      
   

                                             
                                                    
                                            
                                              
  


                                     
                               
                             
                                                 
                                   
                                                   
                                                                           
                                
                                         
                                                                                            
         
      
   

                                               
                                        
                                                    
                                            
                                              
  


                                       
                               
                               
                                                   
                                   
                                                   
                                                                           
                            
                                      
                                                                                                              
         
      
   

                                            
                                  
  


                                    
                               
                            
                                                   
                                                                                                                                                                                                                                                                                                                                                                                                                                                      
                                     
                                             
                                                   
                                      
                                                                       
                                                                                                                                                       
        
      
   

                                       
                                            
                                                                  
                                                          
                                                                        
                                                
                                               
                                                    
                                                          
                                                            
                                                         
                                                                   
                                                             
                                                                                
                                                    
                                                         
                                                        
                                             
                                              
                                              
  


                               
                               
                     
                                             
                                   
                                                   
                                                                                                                                                                    
                        
                                               
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  
                                             
                                                       
                                                                           
                                       
                                               
                                                     
                                               
                                                            
                                                                                                                                                                                                                                                                                                     
                         
                                        
                                                                                                                                            
                                         
                                                          
                                                                                                                                                                                                                                                                                                                                                                                                                                                                       
                                                    
                                                                
                                                                                                                
                         
                                                                        
                                                                                           
            
                                                 
                                                                  
                                                                                                                                                           
                                                
                                                                  
                                                                                                                    
                                                
                                                                  
                                                                                                                                                                                                                                                                                                                                                       
                                  
                                                     
                                                                                                                                 
                                     
                                                 
                                                                                
           
         
      
   

                                                
                                        
                                            
  


                                        
                               
                                
                                              
                                   
                                                   
                                                                           
                        
                                         
                                                                                                                                                                                                                                                                                                                                                                                                                                            
                                       
                                               
                                                     
          
         
      
   

                                                          
                                        
                                            
  


                                                  
                               
                                           
                                                        
                                   
                                                   
                                                                           
                                   
                                                            
                                                                                                                                                                                                                           
                                   
                                               
                                                                      
                                 
                                                     
                                                                                                
                                                     
                                                             
                                                                                                                                     
               
            
          
         
      
   

                                       
                                    
  


                               
                               
                     
                                             
                             
                                      
                                                                                                                                          
         
      
   

                                             
                                    
                          
  


                                     
                                  
                         
                                             
                             
                                      
                                                                                                              
         
      
   

                                                    
                                    
  


                                            
                               
                                   
                                                          
                                                                         
      
   

                                                      
                                                         
  


                                              
                               
                                      
                                                            
                                                 
                                                     
                                                                                                                     
         
      
   

                                                          


                                                
                               
                                        
                                                              
                                                                    
      
   

                                                                
                                      
  


                                                        
                                  
                                    
                                                           
                                                                                                                           
      
   

                                                                  
                                        
  


                                                          
                                  
                                      
                                                             
                                                                                                                                  
      
   

                                                                  
                                        
  


                                                          
                                  
                                      
                                                             
                                                                                                                            
      
   

                                             
                                                            
  


                                     
                               
                            
                                                   
                                    
                                                  
                                                                                                                               
                               
                                                                 
                                                                                                                                                                                                                              
                                 
                                                                 
                                                                                                                                                                                                                              
                               
                                                                 
                                                                                                                                                                                                                              
           
        
      
   

                                                            
                                            
                                            
  


                                                    
                               
                                               
                                                            
                                   
                                                   
                                                                           
                                
                                                       
                                                                                                                                                                                                                                           
         
      
   

                                                                 
                                         
  


                                                         
                                  
                                                 
                                              
                                                                 
      
   

                                                          
                                          
  


                                                  
                                  
                                       
                                                             
                                                                                
      
   

                                                     
                                                            
                                            
  


                                             
                               
                                    
                                                       
                                   
                                                   
                                                                           
                                      
                                                
                                                                                                                                                                                                      
                                     
                                                         
                                                                                           
          
         
      
   

                                                         
                                  
                                       
  


                                                 
                                  
                                     
                                              
                                                                                                                                                                           
                                   
                                                       
                                                                                         
        
      
   

                                                         
                                       
  


                                                 
                                  
                                     
                                                            
                                                                                                                                                                                         
      
   

                                              
                                                            
  


                                      
                               
                          
                                                    
                            
                                                
                                                                                                                                                                                                                                                                                                                                                               
                                     
                                                      
                                                                                                                                                                                                      
                                     
                                                      
                                                                                                                                                                                                      
                                       
                                                      
                                                                                                                                                                                                      
                                       
                                                      
                                                                                                                                                                                                      
           
        
      
   

                                                            


                                                  
                               
                                            
                                                                
                                                     
                                                                        
                                                                                                                                                            
                                                     
                                                                        
                                                                                                                                                            
                                                       
                                                                        
                                                                                                                                                            
                                                       
                                                                        
                                                                                                                                                            
                                   
                                                                     
                                                                                          
         
      
   

                                                  
                                                            
  


                                          
                               
                                 
                                                
                                   
                                                  
                                                                    
                                                                        
                                                                                                                                                        
                              
                                                       
                                                                                                                                                                                                                                                                
          
        
      
   

                                                  
                                    
  


                                          
                               
                                 
                                                        
                               
                                         
                                      
                                  
                                           
                                                                                                                                                                                                                                                                     
                                       
                                               
                                                        
                                     
                                            
                                                        
                                   
                                                  
                                                         
              
            
           
                                    
                                             
                                                                                                                                                                                               
                                   
                                          
                                                      
                                 
                                                
                                                        
                                                     
                                                             
                                                                                                                                     
               
            
                                        
                                              
                                                                
                            
                                            
                                                  
            
                                       
                                             
                                                                       
          
                               
                                        
                                                                                              
                                   
                                            
                                                                                                            
                                   
                                            
                                                                                                  
                                      
                                       
                                    
                         
                                   
                                
                                    
                                            
                                         
                               
                                        
                                     
         
      
   

                                                          
                                    
  


                                                  
                               
                                    
                                           
                                                                                                                                                                                                                                                                                                                                                                                                       
                                       
                                                   
                                                                                                                                                                             
                                  
                                                          
                                                                                                                                   
         
      
   

                                                
                                            
                                             
                                                              
                                                          
                                               
                                                    
                                                            
                                                     
                                   
  


                                        
                               
                              
                                                     
                                   
                                                   
                                                                           
                                 
                                           
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                
                                           
                                                                    
                                                                                                    
                                    
                                                                                      
                                                                                                                             
            
                                       
                                                                 
                                                                                                
                                        
                                                                  
                                                                                                                                                                                                                            
                                               
                                                         
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        
          
         
      
   

                                                                      
                                            
                                    
  


                                                              
                               
                                                        
                                                               
                                   
                                                   
                                                                           
                                   
                                               
                                                                                                                                                                                            
                             
                                                            
                                                                                                                                                                                     
                                           
                                                       
                                                                                                                                                                                 
            
                                  
                                            
                                                        
                                 
                                                
                                                                                                                   
                                                     
                                                             
                                                                                                                                     
                                             
                                                                 
                                                                                                                                    
                                                       
                                                               
                                                                                                                                       
                 
               
            
          
         
      
   

                                                            
                                                            
                                                    
  


                                                    
                               
                                            
                                                                  
                                               
                                                        
                                                                                                                                                               
         
      
   

                                                       


                                             
                               
                                    
                                                        
                                                                   
                          
                                                                       
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       
        
      
   

                                                        
                                      
  


                                                
                                  
                                    
                                                           
                                                                     
      
   

                                                   
                                      
  


                                           
                               
                                     
                                                           
                                                                                                                                                                          
      
   

                                                 
                                                
                                            
                                                            
                                                    
  


                                         
                               
                                  
                                                    
                                       
                                             
                                                                                                                                                    
                                   
                                                   
                                                                           
        
      
   

                                                 
                                                
  


                                         
                               
                                  
                                                    
                                                                                                                                                                                                                                                                                                                                                                                                          
      
   

                                                   
                                                
                                    
  


                                           
                                  
                                 
                                                        
                                                              
      
   

                                                               


                                                     
                               
                                        
                                                          
                                   
                                                   
                                                          
                                          
                                                   
                                                                                                                                                                                         
                              
                                                           
                                                                                                     
          
         
      
   

                                            
                                            
                                          
                                                    
                                                            
                                                                     
                                                                  
  


                                    
                               
                           
                                                  
                                   
                                                   
                                                                           
                              
                                        
                                                                                                                                 
                                        
                                                    
                                                                                                                                
                                              
                                                   
                                                                                         
          
         
      
   

                                                           
                                  
                                            
  


                                                   
                               
                                            
                                                                 
                                   
                                                   
                                                                           
                                 
                                                     
                                                              
         
      
   

                                                


                                      
                               
                             
                                                    
                                               
                                                             
                                                             
                                                                              
                                                                                                                                                                                                                                           
                              
                                                        
                                                                                                                                                                                                  
          
        
      
   

                                            
                                          
  


                                    
                               
                           
                                               
                                                                                                                                                                                                                                                                                 
      
   

                                             
                                            
                                                                 
                                             
  


                                     
                               
                            
                                                   
                                   
                                                   
                                                                           
                              
                                                 
                                                                                                                                                                                                                                                                                    
                                        
                                                                     
                                                                                                                                                                                              
          
         
      
   

                                                                
                                      
  


                                                        
                                  
                                    
                                                           
                                                                                                                                   
      
   

                                               
                                    
  


                                       
                               
                              
                                                     
                               
                                         
                                      
                               
                                        
                                     
                         
                                   
                                
                               
                                        
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            
                                      
                                       
                                    
                                   
                                            
                                                                                                            
                                    
                                            
                                         
                               
                                        
                                     
                                    
                                             
                                                                                                                                                                                               
                                   
                                          
                                                      
                                 
                                                
                                                        
                                                     
                                                             
                                                                                                                                     
               
            
                                        
                                              
                                                                
                            
                                            
                                                  
            
                                       
                                             
                                                                       
          
                                   
                                            
                                                                                                  
         
      
   

                                             
                                            
                                  
                                                    
  


                                     
                               
                           
                                                  
                                   
                                                   
                                                                           
                              
                                        
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        
         
      
   

                                                  
                                    
  


                                          
                               
                                  
                                                        
                                         
                                                 
                                                                
                                      
                                                 
                                                                
        
      
   

                                             
                                               
                                            
                                                              
  


                                     
                               
                            
                                                      
                                   
                                                   
                                                                           
                              
                                           
                                                                                                                                                                                                                                                                                                                                                                              
                                         
                                                           
                                                                                                                                                                            
          
         
      
   

                                                     
                                            
                                                                
  


                                             
                               
                                     
                                                       
                                         
                                                 
                                                                                                                                                                                                                                
                                   
                                                   
                                                                           
        
      
   

                                        
                                      
  


                                
                               
                        
                                              
                                                                                                                              
                                 
                                                            
                                                                                          
         
      
   

                                         
                                            
                                         
                                                            
  


                                 
                               
                    
                                                 
                                   
                                                   
                                                                           
                             
                                     
                                                                                                                    
         
      
   

                                          
                                                            
  


                                  
                               
                          
                                                
                                                                                                              
      
   

                                                      


                                            
                               
                              
                                                  
                                  
                                            
                               
                                              
                                       
                                                      
                                                                                                                                                                         
                                  
                                                      
                                                                                                                                                                         
                                      
                                                      
                                                                                                                                                                         
                                     
                                                              
                                                                                                                                                                                                                                                
            
          
         
      
   

                                                  
                                                    
  


                                          
                               
                                             
                                                  
                                  
                                            
                               
                                              
                                       
                                                      
                                                                    
                                  
                                                      
                                                                    
                                      
                                                      
                                                                    
                                     
                                                              
                                                                                                               
            
                              
                                              
                                       
                                                      
                                                                                                                                                                         
                                  
                                                      
                                                                                                                                                                         
                                      
                                                      
                                                                                                                                                                         
                                     
                                                              
                                                                                                                                                                                 
            
                              
                                              
                                       
                                                      
                                                                                                                                                                         
                                  
                                                      
                                                                                                                                                                         
                                      
                                                      
                                                                                                                                                                         
                                     
                                                              
                                                                                                                                                                                 
            
                                
                                              
                                       
                                                      
                                                                                                                                                                         
                                  
                                                      
                                                                                                                                                                         
                                      
                                                      
                                                                                                                                                                         
                                     
                                                              
                                                                                                                                                                                 
            
          
         
      
   

                                                                 
                                       
  


                                                         
                                  
                                     
                                                            
                                                                                                                        
      
   

                                               


                                     
                               
                            
                                           
                          
                                     
                                  
                                               
                                                                                                                                                                                                                                                                                                                                                                                                                                                          
          
         
      
   

                                                        


                                              
                               
                                      
                                           
                          
                                     
                                   
                                                
                                                                                                                                                                                                                                         
          
         
      
   

                                                             
                                                            
  


                                                     
                               
                                              
                                           
                          
                                     
                                    
                                                               
                                                                                                                                                                                                                                    
          
         
      
   

                                                              


                                                    
                               
                                            
                                           
                          
                                     
                                                  
                                                              
                                                                        
                                 
                                                                              
                                                                                                                                                                                                                                                                                                                                 
                                
                                                                              
                                                                                                                                                                                                                                                                                                                                 
                                
                                                                              
                                                                                                                                                                                                                                                                                                                                 
                                  
                                                                              
                                                                                                                                                                                                                                                                                                                                 
            
          
         
      
   

                                                           
                                    
                                             
  


                                                   
                               
                                
                                                               
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         
                                                           
                                                                    
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                
        
      
   

                                                      
                                  
  


                                              
                               
                                      
                                                            
                                                                                     
      
   

                                                         
                                  
                                      
  


                                                 
                                  
                                       
                                     
                                  
      
   

                                                      


                                            
                               
                                    
                                                          
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            
      
   

                                                   
                                            
                                                
                                                                                   
                                                                                
                                                    
                                                           
                                                                  
                                                                
                                               
                                                    
                                       
                                                                                
                                                                   
                                                          
                                                            
                                                                    
                                              
  


                                           
                               
                          
                                                          
                                   
                                                   
                                                                           
                                     
                                                        
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 
                                       
                                               
                                                     
                                     
                                                                     
                                                                             
                            
                                        
                                                                                                                                            
           
         
      
   

                                                              
                                    
  


                                                      
                               
                                     
                                                            
                                      
                                       
                                                                                            
                                 
                                               
                                                                                                                                              
          
                               
                                        
                                                                                                                                                                            
                                     
                                            
                                                                                  
                                        
                                                 
                                                                             
                                       
                                              
                                                         
              
                            
                                            
                                                      
            
           
                                  
                                           
                                                                                                                                                                                                                                                                     
                                       
                                               
                                                        
                                     
                                            
                                                        
                                   
                                                  
                                                         
              
            
           
         
      
   

                                                                     
                                           
  


                                                             
                                  
                                         
                                                                
                                                                                                                       
      
   

                                                                
                                      
  


                                                        
                                  
                                     
                                                  
                                                                             
      
   

                                                              
                                    
  


                                                      
                                  
                                  
                                          
                                                                                                                                                                                                                                                     
      
   

                                                   
                                    
  


                                           
                               
                                  
                                                         
                                  
                                           
                                                                                                               
                               
                                        
                                                                                              
                                   
                                            
                                                                                                                                                                                           
                          
                                          
                                                
          
                                   
                                            
                                                                                                  
                                    
                                             
                                                                                                                                          
                                   
                                          
                                                      
                                 
                                                
                                                        
                                                     
                                                             
                                                                                                                                     
               
            
                                      
                                            
                                                                   
                            
                                            
                                                      
            
                                       
                                             
                                                                       
                                        
                                              
                                            
                            
                                            
                                                  
            
                                        
                                              
                                                        
          
                                     
                                              
                                            
                                        
                                                                
                                                                                        
                                 
                                           
                                                                                                                                                                                                           
           
                                   
                                           
                                        
                                                
                                                          
                                   
                                                 
                                       
                                              
                                                         
              
            
           
         
      
   

                                                    
                                            
  


                                            
                               
                                   
                                              
                                   
                                                   
                                                          
                          
                                                      
                                                                                                                                                                       
         
      
   

                                                          
                                    
  


                                                  
                                  
                                       
                                                             
                                                          
      
   

                                                         
                                            
  


                                                 
                               
                                   
                                                          
                                   
                                                   
                                                                           
                                     
                                               
                                                                                                                                                                                     
         
      
   

                                                                 
                                        
  


                                                         
                                  
                                     
                                                            
                               
                                        
                                        
         
      
   

                                               
                             
  


                                       
                                  
                          
                                                  
                           
                                    
                                                                                                                                                                                                                 
                              
                                                
                                                                          
          
         
      
   

                                          
                                            
                                                            
                                                    
                                                                
  


                                  
                               
                        
                                                
                                   
                                                   
                                                          
                          
                                    
                                                                                                                                                                                                                                                                   
                              
                                                
                                                                                                                                    
          
         
      
   

                                            
                                            
                                                    
                                                                
                                                                       
  


                                    
                               
                           
                                                  
                                   
                                                   
                                                          
                          
                                      
                                                                                                                                                                       
         
      
   

                                         
                                          
                                                            
  


                                 
                               
                       
                                                
                           
                                    
                                                                                                                                                                                                                                                                   
                              
                                                
                                                                                                                                    
          
                               
                                        
                                                                                                                                                                                                                                                                                                 
                              
                                                       
                                                                                                                                                                                              
          
                               
                                         
                                                                                                                                                                           
                                               
                                                             
                                                                                                                                                                                   
                             
                                                
                                                                                                                                    
          
                        
                                              
                                                                                                                
         
      
   

                                               
                                  
  


                                       
                                  
                          
                                         
                                            
      
   

                                                         
                                         
                                            
                                                            
                                                            
                                                    
                                                                                   
                                                                   
                                                                              
                                                              
  


                                                 
                               
                                          
                                                              
                                   
                                                   
                                                                           
                                   
                                              
                                                                                                                                                                                   
                                            
                                                       
                                                                                                                                                                                              
                                                
                                                         
                                                                                                                                 
                                         
                                                             
                                                                                                                                                                       
                                              
                                                         
                                                                                                                                                                                                
                                                  
                                                           
                                                                                                                                   
             
                            
                                                          
                                                                                                                                                             
                                
                                                         
                                                                                                                                                                                                
            
                                      
                                                 
                                                                                   
                                                  
                                                 
                                                           
           
         
      
   

                                             
                                          
  


                                     
                               
                       
                                                
                               
                                        
                                                                                                                                                                                                                                                                                                 
                     
                                      
                                   
                             
                                                       
                                                                                                                                                                                              
          
         
      
   

                                             
                                            
                                                         
                                                                           
                                                                       
  


                                     
                               
                            
                                                  
                                   
                                                   
                                                                           
                              
                                        
                                                                                                                                                                                                                                                                                                 
                              
                                                       
                                                                                                                                                                                              
                              
                                              
                                                     
          
         
      
   

                                               
                                            
                                    
                                                                   
                                                                           
                                                                      
                                                               
                                                    
                                                        
                                               
                                                                           
                                                                       
                                                       
                                                               
                                                                                         
                                                                       
                                                                                    
                                                
                                                                             
                                                                 
                                                                           
                                                                      
                                                                                
                                                                                      
                                                                       
                                                                            
                                                                 
  


                                       
                               
                               
                                                     
                                   
                                                   
                                                                           
                              
                                                                 
                                                                             
                                                
                                                                                               
                                                                                                                                                                                                                                                                                             
                              
                                                                                 
                                                                                                                            
          
         
      
   

                                                       
                                            
                                        
  


                                               
                               
                                         
                                                             
                                   
                                                   
                                                                           
                                   
                                            
                                                                                                                                                      
                                                 
                                                         
                                                                                                                                 
                                            
                                                       
                                                                                                                                                                                              
          
                               
                                        
                                                                                                                                                                                                                                                            
                              
                                                       
                                                                                                                                                                                              
          
                        
                                              
                                                                                                                                  
         
      
   

                                                             


                                                   
                               
                                            
                                                           
                                                    
                                                          
                                                                                                                          
         
      
   

                                                  
                                    
                                  
  


                                          
                                  
                              
                                      
                                                                                                                                                                                                                                                                                               
                            
                                                     
                                                                                                                                                                                            
        
      
   

                                                  
                                        
                                      
  


                                          
                                  
                                
                                         
                                            
      
   

                                                  
                                    
                                                
                                  
  


                                          
                                  
                              
                                                     
                               
                                        
                                                                                                                                                                                                                                                                      
                              
                                                       
                                                                                                                                                                                              
          
                                    
                                            
                                                                          
         
      
   

                                                      
                                    
  


                                              
                               
                                 
                                                   
                                    
                                             
                                                                                                                                                                                                                          
                            
                                           
                                                                                  
                                  
                                          
                                        
                                 
                                                
                                             
            
                                        
                                              
                                                                                                     
                                            
                                                   
                                                   
                               
                                                
                                                            
            
                                        
                                              
                                                                                                       
                            
                                            
                                                  
            
                                    
                                          
                                                                                                         
                                      
                                            
                                                                   
                            
                                            
                                                                                                             
                                  
                                                  
                                                              
              
            
                                       
                                             
                                                                                                                                             
          
                               
                                        
                                                   
                                    
                                            
                                                                                       
         
      
   

                                                       
                                            
                                                                       
                                                                       
                                                                  
                                                        
                                                                
                                                   
  


                                               
                               
                                 
                                                       
                                   
                                                   
                                                                           
                                   
                                             
                                                                                                                                                                                                                          
                                   
                                          
                                                      
                                 
                                                
                                                        
                                                     
                                                             
                                                                                                                                     
               
            
                                    
                                          
                                                                                                           
                                
                                                
                                                            
            
                                      
                                            
                                                                   
                            
                                            
                                                                                                             
                                  
                                                  
                                                              
              
            
                                       
                                             
                                                                       
                                    
                                          
                                                                                                         
                                        
                                              
                                                                                                               
                            
                                            
                                           
            
                                        
                                              
                                                        
          
                               
                                        
                                                                                                                                             
                                     
                                            
                                                                  
                              
                                              
                                                          
          
         
      
   

                                                        
                                    
                                             
  


                                                
                               
                                      
                                             
                                                                                                                                                                                                                                                                                                                                                                                                             
                                           
                                                                  
                                                                                                                                               
                                    
                                         
                                                                                
                        
                                                        
                                                                                 
                          
                                                          
                                                                                                                                                                                                          
                                       
                                               
                                                     
                                        
                                                     
                                                                                                                                                                               
          
                                
                                          
                                                                   
                               
                                              
                                                                                                                                                      
                                                   
                                                           
                                                                                                                                   
                                           
                                                               
                                                                                                                                  
                                                     
                                                             
                                                                                                                                     
               
             
          
                                         
                                                                         
                                                                                                                                                                                                                                                                                                  
        
      
   

                                                               
                                            
                                              
                                             
                                                        
                                                                
                                                   
                                                                  
                                                                 
                                                                  
                                                               
                                                                                 
                                                               
                                                                        
                                                                  
                                                                       
                                                                        
                                                                       
                                                            
                                                                                      
  


                                                       
                               
                                    
                                                               
                                   
                                                   
                                                                           
                                   
                                               
                                                                                                                                                                                                                                                                                                                                                                                                    
                             
                                                            
                                                                                                                                                                                                                
                                  
                                            
                                                        
                                 
                                                
                                                                        
                                                     
                                                             
                                                                                                                                     
               
            
          
         
      
   

                                                              
                                  
                                    
  


                                                      
                                  
                                  
                                                         
                                    
                                             
                                                                                                                                
         
      
   

                                                          
                                                   
                                            
  


                                                  
                               
                                          
                                                                
                                   
                                                   
                                                                           
                          
                                     
                                                                                                                                                                   
                        
                                              
                                                                                                                                  
         
      
   

                                                               
                                                   
                                             
  


                                                       
                                  
                                            
                                   
                                                                                                                                                                 
      
   

                                                  
                                            
                                                                        
                                                       
                                                                        
                                                            
  


                                          
                               
                                 
                                                    
                                   
                                                   
                                                                           
                                   
                                                         
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       
                              
                                                           
                                                                                                                                      
                                                                 
                                                        
                                                                                                                                                                                                                                                                       
           
         
      
   

                                                 
                                    
  


                                         
                               
                                
                                                       
                                                                                                                                                                                                                                                                                 
                            
                                                         
                                                                                                                                    
        
      
   

                                                
                                    
  


                                        
                               
                              
                                                     
                               
                                        
                                                                                                                                                                                       
                                     
                                            
                                                                                  
                                        
                                                 
                                                                             
                                       
                                              
                                                         
              
                            
                                            
                                                      
            
                              
                                              
                                                                                                              
          
                                   
                                            
                                                                                                            
                                   
                                            
                                                                                                  
                                  
                                           
                                                                                                                                                                                                                                                                     
                                       
                                               
                                                        
                                     
                                            
                                                        
                                   
                                                  
                                                         
              
            
           
                                      
                                       
                                    
                                    
                                             
                                                                                                                                          
                                   
                                          
                                                      
                                 
                                                
                                                        
                                                     
                                                             
                                                                                                                                     
               
            
                                       
                                             
                                                                       
                                        
                                              
                                            
                            
                                            
                                                  
            
                                        
                                              
                                                        
          
         
      
   

                                                  
                                            
  


                                          
                               
                           
                                                   
                                   
                                                   
                                                          
                              
                                        
                                                                                                                                                                                                                                                                                                            
         
      
   

                                                     
                                            
  


                                             
                               
                              
                                                      
                                   
                                                   
                                                                           
                              
                                        
                                                                                                                                                                            
                                     
                                            
                                                                                         
                                        
                                                 
                                                                             
                                       
                                              
                                                          
                                     
                                                    
                                                            
                                                         
                                                                 
                                                                                                                                         
                   
                
              
                            
                                            
                                                      
            
                              
                                              
                                                                                                              
          
         
      
   

                                                        
                                    
  


                                                
                               
                                        
                                                              
                                                                     
      
   

                                                                   


                                                         
                               
                                                    
                                                            
                                                        
                                                                
                                                                                                                         
         
      
   

                                                                       


                                                             
                               
                                                        
                                                                
                                                            
                                                                    
                                                                                                                                 
         
      
   

                                                                            
                                  
                                                  
  


                                                                    
                                  
                                                   
                                                                       
                                                                                                                               
      
   

                                                                     
                                                   
  


                                                             
                                  
                                                    
                                                                        
                                                                                                                                                                                                                    
                                                    
                                                               
                                                                                                 
        
      
   

                                                          
                                               
  


                                                  
                               
                                     
                                                      
                                        
                                                
                                                                                                      
                                   
                                                               
                                                                                                                          
          
         
      
   

                                                       
                                                    
                                                              
                                                                                 
                                              
                                            
                                            
  


                                               
                               
                                       
                                                         
                                   
                                                   
                                                                           
                                          
                                                  
                                                                                                                                                                                                                                                                                                                                                                                                                                                
                                 
                                                             
                                                                                
           
         
      
   

                                                      
                                             
  


                                              
                               
                                          
                                                            
                                              
                                                               
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    
                                          
                                                                                  
                                                                                                                                                                                   
                                              
                                                                                 
                                                                                                                     
                                             
                                                                                 
                                                                                                                     
                                             
                                                                                 
                                                                                                                     
           
        
      
   

                                                              
                                           
  


                                                      
                                  
                                            
                                         
                                            
      
   

                                                  
                                      
  


                                          
                                  
                              
                                                           
                                                                        
                                              
                                                              
                                                                                                                
                                       
                                                                       
                                                                                       
          
                                               
                                                                
                                                                                                                       
                                       
                                                                         
                                                                                                                                                                                        
          
                                                    
                                                                                     
                                                                                              
                                                           
                                                                                           
                                                                                                             
                                       
                                               
                                                                                                         
         
      
   

                                              
                                                
                                        
  


                                      
                               
                        
                                                
                                
                                          
                                                                                                     
         
      
   

                                                           
                                         
  


                                                   
                                  
                                         
                                         
                                            
      
   

                                                                     


                                                           
                               
                                            
                                                                 
                                                                                                                                 
                                              
                                                              
                                                                                                                
                                       
                                                                       
                                                                                       
          
        
      
   

                                                                      
                                                       
  


                                                              
                                  
                                                
                                                                   
                                                                                                                                   
                                              
                                                              
                                       
                                                                       
                                                                                       
          
        
      
   

                                                                  


                                                        
                               
                                              
                                                                   
                                                                                                                                   
                                                
                                                                
                                                                                                                       
                                       
                                                                         
                                                                                                                                                                                        
          
        
      
   

                                                                        
                                                         
  


                                                                
                                  
                                                  
                                                                   
                                                                                                                                   
                                                
                                                                
                                                                                                                       
                                       
                                                                         
                                                                                                                                                                                        
          
        
      
   

                                                                        


                                                              
                               
                                                    
                                                                       
                                                                                                                                                                   
                                                                 
                                                               
                                                                                                                                          
        
      
   

                                                                   


                                                         
                               
                                             
                                                                  
                                                                                                                                                              
                                                          
                                                               
                                                                                                                                          
        
      
   

                                                                    
                                       
  


                                                            
                                  
                                                 
                                                            
                                                                                                                                                        
                                                            
                                                               
                                                                                                                                          
        
      
   

                                                                          
                                       
  


                                                                  
                                  
                                                        
                                                            
                                                                                                                                                        
                                                                   
                                                               
                                                                                                                                          
        
      
   

                                                      
                                         
  


                                              
                               
                                      
                                                           
                                                                                                                        
                                      
                                                                
                                                                                      
                                     
                                                                                           
                                                                                                                                                                                                                                                                      
          
                                                    
                                                                                     
                                                                                              
                                                           
                                                                                           
                                                                                                             
        
      
   

                                                         
                                         
                                       
  


                                                 
                                  
                                      
                                                           
                                                                                                                        
                                      
                                                                
                                                                                      
                                     
                                                                                           
                                                                                                                                                                                                                                                                      
          
                                                    
                                                                                     
                                                                                              
                                                           
                                                                                           
                                                                                                             
        
      
   

                                               


                                     
                               
                            
                                                   
                                                                      
                                    
                                                         
                                                                                                                                     
         
      
   

                                            


                                  
                               
                        
                                                
                                    
                                                             
                                                                                                                                                       
                                
                                                      
                                                      
                               
                                                               
                                                                                                                                                         
           
         
      
   

                                               
                                                            
  


                                       
                               
                              
                                                     
                                      
                                                           
                                                                                                                  
         
      
   

                                           
                                    
  


                                   
                               
                     
                                             
                             
                                      
                                                                                                                                          
         
      
   

                                             
                                    
                          
  


                                     
                                  
                         
                                             
                             
                                      
                                                                                                                                          
         
      
   

                                                   
                                           
  


                                           
                               
                               
                                                              
                                 
                                                                       
                                    
                                             
                                                                  
                          
                                                                      
                                                                                                                        
                          
                                                 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 
                                               
                                                         
                                                                             
             
           
        
      
   

                                              
                                                            
                                            
  


                                      
                               
                             
                                                    
                                   
                                                   
                                                                           
                                      
                                                           
                                                                                                                                                                                                                   
                              
                                                          
                                                                                                                                                                                                                                                                                                                                                                                                                                                                 
         
      
   

                                                              


                                                    
                               
                                             
                                                                  
                                       
                                             
                                                                               
         
      
   

                                                  


                                        
                               
                               
                                                      
                                       
                                             
                                                                               
         
      
   

                                                      


                                            
                               
                                    
                                                          
                                            
                                             
                                                                               
         
      
   

                                                                
                                  
  


                                                        
                               
                                                
                                                      
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         
                                            
                                                                     
                                                                                                          
        
      
   

                                                       
                                  
  


                                               
                               
                                      
                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      
      
   

                                                         
                                  
                                            
  


                                                 
                               
                                        
                                                               
                                   
                                                   
                                                                           
                                               
                                                        
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                           
                                              
                                                                       
                                                                                                            
          
         
      
   

                                                                 
                                            
                                  
  


                                                         
                               
                                                    
                                                                   
                                   
                                                   
                                                                                                                                                                    
                        
                                         
                                                                                                                                                                                                                                                                                                                                                                                                                                            
                                       
                                               
                                                     
          
         
      
   

                                                       
                                                                 
                                            
  


                                               
                               
                                               
                                                                 
                                   
                                                   
                                                                                                                                                                    
                                                   
                                                           
                                                                                                               
                                              
                                                 
                                                                                        
                                               
                                                 
                                                                       
                                                 
                                                    
                                                                      
                                                                 
                                                                  
                                                                                              
          
         
      
   

                                                             
                                          
  


                                                     
                                  
                                     
                                                                             
                                                                                                                                                                                                                      
                                                    
                                                               
                                                                                                                                                                                                  
        
      
   

                                                    


                                          
                               
                                  
                                                        
                               
                                                              
                                                                                                                                     
         
      
   

                                                   
                                                         
                                            
  


                                           
                               
                                   
                                                         
                                   
                                                   
                                                                           
                        
                                                               
                                                                                                                                                                                  
         
      
   

                                             
                                                                           
  


                                     
                               
                            
                                                   
                                    
                                                         
                                                                                                                                                                                                                                                                     
         
      
   

                                                
                                                                           
  


                                        
                               
                                
                                                      
                                        
                                               
                                                                                                                                                              
         
      
   

                                                  
                                                                           
  


                                          
                               
                                 
                                                        
                                                                                                                               
                                                      
                                                                 
                                                                                                                                                                                                                                                             
                                                       
                                                                 
                                                                                                                                                                                                                                                             
        
      
   

                                                             
                                                                           
  


                                                     
                               
                                              
                                                          
                         
                                                      
                                                                                                
                         
                                                     
                                                           
                         
                                                  
                                                       
                              
                                                
                                                                    
             
           
         
      
   

                                                       
                                                    
  


                                               
                               
                                       
                                                             
                                               
                                                           
                                                                                             
         
      
   

                                                          


                                                
                               
                                     
                                                      
                                       
                                             
                                                                               
         
      
   

                                                           


                                                 
                               
                                      
                                                            
                                              
                                             
                                                                               
         
      
   

                                                  
                                                            
  


                                          
                               
                              
                                                     
                                                                          
                                      
                                             
                                                                                                                                 
         
      
   

                                                   
                                            
                                                            
  


                                           
                               
                               
                                                      
                                
                                              
                                                                                                                            
                              
                                                    
                                                                                                                                                        
          
                                         
                                                     
                                                                                                                                                                                                             
                                  
                                                   
                                                                                                                                                                    
        
      
   

                                                     


                                           
                               
                                    
                                                          
                                               
                                                        
                                                                                          
         
      
   

                                             
                                                                 
                                                            
  


                                     
                               
                            
                                                   
                                  
                                                     
                                                                                                    
                                   
                                             
                                                            
                                 
                                                                 
                                                                                                     
          
                                              
                                           
                                                              
                                 
                                           
                                                          
         
      
   

                                                       


                                             
                               
                                            
                                                        
                                                
                                             
                                                                               
         
      
   

                                                      


                                            
                               
                                 
                                                       
                                         
                                             
                                                                               
         
      
   

                                                  


                                        
                               
                               
                                                       
                         
                                                                   
                                                                              
                         
                                                                  
                                                                        
                         
                                                                           
                                                                                
                              
                                                                                       
                                                                                                            
             
           
         
      
   

                                                         


                                               
                               
                                       
                                                             
                          
                                                                   
                                                                          
                                              
                                                                            
                                                                                                                                                                                                                                                                                                                                                                                                      
          
         
      
   

                                                              
                                                            
  


                                                      
                               
                                         
                                                               
                                                 
                                                                     
                                                                                                                                                                                                     
         
      
   

                                                       


                                             
                               
                                  
                                                        
                                          
                                             
                                                                               
         
      
   

                                                                           
                                               
  


                                                                   
                                  
                                                 
                                                                      
                                                                              
      
   

                                                        
                                    
  


                                                
                                  
                                  
                                         
                                            
      
   

                                           


                                 
                               
                        
                                               
                                     
                                             
                                                                                                                                                   
         
      
   

                                               


                                     
                               
                            
                                                   
                                    
                                                         
                                                                                                                                                                                                                                                                     
         
      
   

                                                  


                                        
                               
                                
                                                      
                                        
                                               
                                                                                                                                                              
         
      
   

                                           


                                 
                               
                       
                                               
                                  
                                                       
                                                                   
                   
                                               
                                                                                                                                       
          
                        
                                             
                                                                                                                                     
        
      
   

                                                      
                                      
  


                                              
                                  
                                    
                                                         
                                                              
      
   

                                                    


                                          
                               
                                  
                                                        
                                          
                                             
                                                                               
         
      
   

                                                  
                                                                           
  


                                          
                               
                              
                                                     
                                        
                                                           
                                                                                                                                 
         
      
   

                                                 
                                                                           
                                                          
  


                                         
                               
                             
                                                       
                         
                                                                   
                                                                                                  
                         
                                                                  
                                                                        
                         
                                                                           
                                                                                
                              
                                                                                       
                                                                                                            
             
           
         
      
   

                                                 
                                                                 
  


                                         
                               
                                
                                                       
                         
                                                                   
                                                                              
                         
                                                                  
                                                                        
                         
                                                                           
                                                                                
                              
                                                                                       
                                                                                                            
             
           
         
      
   

                                                 
                                                                 
  


                                         
                               
                                
                                                       
                                   
                                                             
                                                                                                                                                                                          
         
      
   

                                                    
                                                               
  


                                            
                               
                                    
                                                          
                                            
                                                                
                                                                                                                                                                                                                              
         
      
   

                                                    


                                          
                               
                                  
                                                        
                                          
                                                              
                                                                                                                                                                                                                                                                          
         
      
   

                                              
                                                    
  


                                      
                               
                             
                                                    
                                     
                                                          
                                                                                                                                
                               
                                                                        
                                                                                                             
           
         
      
   

                                                      
                                                
                                                    
                                            
  


                                              
                               
                                      
                                                            
                                   
                                                   
                                                                           
                           
                                                                  
                                                                                                              
                                
                                                                    
                                                                                             
           
         
      
   

                                            
                                                                
                                                                      
  


                                    
                               
                           
                                                  
                                 
                                                    
                                                                                         
                                   
                                             
                                                            
                                 
                                                                
                                                                                         
          
                                   
                                           
                                                              
                                 
                                           
                                                          
         
      
   

                                                       
                                                         
  


                                               
                               
                                       
                                                            
                                                                                                                                                                                                                                                                                                                                     
                                 
                                          
                                                                   
                               
                                              
                                                     
          
                                                    
                                       
                                                                
                                                    
                                       
                                                                
                                                    
                                       
                                                                
        
      
   

                                                               


                                                     
                               
                                    
                                                          
                                            
                                             
                                                                               
         
      
   

                                                   


                                         
                               
                                
                                           
                          
                                     
                                            
                                              
                                                          
                                                                                                                                  
          
         
      
   

                                                              


                                                    
                               
                                            
                                                                  
                                                    
                                             
                                                                    
         
      
   

                                                              


                                                    
                               
                                                          
                                                           
                                 
                                                                   
                                                                                                                                                                         
                                 
                                                              
                                                                                                 
           
                          
                                                                
                                                                                                                                                             
                                 
                                                              
                                                                                                 
           
        
      
   

                                                          
                                                            
  


                                                  
                               
                                                        
                                                           
                                 
                                                                   
                                                                                                                                                                         
                                 
                                                              
                                                                                                 
           
                          
                                                                
                                                                                                                                                             
                                 
                                                              
                                                                                                 
           
        
      
   

                                                             
                                                            
  


                                                     
                               
                                                               
                                                           
                                 
                                                                   
                                                                                                                                                                         
                                 
                                                              
                                                                                                 
           
                          
                                                                
                                                                                                                                                             
                                 
                                                              
                                                                                                 
           
        
      
   

                                                                        
                                          
                                            
                                                         
                                            
  


                                                                
                               
                                                               
                                                                
                                   
                                                   
                                                                           
                                 
                                                         
                                                                                                                                                                                                                                                                                                                          
         
      
   

                                                                             
                                          
  


                                                                     
                               
                                                              
                                                           
                                 
                                                                   
                                                                                                                                                                         
                                 
                                                              
                                                                                                 
           
                          
                                                                
                                                                                                                                                             
                                 
                                                              
                                                                                                 
           
        
      
   

                                                                            
                                          
                                            
                                                           
  


                                                                    
                               
                                                                   
                                                                          
                                                        
                                               
                                                                                                                                                                                                                   
                                             
                                                                    
                                                                                                                                                 
                                      
                                           
                                                                                  
                          
                                                          
                                                                                   
                            
                                                            
                                                                                                                                                                                                            
                                           
                                                       
                                                                                                                                                                                 
            
                                  
                                            
                                                        
                                 
                                                
                                                                                                                   
                                                     
                                                             
                                                                                                                                     
                                             
                                                                 
                                                                                                                                    
                                                       
                                                               
                                                                                                                                       
                 
               
            
          
                                   
                                                   
                                                                           
        
      
   

                                                                       


                                                             
                                  
                                                  
                                                                  
                                                                                                                                                            
      
   

                                                   
                                                    
  


                                           
                               
                                     
                                                           
                                        
                                             
                                                                               
         
      
   

                                                        
                                                    
  


                                                
                               
                                         
                                                              
                                             
                                                        
                                                                                          
         
      
   

                                                                   
                                                       
  


                                                           
                               
                                                     
                                                                 
                                                  
                                             
                                                                               
         
      
   

                                                            
                                  
                                         
  


                                                    
                                  
                                       
                                                       
                                                                                                                                    
      
   

                                                   
                                            
                                              
                                             
                                                        
                                                                
                                                   
                                                                  
                                                                 
                                                                  
                                                               
                                                                                 
                                                               
                                                                        
                                                                  
                                                                       
                                                                        
                                                                       
                                                            
                                                                 
                                                                                      
                                                                 
                                                                            
  


                                           
                               
                                    
                                                               
                                   
                                                   
                                                                           
                                   
                                               
                                                                         
                                   
                                            
                                                                     
                                 
                                                
                                                
            
                            
                                                            
                                                                                                    
                                   
                                                          
                                                                 
                                           
                                                                           
                                                                                                                                                                                                                                                                                                    
          
         
      
   

                                                    


                                          
                               
                                    
                                               
                                                                                                                                                                                                                                                                                         
      
   

                                                        
                                                            
                                              
                                            
                                              
                                                                 
                                                                      
                                                    
                                                               
  


                                                
                               
                                        
                                                          
                                   
                                                   
                                                                                                                                                                    
                           
                                             
                                                                                                                                                                                                                                                                                                                                                                                                                                 
                            
                                                            
                                                                                                                                      
          
         
      
   

                                                           
                                  
  


                                                   
                               
                                           
                                                                 
                                                                                                                                                                                                                                                                                                                                                                                                                                                   
                          
                                                          
                                                                                                                                    
                                  
                                                           
                                                                                                                                                                                                   
                                   
                                                           
                                                                                                                                                                        
         
      
   

                                                          


                                                
                               
                                            
                                                              
                                                    
                                             
                                                                               
         
      
   

                                                             


                                                   
                               
                                            
                                                              
                                                       
                                                        
                                                                                                                            
         
      
   

                                                             


                                                   
                               
                                            
                                                                 
                                                                        
      
   

                                                          
                                                              
  


                                                  
                                  
                                         
                                         
                                            
      
   

                                                      


                                            
                               
                                   
                                                          
                                              
                                                        
                                                                                          
         
      
   

                                                     


                                           
                               
                                   
                                                 
                               
                                                       
                                                                                                                                                                      
         
      
   

                                                            


                                                  
                               
                                           
                                                    
                                                   
                                                     
                                                                                                                     
         
      
   

                                                 
                                            
                                          
                                                            
                                                                
  


                                         
                               
                                 
                                                       
                                   
                                                   
                                                                           
                                   
                                                             
                                                                                                                                                       
         
      
   

                                                  
                                            
                                          
                                                                
                                                                      
                                                      
                                                            
                                                                
  


                                          
                               
                                 
                                                        
                                   
                                                   
                                                                           
                        
                                                              
                                                                                                                                                                                                                                                                                                                                                                                                            
         
      
   

                                                  
                                            
                                                    
                                                        
                                                          
                                                            
  


                                          
                               
                                 
                                                        
                                   
                                                   
                                                                           
                              
                                                              
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 
                                       
                                                 
                                                           
                                   
                                                      
                            
                                                    
                                                                                                       
             
          
         
      
   

                                              


                                    
                               
                     
                                             
                                                                                                                                                                                                                              
      
   

                                                


                                      
                               
                        
                                               
                                
                                                     
                                                                            
                              
                                                            
                                                                     
           
         
      
   

                                                  


                                        
                               
                                
                                                      
                         
                                                 
                                                  
                          
                                                       
                                                                              
           
         
      
   

                                              
                                                    
  


                                      
                               
                             
                                                    
                                                      
                                                          
                                                                                                    
                               
                                                                        
                                                                                                             
           
         
      
   

                                                            
                                          
                                           
  


                                                    
                               
                                             
                                                                 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                
      
   

                                                      
                                        
                                                    
                                            
  


                                              
                               
                                      
                                                            
                                   
                                                   
                                                                           
                           
                                                                  
                                                                                                                             
                                
                                                                    
                                                                                                            
           
         
      
   

                                                         
                                      
                                      
  


                                                 
                                  
                                      
                                                            
                                                                                                                                                                                                                                                                                                                                                                                                                           
      
   

                                                         
                                          
                                            
                                           
  


                                                 
                               
                                          
                                                             
                                                                                                                                                                                                                                                            
                         
                                                                   
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  
        
      
   

                                                    


                                          
                               
                                 
                                                     
                                                                                                            
      
   

                                                     


                                           
                               
                                    
                                                      
                             
                                               
                                                                        
         
      
   

                                                                   
                                      
  


                                                           
                                  
                                                
                                                     
                                                                                                            
      
   

                                                 
                             
  


                                         
                                  
                             
                                         
                                            
      
   

                                               
                                           
                                            
                                                              
  


                                       
                               
                              
                                                         
                                   
                                                   
                                                                                                                                                                    
                                     
                                           
                                                                                                                                                                                                                                                                                          
                                         
                                                           
                                                                                                                                                                            
          
         
      
   

                                                    


                                          
                               
                                   
                                                        
                                  
                                                              
                                                                                 
         
      
   

                                         
                                                    
                                                                                
                                                                                   
                                                
                                                                                  
                                                                     
                                            
                                
                                              
                                    
  


                                 
                               
                       
                                               
                                   
                                                   
                                                                           
                         
                                               
                                                                                                                         
                                                   
                                                                              
                                                                                                                                                                                                                                                                                                                                                                                 
                                                              
                                                                                       
                                                                                                                                                                                                                                                                                      
          
         
      
   

                                                  
                                        
                                            
  


                                          
                               
                                  
                                                    
                                   
                                                   
                                                                           
                                
                                              
                                                                                                                                                        
         
      
   

                                                           
                                    
  


                                                   
                               
                                  
                                                         
                                   
                                            
                                                                                                                                                                                                                      
                              
                                              
                                                                                                               
                                        
                                                 
                                                         
             
                                         
                                                 
                                                                                                                                                                                                                                   
                                           
                                            
                                                                        
                                                  
                                             
                                                                  
             
                                     
                                               
                                                                                                                                                                                                                 
                                     
                                            
                                                        
                                   
                                                  
                                               
              
            
           
                               
                                        
                                                                                                                                              
                                     
                                            
                                                                  
           
                                  
                                           
                                                                                                                                                                                                                                                                    
                                   
                                            
                                                                                                            
                               
                                        
                                                                                                       
                                    
                                             
                                                                                                                                                                                                                                       
                                   
                                          
                                                      
                                 
                                                
                                                        
                                                     
                                                             
                                                                                                                                     
               
            
                                      
                                            
                                                                   
                            
                                            
                                                      
            
                                       
                                             
                                                                       
                                        
                                              
                                            
                            
                                            
                                                  
            
                                        
                                              
                                                        
          
         
      
   

                                                      
                                            
                                                     
                                              
                                                    
                                                                       
  


                                              
                               
                                   
                                                              
                                   
                                                   
                                                                           
                                  
                                                    
                                                                                                                                                                                                                                                                                                                                                                                                      
                                                     
                                                 
                                                                                                                                                                                                                                   
                                           
                                            
                                                                                 
                                                  
                                             
                                                                  
             
                                                       
                                                     
                                                                              
                                               
                                                          
                                                     
                                                            
                                                                                                                                                       
                                    
                                                            
                                                                                                                                                       
                                              
                                                            
                                                                                                                                                       
                                  
                                                            
                                                                                                                                                       
                                      
                                                            
                                                                                                                                                       
                                           
                                                            
                                                                                                                                                       
                                                       
                                                            
                                                                                                                                                       
                                                
                                                            
                                                                                                                                                       
                                             
                                                            
                                                                                                                                                       
                                                     
                                                            
                                                                                                                                                       
            
                                          
                                                     
                                                                                                                                           
                                      
                                               
                                                                                      
            
                                                 
                                                     
                                                                                                                                                                                                     
          
         
      
   

                                                               
                                    
                                             
  


                                                       
                               
                               
                                                      
                       
                                                        
                                                       
                                
                                                   
                                             
                                                                 
                                                                                  
                                               
                                                                          
                                                                                                                                                            
                                                
                                                                                
                                                                                                                                                                  
             
          
         
      
   

                                                        


                                              
                               
                                           
                                                                
                               
                                                    
                                                                                                   
                                               
                                                           
                                                                                                                             
                                               
                                                           
                                                                                                                             
          
                                         
                                                              
                             
                                                       
                                                                               
                                
                                                     
                                           
                                                           
                                                                                                                                                                  
                                          
                                                           
                                                                                                                                                                  
                                            
                                                           
                                                                                                                                                                  
              
                                   
                                                         
                                           
                                                           
                                                                                                                                                                  
                                             
                                                           
                                                                                                                                                                  
                                             
                                                           
                                                                                                                                                                  
                                                  
                                                           
                                                                                                                                                                  
              
            
          
                       
                                             
                                                         
                                           
                                                              
                             
                                                                         
                                                                                
                             
                                                                        
                                                                                                                             
                                   
                                                                                     
                                                                                                                                                                         
                                       
                                                                                                  
                                                                                                               
                 
               
             
          
        
      
   

                                                              
                                  
  


                                                      
                               
                                          
                                                            
                                                                                                      
                           
                                                               
                                                                                  
                               
                                                                           
                                                                                          
                                           
                                                                                               
                                                                                                                                                          
                                         
                                                                                    
                                                                                                            
            
           
                                           
                                                              
                             
                                                                         
                                                                                
                             
                                                                        
                                                                                                                             
                                   
                                                                                     
                                                                                                                                                                         
                                       
                                                                                                  
                                                                                                               
                 
               
             
          
        
      
   

                                                        
                                    
                                             
  


                                                
                               
                                      
                                                           
                                                                                                                                                                                                                                                           
                                        
                                        
                                                                             
                                                           
                                                                    
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    
        
      
   

                                                            


                                                  
                               
                                           
                                                          
                                                   
                                                     
                                                                                                                     
         
      
   

                                                  


                                        
                               
                            
                                                   
                                                                      
                                    
                                           
                                                                                                                       
         
      
   

                                                 
                                            
                                                                       
                                                                       
  


                                         
                               
                             
                                                    
                              
                                            
                                                                                                                                                                                                                                                                                                                                                                            
                                       
                                                     
                                                                                                                                                                                                             
                                  
                                                   
                                                                                                                                                                    
        
      
   

                                                


                                      
                               
                             
                                                    
                              
                                                          
                                                           
                          
                                                                
                                                                                           
           
         
      
   

                                            


                                  
                               
                                                
                                                             
                                                                                                                                                                                                                                                                                          
      
   

                                                


                                      
                               
                             
                                                       
                         
                                                                   
                                                                              
                         
                                                                  
                                                                        
                         
                                                                           
                                                                                
                              
                                                                                       
                                                                                                            
             
           
         
      
   

                                                            
                                                    
  


                                                    
                               
                                             
                                                         
                                           
                                                          
                                                                                                    
                               
                                                                        
                                                                                                             
           
         
      
   

                                                                    
                                        
                                                    
                                            
  


                                                            
                               
                                                      
                                                                 
                                   
                                                   
                                                                           
                           
                                                                  
                                                                                                              
                                
                                                                    
                                                                                             
           
         
      
   

                                                                   
                                                    
  


                                                           
                               
                                                     
                                                         
                                           
                                                          
                                                                                                    
                               
                                                                        
                                                                                                             
           
         
      
   

                                                                           
                                        
                                                    
                                            
  


                                                                   
                               
                                                              
                                                                 
                                   
                                                   
                                                                           
                           
                                                                  
                                                                                                              
                                
                                                                    
                                                                                             
           
         
      
   

                                                
                                                                  
                                            
                                                                    
  


                                        
                               
                                
                                                      
                                   
                                                   
                                                                           
                          
                                            
                                                                                                                                                 
                                               
                                                      
                                                                                                                 
          
         
      
   

                                                     


                                           
                               
                                      
                                                         
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              
      
   

                                                            
                                            
  


                                                    
                                  
                                                 
                                                           
                                                                     
      
   

                                                           


                                                 
                               
                                    
                                                           
                                                                                                                                                                                                                                        
                                             
                                                         
                                                                                                                                                                                                                                                                                             
        
      
   

                                                                 
                                             
  


                                                         
                                  
                                         
                                                           
                                                                                                                                                                                                                                        
                                             
                                                         
                                                                                                                                                                                                                                                                                             
        
      
   

                                                           
                                            
  


                                                   
                               
                                       
                                                             
                                   
                                                   
                                                                           
                                   
                                                       
                                                                                                                                                                                         
         
      
   

                                                                   


                                                         
                               
                                                        
                                                           
                                                                      
                                   
                                                   
                                                                           
        
      
   

                                                                                   
                                                                 
  


                                                                           
                                  
                                                               
                                                                         
                                                                                                      
      
   

                                                            


                                                  
                               
                                                 
                                                    
                                                        
                                   
                                                   
                                                                           
        
      
   

                                                                             
                                                           
  


                                                                     
                                  
                                                        
                                                                  
                                                                                          
      
   

                                                             


                                                   
                               
                                                  
                                                     
                                                          
                                   
                                                   
                                                                           
        
      
   

                                                                              
                                                            
  


                                                                      
                                  
                                                         
                                                                   
                                                                               
      
   

                                                            


                                                  
                               
                                                
                                                    
                                                       
                                   
                                                   
                                                                           
        
      
   

                                                                             
                                                           
  


                                                                     
                                  
                                                       
                                                                  
                                                                                 
      
   

                                                              


                                                    
                               
                                                  
                                                      
                                                           
                                   
                                                   
                                                                           
        
      
   

                                                                               
                                                             
  


                                                                       
                                  
                                                         
                                                                    
                                                                                    
      
   

                                                        
                                    
                                        
  


                                                
                                  
                                            
                                                           
                                                                     
      
   

                                                       
                                            
                                                            
  


                                               
                               
                                   
                                                         
                                   
                                                   
                                                                           
                               
                                                              
                                                                                                                                                                                                                                                                                                                            
                          
                                                                         
                                                                                                                                                                                                                      
                                        
                                                                         
                                                                                                                                                         
          
         
      
   

                                                             
                                  
  


                                                     
                               
                                           
                                                                
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       
                                
                                                        
                                                                                                                                      
                                                                
                                                      
                                                                                           
                                                             
                                                      
                                                                                           
                                                           
                                                      
                                                                                           
                                                               
                                                      
                                                                                           
                                                               
                                                      
                                                                                           
         
      
   

                                                                      
                                  
  


                                                              
                               
                                                         
                                                                            
                                   
                                                   
                                                                           
                                        
                                                        
                                                                                                                                                                             
         
      
   

                                                                           
                                  
                                            
  


                                                                   
                               
                                                               
                                                                                 
                                     
                                                           
                                                                                                                  
                                    
                                                                       
                                                                                                                                     
           
         
      
   

                                                                      


                                                            
                               
                                       
                                                                       
                                                                                                                                                                                                                                                                                                                                                           
                                
                                                        
                                                                                                                                      
         
      
   

                                                       
                                           
  


                                               
                               
                                        
                                                             
                                                                                                                                                                                                                                                                                                                                                               
                   
                                                          
                                                                                                                                                                                  
                 
                                                         
                                                                                                                                                       
                        
                                                                    
                                                                                                                                                                                                                                                                                   
                                           
                                                                                            
                                                                                                                  
                        
                                                                              
                                                                                                                       
                        
                                                                              
                                                                                                                       
                    
                                                            
                                                                                                                                                                                    
                   
                                                           
                                                                                                                                                         
          
         
      
   

                                                   
                                                            
                                                        
                                            
  


                                           
                               
                                  
                                                         
                                   
                                                   
                                                                           
                                         
                                                               
                                                                                                                                                                                                                                                                                             
                     
                                                            
                                                                                                                                                                                    
                   
                                                           
                                                                                                                                                         
          
         
      
   

                                            
                                                    
                                                                  
                                                                   
                                                                       
                                                        
                                                            
  


                                    
                               
                          
                                                  
                                   
                                                   
                                                                           
                                 
                                                        
                                                                                                                                              
                     
                                                            
                                                                                                                                                                                    
                   
                                                           
                                                                                                                                                         
          
         
      
   

                                             
                                                            
  


                                     
                               
                                   
                                                         
                                           
                                                               
                                                                                                               
                                 
                                                                           
                                                                                                              
                                    
                                                                           
                                                                                                                                   
                                   
                                                                             
                                                                                                                
             
           
         
      
   

                                             
                                            
  


                                     
                               
                           
                                                   
                                   
                                                         
                                                                                
         
      
   

                                                                   
                                  
  


                                                           
                               
                                        
                                                               
                                                                                 
                  
                                                                             
                                                                                                                                                                                                                                       
                         
                                                                            
                                                                                                                     
        
      
   

                                                                 
                                                        
                                                                  
                                                                         
  


                                                         
                               
                                                  
                                                              
                                   
                                                   
                                                                           
                 
                                                                             
                                                                                                                                                                                                                                       
                                             
                                                                    
                                                                                                                                                                                                              
                       
                                                            
                                                                                                                                                                                    
                       
                                                                              
                                                                                                                       
                        
                                                                              
                                                                                                                       
          
         
      
   

                                                                 
                                  
                                         
  


                                                         
                               
                                                   
                                                                       
                                                                                                                                                                                                                                     
                   
                                                          
                                                                                                                                                                                  
                 
                                                         
                                                                                                                                                       
        
      
   

                                                        
                                            
                                  
  


                                                
                               
                                        
                                                              
                                   
                                                   
                                                                           
                          
                                                                    
                                                                                                                                                                                                 
                     
                                                            
                                                                                                                                                                                    
          
         
      
   

                                                  
                                            
                                  
  


                                          
                               
                                 
                                                        
                                   
                                                   
                                                                           
                                
                                                              
                                                                                                                                                             
         
      
   

                                                 
                                                            
  


                                         
                               
                            
                                                    
                                    
                                             
                                                                                                                          
         
      
   

                                                              
                                           
  


                                                      
                               
                                                
                                                                    
                          
                                                                          
                                                                                                                                  
         
      
   

                                                                       
                                       
                                                   
  


                                                               
                                  
                                                       
                                                             
                                                                                      
      
   

                                                                         


                                                               
                               
                                                         
                                                       
                                                                                                                                                                                                                                                                                                                                                                                                     
      
   

                                                        
                                    
                                  
  


                                                
                                  
                                    
                                         
                                            
      
   

                                                 
                             
                                  
  


                                         
                                  
                             
                                         
                                            
      
   

                                                          
                                  
                                  
  


                                                  
                                  
                                       
                                                                              
                                                                             
      
   

                                                    
                                  
                                            
  


                                            
                                  
                                
                                         
                                            
      
   

                                                                


                                                      
                               
                                               
                                                                    
                                                          
                                                     
                                                                                                                     
         
      
   

                                                                  
                                                                                      
                                                                          
                                                                             
                                                                         
                                              
                                            
  


                                                          
                               
                                                   
                                                                        
                                   
                                                   
                                                                           
                                          
                                                                                
                                                                                                                                                                                                                                                                                                                                                                                                                                        
                         
                                                                                       
                                                                                                       
                   
                                                                         
                                                                                                                 
                                     
                                                                                       
                                                                                                                          
                                                
                                                                                                       
                                                                                                                                                                                      
                      
                                                                           
                                                                                                                   
            
          
         
      
   

                                                          
                                            
  


                                                  
                               
                                          
                                                                
                                   
                                                   
                                                                           
                                 
                                                                                     
                                                                                                                        
                                              
                                                                                                     
                                                                                                                                                                                    
                    
                                                                         
                                                                                                                 
          
         
      
   

                                                                        
                                    
                                            
  


                                                                
                               
                                                          
                                                                              
                                   
                                                   
                                                                           
                        
                                                                                     
                                                                                                                             
         
      
   

                                                                          
                                    
                                            
  


                                                                  
                               
                                                            
                                                                                
                                   
                                                   
                                                                           
                          
                                                                                       
                                                                                                                    
                     
                                                                                             
                                                                                                                                                                                  
          
         
      
   

                                                           
                                                    
                                            
  


                                                   
                               
                                           
                                                                 
                                   
                                                   
                                                                           
                             
                                                          
                                                                                                                                 
         
      
   

                                                                  
                                                
  


                                                          
                                  
                                                
                                                                
                                                                           
      
   

                                                                   
                                    
                                                
  


                                                           
                                  
                                                 
                                                                
                                                                           
      
   

                                                                    
                                    
  


                                                            
                                  
                                                    
                                         
                                            
      
   

                                             
                                    
  


                                     
                               
                   
                                   
                                                                                                                                                                                                                                                              
      
   

                                               
                                            
                                                  
                                                    
                                                          
  


                                       
                               
                         
                                            
                                   
                                                   
                                                                           
                           
                                     
                                                                                                                                                                                                                                                                
         
      
   

                                                   
                                  
  


                                           
                               
                                  
                                                     
                                                                                                                                                                                                       
                             
                                                                         
                                                                                                                                                       
                         
                                                                      
                                                                                                                                                                                                                                                                                                                        
                                          
                                                                                              
                                                                                                    
                                         
                                                                                              
                                                                                                    
                                      
                                                                                              
                                                                                                    
                                    
                                                                                   
                                                                                                        
                               
                                                                                                
                                                                                                      
                                           
                                                                                                
                                                                                                      
                                               
                                                                                                
                                                                                                      
                               
                                                                                                
                                                                                                      
                                           
                                                                                                
                                                                                                      
                               
                                                                                                
                                                                                                      
                            
                                                                                                
                                                                                                      
            
           
                                
                                                   
                                                                                                                    
                            
                                                
                                                                                                                                                                                                            
                                        
                                                                
                                                                                                                                                                                                          
                                   
                                                                              
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              
                                         
                                                                                   
                                                                                                                                                       
          
        
      
   

                                                    
                                                                        
                                            
  


                                            
                               
                                   
                                                          
                                   
                                                   
                                                                           
                                     
                                                
                                                                                                                    
         
      
   

                                                       
                                      
  


                                               
                                  
                                     
                                         
                                            
      
   

                                                         
                                       
  


                                                 
                                  
                                     
                                              
                                                                                                                  
      
   

                                                         
                                  
                                       
  


                                                 
                                  
                                     
                                              
                                                                                                                  
      
   

                                                         
                                  
  


                                                 
                                  
                                       
                                         
                                            
      
   

                                                                


                                                      
                               
                                               
                                                                     
                                                     
                                                                   
                                                                                                  
         
      
   

                                                                    
                                                   
  


                                                            
                                  
                                
                                                                  
                                                                          
                                
                                                                               
                                                                                                      
         
      
   

                                                                  
                                                 
  


                                                          
                                  
                                    
                                                                
                                                                                                       
                                    
                                                                                  
                                                                                                                                                                   
                                      
                                                                                              
                                                                                                    
                              
                                                                                              
                                                                                                    
                                           
                                                                                              
                                                                                                    
           
         
      
   

                                                     


                                           
                               
                                                   
                                                                       
                                                           
                                                                             
                                                                                                          
         
      
   

                                                 


                                       
                               
                               
                                                     
                               
                                        
                                                                        
         
      
   

                                                      


                                            
                               
                                    
                                                         
                              
                                               
                                                                                                                                                                                         
                               
                                                      
                                                                                                                                                     
          
                             
                                             
                                                                                                               
                                  
                                                   
                                                                                          
           
        
      
   

                                                           
                                             
  


                                                   
                               
                                      
                                                        
                                      
                                                  
                                                                                          
                                    
                                                  
                                                                                                         
                          
                                         
                                                                                                                                                          
                                   
                                                
                                                                                                                                                                         
                               
                                            
                                                                                                                                                                                               
           
                                         
                                                       
                                                                                                                                                                                                                                                         
                                     
                                                   
                                                                                                                                                                                                     
                                        
                                                     
                                                                                                                                              
                               
                                               
                                                                                                                                                                                                                                    
                                  
                                            
                                                                           
                              
                                            
                                                                                                                                                                                                                        
                             
                                            
                                                                                                               
                                
                                                
                    
                                                      
                                                                                                                                                                                                             
                    
                                                      
                                                                                                                                                                                                             
                      
                                                      
                                                                                                                                                                                                             
                    
                                                      
                                                                                                                                                                                                             
                  
                                                      
                                                                                                                                                                                                             
                      
                                                      
                                                                                                                                                                                                             
                        
                                                      
                                                                                                                                                                                                             
                          
                                                      
                                                                                                                                                                                                             
                        
                                                      
                                                                                                                                                                                                             
          
        
      
   

                                                               
                                             
  


                                                       
                               
                                      
                                                            
                                            
                                                       
                                                                                               
                                    
                                                  
                                                                                                         
                                                        
                                                                   
                                                                                                                                                                                                                                                                                        
                                                      
                                                                 
                                                                                                                                                                                                                                                                                      
                                              
                                                           
                                                                                                                                                                                                                                                                                                                       
                                        
                                                     
                                                                                                                                                                                                                                                              
                                
                                                     
                                                                                                                                                                                                                                      
                               
                                               
                                                                                                                                                                                                                                    
                                  
                                                
                                                                                                                                        
                             
                                                   
                                                                                                                                                                                                                                                                                                                                                           
                             
                                                   
                                                                                                                      
                                       
                                                     
                                                                                                                                            
                                 
                                                
                    
                                                      
                                                                                                                                                                                                             
                    
                                                      
                                                                                                                                                                                                             
                      
                                                      
                                                                                                                                                                                                             
                    
                                                      
                                                                                                                                                                                                             
                  
                                                      
                                                                                                                                                                                                             
                      
                                                      
                                                                                                                                                                                                             
                        
                                                      
                                                                                                                                                                                                             
                          
                                                      
                                                                                                                                                                                                             
                        
                                                      
                                                                                                                                                                                                             
          
        
      
   

                                                      
                                             
                                            
  


                                              
                               
                           
                                                   
                                                                                                                                                                                                            
                       
                                               
                                                                                                                                 
                                    
                                                         
                                                                                                                                                       
                                  
                                                                       
                                                                                                                                                                           
           
                                     
                                                         
                                                                                                                                                       
                                  
                                                                       
                                                                                                                                                                           
           
                                           
                                                   
                                                                            
                                                   
                                                         
                                                                                               
                                          
                                                  
                                                                                                                        
         
      
   

                                                                    
                                 
  


                                                            
                                  
                                     
                                                     
                                                                                      
      
   

                                                                  
                                           
  


                                                          
                                  
                                                
                                                        
                                 
                                                  
                                                                                                                                        
         
      
   

                                                                     
                                                     
  


                                                             
                                  
                                   
                                                            
                                                                                                      
      
   

                                                                
                                                   
  


                                                        
                                  
                                            
                                                                      
                                                                                                                                                                                             
      
   

                                                              
                                               
                                                   
  


                                                      
                                  
                                                                       
                                                                                        
                                                                                                                                                                                                     
      
   

                                                            
                                             
                                            
  


                                                    
                               
                                            
                                                                  
                                                                                                               
      
   

                                                                
                                             
                                            
  


                                                        
                               
                                                 
                                                                      
                                    
                                                                            
                                                                                                               
         
      
   

                                                                     
                                            
                                             
                                            
  


                                                             
                               
                                             
                                                                   
                                   
                                                   
                                                                           
                                            
                                                            
                                                                                          
         
      
   

                                                     
                                   
  


                                             
                                  
                                            
                                                        
                                                                                              
      
   

                                                           
                                   
  


                                                   
                                  
                                            
                                                        
                                                                                              
      
   

                                                    
                                         
                                              
                                                                 
                                                                        
                                                                    
                                                                   
                                                                    
                                                                      
                                                                   
  


                                            
                               
                                     
                                                          
                                   
                                                   
                                                                           
                               
                                                                
                                                                                                                                                                                                                                 
         
      
   

                                                    
                                      
  


                                            
                               
                                    
                                                          
                                               
                                                                
                                                                                                                               
         
      
   

                                                   
                                         
                                                            
  


                                           
                               
                                  
                                                         
                                 
                                                               
                                                                                                    
         
      
   

                                                   
                                      
  


                                           
                               
                                   
                                                 
                                
                                                         
                                                                                 
        
      
   

                                                       
                                      
  


                                               
                               
                                       
                                                     
                                    
                                                             
                                                                                         
        
      
   

                                                                 
                                         
  


                                                         
                               
                                             
                                                                  
                                                       
                                                     
                                                                                                                     
         
      
   

                                               
                                         
                                            
  


                                       
                               
                               
                                                     
                                 
                                                           
                                                                                                
         
      
   

                                                        
                                      
  


                                                
                               
                                        
                                                              
                                                   
                                                                    
                                                                                                                                       
         
      
   

                                               
                                      
  


                                       
                               
                              
                                                     
                                  
                                                              
                                                                                                        
        
      
   

                                                 
                                      
  


                                         
                               
                                
                                                       
                                     
                                                                
                                                                                                                      
                                     
                                                                             
                                                                                                                                       
         
      
   

                                                   
                                      
  


                                           
                               
                                  
                                                         
                                      
                                                                  
                                                                                              
                                             
                                                                        
                                                                                                                  
         
      
   

                                                 


                                       
                               
                              
                                                     
                              
                                                           
                                                                                                                                                                                                                                                                          
                             
                                                                              
                                                                                                                                                                             
          
                             
                                                                      
                                                                                                                                          
                                     
                                                                                       
                                                                                                                              
           
        
      
   

                                                
                                  
  


                                        
                               
                                
                                                      
                         
                                                            
                                                                                                                                                                                        
                                   
                                                   
                                                                           
        
      
   

                                                       
                                  
                                       
  


                                               
                               
                                        
                                                             
                                                                                                                                                                                                                  
                       
                                                                        
                                                                                                                                   
        
      
   

                                                       
                                                    
  


                                               
                               
                                       
                                                             
                                
                                                          
                                                                                                    
                               
                                                                        
                                                                                                             
           
         
      
   

                                                                   
                                                    
  


                                                           
                               
                                                    
                                                                 
                                                    
                                                          
                                                                                                    
                               
                                                                        
                                                                                                             
           
         
      
   

                                                               
                                        
                                                    
                                            
  


                                                       
                               
                                                
                                                                        
                                   
                                                   
                                                                           
                           
                                                                  
                                                                                                              
                                
                                                                    
                                                                                             
           
         
      
   

                                                           


                                                 
                               
                                          
                                                       
                               
                                                             
                                                                                                              
         
      
   

                                              
                                                            
  


                                      
                               
                             
                                              
                              
                                                         
                                                                                                                                          
         
      
   

                                                              


                                                    
                               
                                                      
                                                                 
                                                                                                                                               
      
   

                                                   
                                                            
  


                                           
                               
                                  
                                                         
                                   
                                                               
                                                                                                                      
         
      
   

                                                         
                                                            
  


                                                 
                               
                                          
                                                         
                                           
                                                               
                                                                                        
         
      
   

                                                           
                                                            
  


                                                   
                               
                                            
                                                           
                                             
                                                               
                                                                                        
         
      
   

                                                       
                                                            
  


                                               
                               
                                       
                                                             
                                                                      
                           
                                                                          
                                                                                                                 
                          
                                                                          
                                                                                           
                          
                                                                                 
                                                                                          
           
         
      
   

                                                          
                                                            
  


                                                  
                               
                                                  
                                                         
                                           
                                                               
                                                                                                               
                                 
                                                                           
                                                                                                              
                                    
                                                                           
                                                                                                                                   
                                   
                                                                             
                                                                                                                
             
           
         
      
   

                                                                    


                                                          
                                  
                                           
                                                    
                                                           
      
   

                                                            
                               
  


                                                    
                                  
                                    
                                                    
                                                           
      
   

                                                      
                                    
                               
  


                                              
                                  
                            
                                                    
                                                           
      
   

                                          
                                                    
                                                               
                                                                    
                                                               
                                            
                                              
  


                                  
                               
                            
                                                               
                                   
                                                   
                                                                           
                           
                                                     
                                                                                                                                                                                                                                                                          
         
      
   

                                            


                                  
                               
                             
                                                               
                                                                                                                                
                    
                                                                        
                  
                                                                              
                                                                                            
                 
                                                                              
                                                                                            
                   
                                                                              
                                                                                            
                    
                                                                              
                                                                                            
          
        
      
   

                                             


                                   
                               
                             
                                                                
                            
                                                     
                                                                                                                                                                                                                                                                          
                                    
                                                           
                                                                                                                                                                                                                                   
                                 
                                                         
                                                                                                                                                                                                    
         
      
   

                                                 
                                            
                                              
                                               
                                             
  


                                         
                               
                                    
                                                                      
                                   
                                                   
                                                                           
                                   
                                                           
                                                                                                                                                                                                                                   
         
      
   

                                              
                                            
                                              
  


                                      
                               
                                 
                                                                   
                                   
                                                   
                                                                           
                                
                                                         
                                                                                                                                                                                                    
         
      
   

                                                   
                                  
                                              
  


                                           
                                  
                                   
                                                       
                                                                                                                                                                                                  
      
   

                                                     
                                  
                                                
  


                                             
                                  
                                     
                                                   
                                                                                                                                                                                                                                                                        
      
   

                                                       
                                            
                                              
  


                                               
                               
                                           
                                                                            
                                   
                                                   
                                                                           
                                               
                                                                                  
                                                                                                                              
         
      
   

                                           
                                                               
  


                                   
                               
                                          
                                                               
                                                    
                                                     
                                                                                                                     
         
      
   

                                                                   
                                        
  


                                                           
                                  
                                         
                                         
                                            
      
   

                                              


                                    
                               
                          
                                                  
                             
                                                        
                                                                                                                                  
                                   
                                                   
                                                                                                                                                                    
        
      
   

                                                  
                              
  


                                          
                                  
                               
                                                     
                                                                       
                   
                                                            
                                                                                                         
                             
                                                                
                                                                                        
           
                  
                                                            
                                                             
                             
                                                                
                                                                                        
           
                  
                                                            
                                                                         
                             
                                                                
                                                                                        
           
        
      
   

                                                 
                             
  


                                         
                                  
                             
                                                    
                                                                                                                             
                   
                                                           
                                                                                          
                  
                                                                 
                                                                                                                                                                
                    
                                                                 
                                                                                                                                                                
        
      
   

                                                  
                                             
                              
  


                                          
                                  
                              
                                                     
                                                           
      
   

                                                    
                                                     
  


                                            
                               
                                   
                                                          
                                           
                                                                
                                                                                                                                                                                                                                   
         
      
   

                                             
                                                            
  


                                     
                               
                           
                                                   
                                   
                                                         
                                                                                                                 
         
      
   

                                        
                                                            
  


                                
                               
                      
                                              
                              
                                                         
                                                                                                                                          
         
      
   

                                               


                                     
                               
                             
                                                   
                                     
                                                         
                                                                            
         
      
   

                                               


                                     
                               
                             
                                                   
                                     
                                                         
                                                                            
         
      
   

                                                   
                                                            
  


                                           
                               
                                   
                                                         
                                           
                                                               
                                                                                        
         
      
   

                                                     
                                                            
  


                                             
                               
                                     
                                                           
                                             
                                                               
                                                                                        
         
      
   

                                                  
                                                            
  


                                          
                               
                                 
                                                        
                                         
                                                 
                                                                 
         
      
   

                                                


                                      
                               
                             
                                                       
                         
                                                                   
                                                                              
                         
                                                                  
                                                                        
                         
                                                                           
                                                                                
                              
                                                                                       
                                                                                                            
             
           
         
      
   

                                                     
                                            
                                              
                                              
  


                                             
                               
                                     
                                                        
                                   
                                                   
                                                          
                                        
                                              
                                                                            
         
      
   

                                               


                                     
                               
                             
                                                
                                                          
      
   

                                                 


                                       
                               
                              
                                          
                                                                                                                                                                                                                          
      
   

                                                            


                                                  
                               
                                          
                                                                
                           
                                                     
                                                                             
         
      
   

                                                             
                                           
  


                                                     
                                  
                                          
                                                   
                                                                           
      
   

                                                                        
                                                      
  


                                                                
                                  
                                                      
                                                   
                                                                           
      
   

                                                         


                                               
                               
                                        
                                                             
                                       
                                                   
                                                                                                                                                                                                                                                                      
                                      
                                                                               
                                                                                                                                                                        
                                    
                                                                
                                                                                     
                                      
                                                                 
                                                                                
           
         
      
   

                                                      
                                      
  


                                              
                               
                                       
                                                 
                                                                                                                                                                                                                                                                    
                                    
                                                                             
                                                                                                                                                                      
                                  
                                                              
                                                                                   
                                    
                                                               
                                                                              
         
      
   

                                                           
                                      
                                               
  


                                                   
                                  
                                         
                                                 
                                                                                                                                                                                                                                                                    
                                    
                                                                             
                                                                                                                                                                      
                                  
                                                              
                                                                                   
                                    
                                                               
                                                                              
         
      
   

                                                          
                                         
  


                                                  
                                  
                                        
                                                 
                                                                                                                                                                                                                                                                    
                                    
                                                                             
                                                                                                                                                                      
                                  
                                                              
                                                                                   
                                    
                                                               
                                                                              
         
      
   

                                                           
                                
  


                                                   
                                  
                                
                                                     
                                 
                                         
                                                                         
                                            
                                                 
                                                                                      
           
                            
                                     
                                                                                    
         
      
   

                                               
                                            
  


                                       
                               
                         
                                               
                                   
                                                   
                                                          
                           
                                     
                                                                                    
         
      
   

                                                       
                            
  


                                               
                                  
                           
                                                 
                            
                                     
                                                                                    
         
      
   

                                                      
                                  
                           
  


                                              
                                  
                          
                                                
                            
                                     
                                                                                    
         
      
   

                                                
                                            
                                                                  
  


                                        
                               
                                   
                                                      
                                   
                                                   
                                                          
                                       
                                               
                                                                                                               
         
      
   

                                                         
                                         
  


                                                 
                               
                                        
                                                            
                                                                                                                                                                                                              
                                     
                                                                                   
                                                                                                                                                                                                                                                                      
                                                                  
                                                    
                                                                                    
           
        
      
   

                                               
                                            
                                                     
                                           
                                                          
                                                  
  


                                       
                               
                              
                                                     
                                   
                                                   
                                                                           
                                       
                                               
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                   
                                                                  
                                                    
                                                                                    
                                 
                                             
                                                                 
           
         
      
   

                                                                        
                                      
                                                    
  


                                                                
                                  
                                                        
                                                                             
                                                           
                                                                               
                                                                                                                                                         
                               
                                                                          
                                                                                                                                 
           
                               
                                                                               
                                                                                                               
                                          
                                                                                 
                                                                                                                                                           
                                 
                                                                            
                                                                                                                                   
             
           
         
      
   

                                                                  
                                      
  


                                                          
                               
                                                     
                                                                     
                                                                                     
                                                           
                                                                               
                                                                                                                                                         
                               
                                                                          
                                                                                                                                 
           
                               
                                                                               
                                                                                                               
                                          
                                                                                 
                                                                                                                                                           
                                 
                                                                            
                                                                                                                                   
             
           
         
      
   

                                                              
                                  
  


                                                      
                               
                                           
                                                
                          
                                                        
                                                                          
                       
                                                      
                                                                                                                                         
         
      
   

                                               
                                  
                                             
  


                                       
                               
                         
                                     
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                   
                           
                                          
                                                                             
                              
                                             
                                                                                                                                            
                    
                                                 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               
                               
                                                            
                                                                                                                         
                                  
                                                            
                                                                                                                         
                               
                                             
                                                                                                            
                                       
                                                    
                                                                                                                                                                                                                            
                                                         
                                                     
                                                                                                                        
                                              
                                                             
                                                                          
                                             
                                                        
                                                                                                      
           
                               
                                      
                                                                                                                                          
         
      
   

                                            
                                            
                                
                                                    
                                              
                                               
                                          
                                       
                                        
                                                 
  


                                    
                               
                           
                                                  
                                   
                                                   
                                                                           
                               
                                       
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                     
                             
                                            
                                                                               
                                
                                               
                                                                                                                                              
                      
                                                   
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 
                                 
                                                              
                                                                                                                           
                                    
                                                              
                                                                                                                           
                                 
                                               
                                                                                                              
                                         
                                                      
                                                                                                                                                                                                                              
                                                           
                                                       
                                                                                                                          
                                                
                                                               
                                                                            
                                               
                                                          
                                                                                                        
             
                                 
                                        
                                                                                                                                            
           
         
      
   

                                                      
                                            
                                
                                
                                                    
                                              
                                               
                                          
                                       
                                        
                                                 
  


                                              
                               
                           
                                                  
                                   
                                                   
                                                                           
                               
                                       
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                           
                             
                                            
                                                                               
                                
                                               
                                                                                                                                              
                      
                                                   
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 
                                 
                                                              
                                                                                                                           
                                    
                                                              
                                                                                                                           
                                 
                                               
                                                                                                              
                                         
                                                      
                                                                                                                                                                                                                              
                                                           
                                                       
                                                                                                                          
                                                
                                                               
                                                                            
                                               
                                                          
                                                                                                        
             
                                 
                                        
                                                                                                                                            
           
         
      
   

                                                      
                                            
  


                                              
                               
                                            
                                                                
                                   
                                                   
                                                                           
                                                
                                             
                                                                                                                                    
                                    
                                           
                                                                   
           
         
      
   

                                                      
                                  
                                       
  


                                              
                                  
                                             
                                           
                                                                                                                                  
                                  
                                         
                                                                 
         
      
   

                                                      
                                  
  


                                              
                               
                                            
                                                             
                                                                                                                                             
      
   

                                                       
                                  
                                        
  


                                               
                                  
                                             
                                                             
                                                                                                                                             
      
   

                                                                


                                                      
                                  
                                    
                                                        
                                                                       
      
   

                                                                   


                                                         
                                  
                                       
                                                        
                                                                       
      
   

                                              


                                    
                               
                              
                                                  
                                                                                                                                                                                                                                                                        
      
   

                                                                      
                                
  


                                                              
                                  
                                             
                                                  
                                                                                                                                                                                                                                                                        
      
   

                                                          
                                  
  


                                                  
                                  
                                            
                                                  
                                                                                                                                                                                                                                                                        
      
   

                                                  
                                  
                                                     
  


                                          
                               
                                   
                                       
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             
                               
                                                              
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               
                    
                                                       
                                                                                                                                                                                                                                                                                                                                                                                                                                                           
                                                                  
                                                    
                                                                                    
           
                                  
                                                                   
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 
                        
                                         
                                                                                                                           
                                           
                                                             
                                                                            
           
                                
                                             
                                                                                                            
                                       
                                                    
                                                                                                                                                                                                                            
                                                         
                                                     
                                                                                                                        
                                              
                                                             
                                                                          
                                             
                                                        
                                                                                                      
           
        
      
   

                                                
                                         
  


                                        
                               
                                      
                                                           
                                                       
                                                    
                                                                                                    
         
      
   

                                               
                                  
                                         
  


                                       
                                  
                                     
                                       
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             
                               
                                                              
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               
                    
                                                       
                                                                                                                                                                                                                                                                                                                                                                                                                                                           
                                                                  
                                                    
                                                                                    
           
                                  
                                                                   
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 
                        
                                         
                                                                                                        
         
      
   

                                                    
                                  
  


                                            
                                  
                                        
                                       
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             
                               
                                                              
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               
                    
                                                       
                                                                                                                                                                                                                                                                                                                                                                                                                                                           
                                                                  
                                                    
                                                                                    
           
                                  
                                                                   
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 
                        
                                         
                                                                                                        
         
      
   

                                              
                                            
  


                                      
                               
                                 
                                                      
                                   
                                                   
                                                                           
                                        
                                         
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               
                                 
                                                                
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 
                      
                                                         
                                                                                                                                                                                                                                                                                                                                                                                                                                                             
                                                                    
                                                      
                                                                                      
             
                                    
                                                                     
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                   
                          
                                           
                                                                                                          
           
         
      
   

                                                         
                                       
  


                                                 
                                  
                                              
                                                            
                                                                                               
      
   

                                                     
                                            
  


                                             
                               
                                    
                                                           
                                   
                                                   
                                                          
                                        
                                                        
                                                                                                                                                                         
         
      
   

                                       


                             
                               
                   
                                   
                                                                                         
                               
                                            
                                                                                                         
        
      
   

                                                     


                                           
                               
                                     
                                                         
                                   
                                                   
                                                                           
                                        
                                                       
                                                                                                                                                
                                             
                                                         
                                                                                                                                                                    
                                            
                                                         
                                                                                                                     
          
         
      
   

                                                        
                                                      
  


                                                
                                  
                                       
                                         
                                            
      
   

                                                                    
                                              
                                
  


                                                            
                                  
                                             
                                                     
                                                                                                                                              
                                           
                                                       
                                                                                                                                                                  
                                          
                                                       
                                                                                                                   
        
      
   

                                                                      
                                                 
                                
  


                                                              
                                  
                                                
                                                     
                                                                                                                                              
                                           
                                                       
                                                                                                                                                                  
                                          
                                                       
                                                                                                                   
        
      
   

                                                                        
                                                   
                                
  


                                                                
                                  
                                                  
                                                     
                                                                                                                                              
                                           
                                                       
                                                                                                                                                                  
                                          
                                                       
                                                                                                                   
        
      
   

                                                                     
                                          
  


                                                             
                                  
                                             
                                                     
                                                                                                                                              
                                           
                                                       
                                                                                                                                                                  
                                          
                                                       
                                                                                                                   
        
      
   

                                             
                                                  
                                            
                                            
                                  
                              
                                                 
                                              
  


                                     
                               
                            
                                                        
                                   
                                                   
                                                                           
                                
                                                
                                                                                                                                                                                                                                                                       
                              
                                                
                                                                                                                                                                     
                                 
                                                        
                                                                  
             
                                 
                                         
                                                
                                 
                                                 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        
                                                       
                                                             
                                                                                               
                                
                                          
                                                                                                                                                                                  
                                   
                                                          
                                                                    
                                                
                                                             
                                                                     
                 
                                
                                        
                                                                                                                                                                                                                           
               
            
           
         
      
   

                                                    
                                            
  


                                            
                               
                              
                                                     
                                   
                                                   
                                                                           
                                  
                                          
                                                                                                        
         
      
   

                                                    
                           
  


                                            
                                  
                                
                                        
                                                                                                      
      
   

                                                    
                                  
  


                                            
                                  
                                  
                                         
                                            
      
   

                                                    
                                  
                                 
  


                                            
                                  
                                
                                         
                                            
      
   

                                                         
                                            
                                              
                                                            
                                                          
                                        
                                                   
                                                               
                                     
                                               
                                                          
                                        
                                                         
  


                                                 
                               
                                   
                                                          
                                   
                                                   
                                                                           
                                     
                                             
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                     
                                                   
                                                         
                                                                                           
                            
                                      
                                                                                                                                                                              
                               
                                                      
                                                                
                                            
                                                         
                                                                 
             
                            
                                    
                                                                                                                                                                                                                       
                                            
                                                                       
                                                                                                                                                                           
                                    
                                                                                 
                                                                                                                                                                   
             
          
         
      
   

                                                     
                                             
  


                                             
                                  
                                            
                                         
                                            
      
   

                                                 
                                                   
                                         
  


                                         
                                  
                                                      
                                           
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                   
                                                 
                                                       
                                                                                         
                          
                                    
                                                                                                                                                                            
                             
                                                    
                                                              
                                          
                                                       
                                                               
           
                          
                                  
                                                                                                                                                                                                                     
                                          
                                                                     
                                                                                                                                                                         
                                  
                                                                               
                                                                                                                                                                 
           
        
      
   

                                                   
                                                   
                                         
                                   
  


                                           
                                  
                                                    
                                                   
                                                              
                               
                                             
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    
                                                   
                                                         
                                                                                           
                            
                                      
                                                                                                                                                                              
                               
                                                      
                                                                
                                            
                                                         
                                                                 
             
                            
                                    
                                                                                                                                                                                                                       
           
        
      
   

                                                          


                                                
                               
                                        
                                                              
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      
      
   

                                                              
                                         
  


                                                      
                                  
                                            
                                                              
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      
      
   

                                          


                                
                               
                       
                                              
                                                       
      
   

                                                   


                                         
                                  
                             
                                              
                                                   
      
   

                                                              
                                                    
  


                                                      
                               
                                      
                                                                    
                                              
                                                                 
                                                                                                                              
         
      
   

                                                                      
                                        
                                                    
                                            
  


                                                              
                               
                                               
                                                                            
                                   
                                                   
                                                                           
                           
                                                                             
                                                                                                                       
                               
                                                                               
                                                                                                      
                                    
                                           
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                           
                                   
                                                                  
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                   
                        
                                                           
                                                                                                                                                                                                                                                                                                                                                                                                                                                               
                                                                      
                                                        
                                                                                        
               
                                      
                                                                       
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                     
                                   
                                                 
                                                                                                                
                                           
                                                        
                                                                                                                                                                                                                                
                                                             
                                                         
                                                                                                                            
                                                  
                                                                 
                                                                              
                                                 
                                                            
                                                                                                          
               
            
          
         
      
   

                                                         
                                                 
  


                                                 
                               
                                          
                                                           
                                   
                                                   
                                                                           
                                    
                                            
                                                                                                                                                                                                
                            
                                                    
                                                                                                                                                                    
                                            
                                                         
                                                                 
                               
                                                      
                                                                
             
           
         
      
   

                                                          
                                       
  


                                                  
                                  
                                           
                                         
                                            
      
   

                                                  


                                        
                               
                               
                                                      
                                       
                                                            
                                                                                 
         
      
   

                                                   


                                         
                               
                                
                                                       
                              
                                                             
                                                              
                          
                                                                   
                                                                                              
           
         
      
   

                                              
                                            
  


                                      
                               
                              
                                                    
                                          
                                                           
                                                                                                                                                                                                                   
                                
                                                          
                                                                                                                                                                                                                    
                                
                                                                           
                                                                                                                                                    
          
                                   
                                                   
                                                                           
        
      
   

                                                       
                                            
                                                              
                                              
                                               
                                                   
                                                            
                                                   
  


                                               
                               
                                        
                                                         
                                   
                                                   
                                                                           
                                       
                                              
                                                                                                                                                         
         
      
   

                                                        
                                  
  


                                                
                               
                                                
                                                              
                                   
                                                       
                                                                                                      
         
      
   

                                                      
                                  
  


                                              
                               
                                         
                                                           
                                   
                                                   
                                                                           
                                              
                                                     
                                                                                                                                                                                                                                                              
                                                   
                                                         
                                                                                           
           
         
      
   

                                     
                                              
                                            
                                            
                                                        
                                                            
  


                             
                               
                    
                                                 
                                   
                                                   
                                                                           
                        
                                         
                                                                                                                                                                                                                                                                               
                                          
                                                             
                                                                          
           
                                 
                                                
                                                                                                                                                                                                                                                                       
                              
                                                
                                                                                                                                                                     
                                 
                                                        
                                                                  
             
                                 
                                         
                                                
                                 
                                                 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        
                                                       
                                                             
                                                                                               
                                
                                          
                                                                                                                                                                                  
                                   
                                                          
                                                                    
                                                
                                                             
                                                                     
                 
                                
                                        
                                                                                                                                                                                                                           
               
            
           
         
      
   

                                               
                                  
                                              
                                            
                                          
                                              
  


                                       
                               
                                
                                                 
                                   
                                                   
                                                                           
                               
                                            
                                                                                                                                                                           
                                         
                                                     
                                                            
           
         
      
   

                                            
                                                             
  


                                    
                               
                           
                                                  
                                   
                                                   
                                                                           
                              
                                        
                                                                                                                       
         
      
   

                                               
                                          
  


                                       
                               
                                
                                      
                                                                                                                                      
      
   

                                              
                                         
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 
                                               
                                                     
                                                                                       
                        
                                  
                                                                                                                                                                          
                           
                                                  
                                                            
                                        
                                                     
                                                             
         
                        
                                
                                                                                                                                                                                                                   
                                        
                                                                   
                                                                                                                                                                       
                                
                                                                             
                                                                                                                                                               
         
      
   

                                                    
                                            
                                              
                                                            
                                                          
                                                            
                                        
                                                   
                                                               
                                     
                                               
                                                          
                                        
                                                         
                                                   
                                                          
                                                                           
                                             
                                                                      
  


                                            
                               
                                   
                                                          
                                   
                                                   
                                                                           
                                     
                                             
                                      
         
      
   

                                             
                                      
                                             
  


                                     
                               
                           
                                           
                                    
      
   

                                                    
                                      
                            
  


                                            
                                  
                                       
                                
                                                                                                                                                                                                                   
      
   

                                                     
                                      
                                   
  


                                             
                                  
                                 
                                           
                                    
      
   

                                                             
                                      
                                           
  


                                                     
                                  
                                           
                                           
                                    
      
   

                                                            


                                                  
                               
                                                 
                                                                
                                                         
                                                               
                                                                                                                                
         
      
   

                                                          
                                                    
  


                                                  
                               
                                          
                                                                
                                                  
                                                          
                                                                                                    
                               
                                                                        
                                                                                                             
           
         
      
   

                                                                  
                                        
                                                    
                                            
  


                                                          
                               
                                                   
                                                                        
                                   
                                                   
                                                                           
                           
                                                                  
                                                                                                              
                                
                                                                    
                                                                                             
           
         
      
   

                                                     


                                           
                               
                                    
                                                         
                       
                                                               
                                                                                   
         
      
   

                                                   


                                         
                               
                                
                                                       
                         
                                                                   
                                                                              
                         
                                                                  
                                                                        
                         
                                                                           
                                                                                
                              
                                                                                       
                                                                                                            
             
           
         
      
   

                                                    


                                          
                                  
                               
                                         
                                            
      
   

                                           
                                      
  


                                   
                                  
                        
                                         
                                            
      
   

                                       
                                            
  


                               
                               
                     
                                             
                                   
                                                   
                                                                           
                       
                                  
                                                                                                                                                                                                                                                                                                                                                                                                                                                          
                                    
                                                        
                                                                                                                                                                                                                                                                                                                                                         
          
         
      
   

                                      
                                  
  


                              
                               
                       
                                
                                                                                                                                                                                                                                                                                                                                                                                                                                                        
                                  
                                                      
                                                                                                                                                                                                                                                                                                                                                       
        
      
   

                                            
                         
  


                                    
                                  
                       
                                
                                                                                                                                                                                                                                                                                                                                                                                                                                                       
      
   

                                            
                                  
                        
  


                                    
                                  
                       
                                
                                                                                                                                                                                                                                                                                                                                                                                                                                                       
      
   

                                              


                                    
                               
                       
                                
                                                                                                                                                                                                                                                                                                                                                                                                                                                       
      
   

                                                              
                                            
                                             
  


                                                      
                               
                                         
                                                               
                                   
                                                   
                                                                           
                                  
                                                   
                                                                       
                           
                                    
                                                                                                                                                                                                                                                                     
                                        
                                                           
                                                                                                                                                                                   
                                                     
                                                                 
                                                                                    
           
         
      
   

                                                             
                                             
                                        
  


                                                     
                               
                                         
                                                              
                                                                                                                                                                                                                                                         
                                                    
                                                               
                                                                                  
         
      
   

                                             
                                     
  


                                     
                                  
                                          
                                  
                                                                                                                                                                                                          
      
   

                                             
                                        
                                     
  


                                     
                                  
                                          
                                  
                                                                                                                                                                                                                            
      
   

                                            
                                        
  


                                    
                                  
                                         
                                   
                                                               
      
   

                                                              
                                             
  


                                                      
                               
                                               
                                  
                                                                                                                                                                                                                                                                                                                                                                         
                                      
                                                         
                                                                                                                                                                                 
                                                   
                                                               
                                                                                  
         
      
   

                                                                   
                                                 
  


                                                           
                                  
                                                 
                                  
                                                                                                                                                                                                                                                                                                                                                                        
      
   

                                                                                   


                                                                         
                                  
                                                                 
                                  
                                                                                                                                                                                                                                                                                                                                                                        
      
   

                                                                                    


                                                                          
                                  
                                                                  
                                  
                                                                                                                                                                                                                                                                                                                                                                        
      
   

                                                              
                                        
                                                        
  


                                                      
                                  
                                                               
                                         
                                            
      
   

                                                            
                                        
  


                                                    
                                  
                                                             
                                         
                                            
      
   

                                                  
                                  
  


                                          
                               
                                  
                                                   
                                                                             
                                        
                                              
                                                                                                      
         
      
   

                                                      
                                  
                                   
  


                                              
                                  
                                     
                                         
                                            
      
   

                                           
                                      
  


                                   
                               
                       
                                
                                                                                                                                                                                                                                                                                                                                                                                                                                                       
      
   

                                            
                                            
  


                                    
                               
                     
                                             
                                   
                                                   
                                                          
                       
                                  
                                                                                                                                                                                                                                                                                                                                                                                                                               
         
      
   

                                                           


                                                 
                               
                                         
                                                               
                                                                                                              
      
   
