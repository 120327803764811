var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
// @ts-nocheck
import { lazy } from 'react';
import routeUtils from './routesUtils';
var chunk = routeUtils.chunk;
// prettier-ignore
var SettingsV2Components = {
    SettingsActivity: lazy(function () { return chunk(import('./pages/settings/v2/SettingsActivity')); }),
    SettingsProfile: lazy(function () { return chunk(import('./pages/settings/v2/SettingsProfile')); }),
    SettingsCompliance: lazy(function () { return chunk(import('./pages/settings/v2/SettingsComplianceReports')); }),
    SettingsManageUsers: lazy(function () { return chunk(import('./pages/settings/v2/SettingsManageUsers')); }),
    SettingsManageUsersEdit: lazy(function () { return chunk(import('./pages/settings/v2/SettingsManageUsersEdit')); }),
    ManagementAlerts: lazy(function () { return chunk(import('./pages/management/alerts/ManagementAlertsPage')); }),
    AcheronSharedLinks: lazy(function () { return chunk(import('./pages/management/shared_links/ManagementSharedLinks')); }),
    ErrorPage: lazy(function () { return chunk(import('./pages/management/share/ShareLinksErrorPage')); }),
};
// prettier-ignore
var ApiV2Components = {
    ApiProtectV2Page: lazy(function () { return chunk(import('./pages/api/v2/ApiProtectPage')); }),
    ApiProtectV2Dashboard: lazy(function () { return chunk(import('./pages/api/v2/ApiProtectDashboard')); }),
    ApiProtectSecurityV2Page: lazy(function () { return chunk(import('./pages/api/v2/ApiProtectSecurityPage')); }),
    ApiInventoryV2Page: lazy(function () { return chunk(import('./pages/api/v2/ApiInventoryV2Page')); }),
    ApiPoliciesV2Page: lazy(function () { return chunk(import('./pages/api/v2/ApiPoliciesV2Page')); }),
    ApiPoliciesV2UuidPage: lazy(function () { return chunk(import('./pages/api/v2/ApiPoliciesV2UuidPage')); }),
    ApiProtectV2AssetGroupsUuidPage: lazy(function () { return chunk(import('./pages/api/v2/ApiProtectV2AssetGroupsUuidPage')); }),
    ApiV2AssetsUuidPage: lazy(function () { return chunk(import('./pages/api/v2/ApiV2AssetsUuidPage')); }),
    ApiV2GraphqlApisUuidPage: lazy(function () { return chunk(import('./pages/api/v2/ApiV2GraphqlApisUuidPage')); }),
    ApiV2NetworkServicesUuidPage: lazy(function () { return chunk(import('./pages/api/v2/ApiV2NetworkServicesUuidPage')); }),
    ApiV2WebApplicationsUuidPage: lazy(function () { return chunk(import('./pages/api/v2/ApiV2WebApplicationsUuidPage')); }),
    ApiV2PolicyViolationsPage: lazy(function () { return chunk(import('./pages/api/v2/ApiV2PolicyViolationsPage')); }),
    ApiV2PolicyViolationsUuidPage: lazy(function () { return chunk(import('./pages/api/v2/ApiV2PolicyViolationsUuidPage')); }),
    ApiV2ProtectUuidPage: lazy(function () { return chunk(import('./pages/api/v2/ApiV2ProtectUuidPage')); }),
    ApiV2OperationDetailsContainer: lazy(function () { return chunk(import('./pages/api/v2/ApiV2OperationDetailsContainer')); }),
};
// prettier-ignore
var MobileV2Components = {
    MobileSecureV2Dashboard: lazy(function () { return chunk(import('./pages/mobile/v2/MobileSecureDashboard')); }),
    MobileSecureV2SecurityPage: lazy(function () { return chunk(import('./pages/mobile/v2/MobileSecureSecurityPage')); }),
    MobileSecureV2InventoryPage: lazy(function () { return chunk(import('./pages/mobile/v2/MobileSecureInventoryPage')); }),
    MobileProtectV2ActiveProtectionPage: lazy(function () { return chunk(import('./pages/mobile/v2/MobileProtectActiveProtectionPage')); }),
    MobileProtectV2Reports: lazy(function () { return chunk(import('./pages/mobile/v2/MobileProtectReports')); }),
    MobileV2PolicyViolationsUuidPage: lazy(function () { return chunk(import('./pages/mobile/v2/MobileV2PolicyViolationsUuidPage')); }),
};
// prettier-ignore
var WebV2Components = {
    WebSecureV2DashboardPage: lazy(function () { return chunk(import('./pages/web/v2/WebSecureDashboardPage')); }),
    WebSecureV2SecurityPage: lazy(function () { return chunk(import('./pages/web/v2/WebSecureSecurityPage')); }),
    WebSecureV2InventoryPage: lazy(function () { return chunk(import('./pages/web/v2/WebSecureInventoryPage')); }),
    WebProtectV2ActiveProtectionPage: lazy(function () { return chunk(import('./pages/web/v2/WebProtectActiveProtectionPage')); }),
    WebSecureV2AssetGroupsPage: lazy(function () { return chunk(import('./pages/web/v2/WebSecureInventoryAssetGroupUuidPage')); }),
    WebSecureV2AssetsUuidPage: lazy(function () { return chunk(import('./pages/web/v2/WebV2AssetsUuidPage')); })
};
// prettier-ignore
var cloudV2Components = {
    cloudSecureV2DashboardPage: lazy(function () { return chunk(import('./pages/cloud/v2/CloudSecureDashboardPage')); }),
    cloudSecureV2InventoryPage: lazy(function () { return chunk(import('./pages/cloud/v2/CloudSecureInventoryPage')); }),
    cloudSecureV2SecurityPage: lazy(function () { return chunk(import('./pages/cloud/v2/CloudSecureSecurityPage')); }),
    cloudSecureV2ActiveProtectionPage: lazy(function () { return chunk(import('./pages/cloud/v2/CloudSecureActiveProtectionPage')); }),
    cloudSecureV2AssetGroupsPage: lazy(function () { return chunk(import('./pages/cloud/v2/CloudSecureInventoryAssetGroupUuidPage')); })
};
// prettier-ignore
var OpenScanV2Components = {
    OpenScanV2DashboardPage: lazy(function () { return chunk(import('./pages/supply_chain/v2/open_scan/OpenScanDashboardPage')); }),
    OpenScanV2SearchPage: lazy(function () { return chunk(import('./pages/supply_chain/v2/open_scan/OpenScanSearchPage')); }),
    OpenScanV2AppPage: lazy(function () { return chunk(import('./pages/supply_chain/v2/open_scan/OpenScanAppPage')); }),
    OpenScanV2AppPublicPage: lazy(function () { return chunk(import('./pages/supply_chain/v2/open_scan/OpenScanAppPublicViewPage')); }),
};
// prettier-ignore
var DevSecOpsV2Components = {
    DevSecopsV2Page: lazy(function () { return chunk(import('./pages/devsecops/v2/DevsecopsPage')); }),
    StanfordDishUniHomeV2: lazy(function () { return chunk(import('./pages/devsecops/v2/StanfordDishUniHome')); }),
    DevSecOpsScanCICD: lazy(function () { return chunk(import('./pages/devsecops/v2/DevSecOpsScanCICD')); }),
    DevSecOpsSASTCodeAnalysis: lazy(function () { return chunk(import('./pages/devsecops/v2/DevSecOpsSASTCodeAnalysis')); }),
    DevSecOpsSASTCodeAnalysisCreateIntegration: lazy(function () { return chunk(import('./pages/devsecops/v2/DevSecOpsSASTCodeAnalysisCreateIntegration')); }),
    StanfordDishUniViewAllMaterialsV2: lazy(function () { return chunk(import('./pages/devsecops/v2/StanfordDishUniViewAllMaterials')); }),
    AwsSecurityHubPage: lazy(function () { return chunk(import('./pages/devsecops/v2/AwsSecurityHubPage')); }),
    MobileAppUploadPage: lazy(function () { return chunk(import('./pages/devsecops/v2/MobileAppUploadPage')); }),
    DevSecOpsSaml: lazy(function () { return chunk(import('./pages/devsecops/v2/DevSecOpsSaml')); }),
    DevSecOpsSamlSecureSharePage: lazy(function () { return chunk(import('./pages/devsecops/v2/DevSecOpsSamlSecureSharePage')); }),
    DevSecOpsJiraOptions: lazy(function () { return chunk(import('./pages/devsecops/v2/DevSecOpsJiraOptionsPage')); }),
    DevSecOpsJiraLandingPage: lazy(function () { return chunk(import('./pages/devsecops/v2/DevSecOpsJiraLandingPage')); }),
    DevSecOpsJiraConfigurePage: lazy(function () { return chunk(import('./pages/devsecops/v2/DevSecOpsJiraConfigurePage')); }),
    DevSecOpsServiceNowLandingPage: lazy(function () { return chunk(import('./pages/devsecops/v2/DevSecOpsServiceNowLandingPage')); }),
    DevSecOpsServiceNowConfigurePage: lazy(function () { return chunk(import('./pages/devsecops/v2/DevSecOpsServiceNowConfigurePage')); }),
    DevSecOpsDatadogLandingPage: lazy(function () { return chunk(import('./pages/devsecops/v2/DevSecOpsDatadogLandingPage')); }),
    DevSecOpsDatadogConfigurePage: lazy(function () { return chunk(import('./pages/devsecops/v2/DevSecOpsDatadogConfigurePage')); }),
    ResultsApiAccessPage: lazy(function () { return chunk(import('./pages/devsecops/v2/DevSecOpsResultsAPIAccessPage')); }),
    DevSecOpsApiKeysCreatePage: lazy(function () { return chunk(import('./pages/devsecops/v2/DevSecOpsApiKeysCreatePage')); }),
    DevSecOpsApiKeysEditPage: lazy(function () { return chunk(import('./pages/devsecops/v2/DevSecOpsApiKeysEditPage')); }),
    DevSecOpsJiraMobileAppsPage: lazy(function () { return chunk(import('./pages/devsecops/v2/DevSecOpsJiraMobileAppsPage')); }),
    DevSecOpsJiraMobileFormPage: lazy(function () { return chunk(import('./pages/devsecops/v2/DevSecOpsJiraMobileFormPage')); }),
    DevSecOpsWebhookIntegrationPage: lazy(function () { return chunk(import('./pages/devsecops/v2/DevSecOpsWebhookIntegrationPage')); }),
    DevSecOpsSlackMicrosoftTeamsIntegrationPage: lazy(function () { return chunk(import('./pages/devsecops/v2/DevSecOpsSlackMicrosoftTeamsIntegrationPage')); }),
    DevSecOpsUploadSourceCode: lazy(function () { return chunk(import('./pages/devsecops/v2/DevSecOpsUploadSourceCode')); }),
    DevSecOpsSastConfigurationPage: lazy(function () { return chunk(import('./pages/devsecops/v2/DevSecOpsSastConfigurationPage')); }),
    DevSecOpsGithubOnPremConfiguration: lazy(function () { return chunk(import('./pages/devsecops/v2/DevSecOpsGithubOnPremConfiguration')); }),
};
// prettier-ignore
var ApiComponents = {
    ApiCustomChecksPage: lazy(function () { return chunk(import('./pages/api/toolkits/custom-checks/ApiCustomChecksPage')); }),
    ApiAssetsUuidPage: lazy(function () { return chunk(import('./pages/api/assets/uuid/ApiAssetsUuidPage')); }),
    ApiAsmSetupPage: lazy(function () { return chunk(import('./pages/api/asm_setup/ApiAsmSetupPage')); }),
    ApiAssetGroupsUuidPage: lazy(function () { return chunk(import('./pages/api/asset_groups/uuid/ApiAssetGroupsUuidPage')); }),
    ApiInventoryPage: lazy(function () { return chunk(import('./pages/api/inventory/ApiInventoryPage')); }),
    ApiPoliciesPage: lazy(function () { return chunk(import('./pages/api/policies/ApiPoliciesPage')); }),
    ApiPoliciesUuidPage: lazy(function () { return chunk(import('./pages/api/policies/uuid/ApiPoliciesUuidPage')); }),
    ApiPolicyViolationsPage: lazy(function () { return chunk(import('./pages/api/policy_violations/ApiPolicyViolationsPage')); }),
    ApiPolicyViolationsUuidPage: lazy(function () { return chunk(import('./pages/api/policy_violations/uuid/ApiPolicyViolationsUuidPage')); }),
    ApiCloudResourcesUuidPage: lazy(function () { return chunk(import('./pages/api/cloud_resources/uuid/ApiCloudResourcesUuidPage')); }),
    ApiMobileApplicationLoadingPage: lazy(function () { return chunk(import('./pages/api/mobile_applications/ApiMobileApplicationLoadingPage')); }),
    ApiProtectionPage: lazy(function () { return chunk(import('./pages/api/toolkits/api_protect/ApiProtectPage')); }),
    ApiProtectUuidPage: lazy(function () { return chunk(import('./pages/api/toolkits/api_protect/ApiProtectUuidPage')); }),
    ApiShadowAssetsPage: lazy(function () { return chunk(import('./pages/api/shadow_assets/ApiShadowAssetsPage')); }),
    APINetworkServicesUuidPage: lazy(function () { return chunk(import('./pages/api/network_services/uuid/APINetworkServicesUuidPage.js')); }),
    ApiWebApplicationsUuidPage: lazy(function () { return chunk(import('./pages/api/web_applications/uuid/ApiWebApplicationsUuidPage')); }),
    ApiGraphqlApisUuidPage: lazy(function () { return chunk(import('./pages/api/graphql_apis/uuid/ApiGraphqlApisUuidPage')); }),
};
// prettier-ignore
var WebComponents = {
    WebNetworkServicesUuidPage: lazy(function () { return chunk(import('./pages/web/network_services/uuid/WebNetworkServicesUuidPage.js')); }),
    WebApplicationsUuidPage: lazy(function () { return chunk(import('./pages/web/applications/uuid/WebApplicationsUuidPage')); }),
    WebAsmSetupPage: lazy(function () { return chunk(import('./pages/web/asm_setup/WebAsmSetupPage')); }),
    WebAssetGroupsUuidPage: lazy(function () { return chunk(import('./pages/web/v2/WebInventoryAssetGroupsUuidPage')); }),
    WebCloudResourcesUuidPage: lazy(function () { return chunk(import('./pages/web/cloud_resources/uuid/WebCloudResourcesUuidPage')); }),
    WebGraphqlApisUuidPage: lazy(function () { return chunk(import('./pages/web/graphql_apis/uuid/WebGraphqlApisUuidPage')); }),
    WebAssetsUuidPage: lazy(function () { return chunk(import('./pages/web/assets/uuid/WebAssetsUuidPage')); }),
    WebInventoryPage: lazy(function () { return chunk(import('./pages/web/inventory/WebInventoryPage')); }),
    WebPage: lazy(function () { return chunk(import('./pages/web/WebPage')); }),
    WebCustomChecksPage: lazy(function () { return chunk(import('./pages/web/security_toolkits/custom-checks/WebCustomChecksPage')); }),
    WebPoliciesPage: lazy(function () { return chunk(import('./pages/web/policies/WebPoliciesPage')); }),
    WebPoliciesUuidPage: lazy(function () { return chunk(import('./pages/web/policies/uuid/WebPoliciesUuidPage')); }),
    WebPolicyViolationsPage: lazy(function () { return chunk(import('./pages/web/policy_violations/WebPolicyViolationsPage')); }),
    WebPolicyViolationsUuidPage: lazy(function () { return chunk(import('./pages/web/policy_violations/uuid/WebPolicyViolationsUuidPage')); }),
    WebSecurityToolkitsDetectInjectPage: lazy(function () { return chunk(import('./pages/web/security_toolkits/sql_injection/WebSecurityToolkitsSqlInjectionPage')); }),
    WebSecurityToolkitsPage: lazy(function () { return chunk(import('./pages/web/security_toolkits/WebSecurityToolkitsPage')); }),
    WebSecurityToolkitsXssAttackPage: lazy(function () { return chunk(import('./pages/web/security_toolkits/xss_attack/WebSecurityToolkitsXssAttackPage')); }),
    WebSecurityToolkitsXssProtectionPage: lazy(function () { return chunk(import('./pages/web/security_toolkits/xss_protection/WebSecurityToolkitsXssProtectionPage')); }),
    WebSecurityToolkitsWebProtectPage: lazy(function () { return chunk(import('./pages/web/security_toolkits/web_protect/WebSecurityToolkitsWebProtectPage')); }),
    WebShadowAssetsPage: lazy(function () { return chunk(import('./pages/web/shadow_assets/WebShadowAssetsPage')); }),
    WebToxicTokensPage: lazy(function () { return chunk(import('./pages/web/toxic_tokens/WebToxicTokensPage')); }),
};
// prettier-ignore
var SupplyChainComponents = {
    SupplyChainPage: lazy(function () { return chunk(import('./pages/supply_chain/supplyChainPage')); }),
    SupplyChainSBOMPage: lazy(function () { return chunk(import('./pages/supply_chain/sbom/SBOMPage')); }),
    SupplyChainVendorsPage: lazy(function () { return chunk(import('./pages/supply_chain/vendors/VendorsPage')); }),
    SupplyChainMyAppsPage: lazy(function () { return chunk(import('./pages/supply_chain/my_apps/MyAppsPage')); }),
    SupplyChainPolicyPage: lazy(function () { return chunk(import('./pages/supply_chain/policy/SupplyChainPolicyPage')); }),
    SupplyChainAsmSetupPage: lazy(function () { return chunk(import('./pages/supply_chain/asm_setup/SupplyChainAsmSetupPage')); }),
    SupplyChainSbomSecurityIssuesPage: lazy(function () { return chunk(import('./pages/supply_chain/security_issues/SupplyChainSecurityIssuesPage')); }),
    SupplierTrustPage: lazy(function () { return chunk(import('./pages/supply_chain/supplier_trust/SupplierTrustPage')); }),
    VendorUuidPage: lazy(function () { return chunk(import('./pages/supply_chain/supplier_trust/uuid/SupplierTrustUuidPage')); }),
    VendorUuidPublicViewPage: lazy(function () { return chunk(import('./pages/supply_chain/supplier_trust/uuid/SupplierTrustUuidPublicViewPage')); }),
    SupplyChainAppUuidWebPage: lazy(function () { return chunk(import('./pages/supply_chain/my_apps/uuid/web/SupplyChainAppUuidWebPage')); }),
    SupplyChainAppUuidMobilePage: lazy(function () { return chunk(import('./pages/supply_chain/my_apps/uuid/mobile/SupplyChainAppUuidMobilePage')); }),
};
// prettier-ignore
var CloudComponents = {
    CloudActivityPage: lazy(function () { return chunk(import('./pages/cloud/activity/CloudActivityPage')); }),
    CloudAsmSetupPage: lazy(function () { return chunk(import('./pages/cloud/asm_setup/CloudAsmSetupPage')); }),
    CloudAssetGroupsUuidPage: lazy(function () { return chunk(import('./pages/cloud/asset_groups/uuid/CloudAssetGroupsUuidPage')); }),
    CloudCloudResourcesUuidPage: lazy(function () { return chunk(import('./pages/cloud/cloud_resources/uuid/CloudCloudResourcesUuidPage')); }),
    CloudInventoryPage: lazy(function () { return chunk(import('./pages/cloud/inventory/CloudInventoryPage')); }),
    CloudNetworkServicesUuidPage: lazy(function () { return chunk(import('./pages/cloud/network_services/uuid/CloudNetworkServicesUuidPage.js')); }),
    CloudPage: lazy(function () { return chunk(import('./pages/cloud/CloudPage')); }),
    CloudAssetsUuidPage: lazy(function () { return chunk(import('./pages/cloud/assets/uuid/CloudAssetsUuidPage')); }),
    CloudPoliciesPage: lazy(function () { return chunk(import('./pages/cloud/policies/CloudPoliciesPage')); }),
    CloudPoliciesUuidPage: lazy(function () { return chunk(import('./pages/cloud/policies/uuid/CloudPoliciesUuidPage')); }),
    CloudPolicyViolationsPage: lazy(function () { return chunk(import('./pages/cloud/policy_violations/CloudPolicyViolationsPage')); }),
    CloudPolicyViolationsUuidPage: lazy(function () { return chunk(import('./pages/cloud/policy_violations/uuid/CloudPolicyViolationsUuidPage')); }),
    CloudShadowAssetsPage: lazy(function () { return chunk(import('./pages/cloud/shadow_assets/CloudShadowAssetsPage')); }),
    CloudToolkitsCodeRedPage: lazy(function () { return chunk(import('./pages/cloud/toolkits/code_red/CodeRedPage')); }),
    CloudToolkitsDlpPage: lazy(function () { return chunk(import('./pages/cloud/toolkits/dlp/CloudToolkitsDlpPage')); }),
    CloudToolkitsGotchasPage: lazy(function () { return chunk(import('./pages/cloud/toolkits/gotchas/CloudToolkitsGotchasPage')); }),
    CloudToolkitsLeaksPage: lazy(function () { return chunk(import('./pages/cloud/toolkits/leaks/CloudToolkitsLeaksPage')); }),
    CloudToolkitsRansomwarePage: lazy(function () { return chunk(import('./pages/cloud/toolkits/ransomware/RansomwarePage')); }),
    CloudXDRPage: lazy(function () { return chunk(import('./pages/cloud/toolkits/xdr/CloudXDRPage')); }),
    CloudIacScanPage: lazy(function () { return chunk(import('./pages/cloud/iac_scan/CloudIacScanPage')); }),
    CloudToolkitsPage: lazy(function () { return chunk(import('./pages/cloud/toolkits/CloudToolkitsPage')); }),
    CloudWebApplicationsUuid: lazy(function () { return chunk(import('./pages/cloud/web_applications/uuid/CloudWebApplicationsUuidPage')); }),
    CloudGraphqlApisUuidPage: lazy(function () { return chunk(import('./pages/cloud/graphql_apis/uuid/CloudGraphqlApisUuidPage')); }),
    ApolloCorrectAndProjectContainer: lazy(function () { return chunk(import('./pages/cloud/toolkits/correct_protect/CorrectAndProtectPage')); }),
    MajorMalfunctionPage: lazy(function () { return chunk(import('./pages/cloud/toolkits/major_malfunction/MajorMalfunctionPage')); }),
    CloudCIEMPage: lazy(function () { return chunk(import('./pages/cloud/toolkits/ciem/CloudCIEMPage')); }),
    CloudCIEMPageDemo: lazy(function () { return chunk(import('./pages/cloud/toolkits/ciem/CloudCIEMPageDemo')); }),
};
// prettier-ignore
var ManagementComponents = {
    ManagementActivity: lazy(function () { return chunk(import('./pages/management/activity/ActivityPage')); }),
    ManagementSharePolicyViolationPage: lazy(function () { return chunk(import('./pages/management/share/ManagementSharePolicyViolationPage')); }),
    OnboardingWizardPage: lazy(function () { return chunk(import('./pages/management/onboarding_wizard/OnboardingWizardPage')); }),
    OnboardingWizardPublicViewPage: lazy(function () { return chunk(import('./pages/management/onboarding_wizard/OnboardingWizardPublicViewPage')); }),
    SlackMicrosoftTeamsIntegrationPage: lazy(function () { return chunk(import('./pages/management/sdlc/slack_msft/SlackMicrosoftTeamsIntegrationPage')); }),
    SamlIntegrationPage: lazy(function () { return chunk(import('./pages/management/sdlc/SamlIntegrationPage')); }),
    WebhookIntegrationPage: lazy(function () { return chunk(import('./pages/management/sdlc/webhooks/WebhookIntegrationListPage')); }),
    SharedPolicyViolations: lazy(function () { return chunk(import('./pages/management/policy_violations/SharedPolicyViolations')); }),
    SastIssuePublicViewPage: lazy(function () { return chunk(import('./pages/management/v2/share/sast_issue/SastIssuePublicViewPage')); }),
};
// prettier-ignore
var ReduxComponents = {
    ManagementComplianceReports: lazy(function () { return chunk(import('./redux/compliance_reports/ComplianceReports')); }),
    ManagementProductOnboardingContainer: lazy(function () { return chunk(import('./redux/onboarding_wizard/ProductOnboardingContainer')); }),
    ManagementProfile: lazy(function () { return chunk(import('./redux/profile/Profile')); }),
    ManagementUserDashboard: lazy(function () { return chunk(import('./redux/users/UserDashboard')); }),
    ManagementUserEdit: lazy(function () { return chunk(import('./redux/users/UserEdit')); }),
    ManagementUserInvite: lazy(function () { return chunk(import('./redux/users/UserInvite')); }),
    AtlasPublicAppSecurity: lazy(function () { return chunk(import('./redux/atlas/components/SharedLinks/PublicAppSecurity')); }),
    AtlasConfiguration: lazy(function () { return chunk(import('./redux/atlas/components/Configuration')); }),
    AtlasApplicationView: lazy(function () { return chunk(import('./redux/atlas/components/ApplicationView')); }),
    AtlasSearchView: lazy(function () { return chunk(import('./redux/atlas/components/SearchView')); }),
    ApolloApiOperationDetailsContainer: lazy(function () { return chunk(import('./redux/api_operations/ApiOperationDetailsContainer')); }),
    ApolloApiProtectContainer: lazy(function () { return chunk(import('./redux/protect/ApiProtectContainer')); }),
    ApolloDetectInjectContainer: lazy(function () { return chunk(import('./redux/detect_inject/DetectInjectContainer')); }),
    ApolloHackExtractContainer: lazy(function () { return chunk(import('./redux/hack_extract/HackExtractContainer')); }),
    ApolloLeakyApisContainer: lazy(function () { return chunk(import('./redux/leaky_apis/LeakyApisContainer')); }),
    ApolloNetworkServiceDetailsContainer: lazy(function () { return chunk(import('./redux/network_services/NetworkServiceDetailsContainer')); }),
    ApolloOverview: lazy(function () { return chunk(import('./redux/dashboard/Overview')); }),
    ApolloOverviewDetail: lazy(function () { return chunk(import('./redux/dashboard/OverviewDetail')); }),
    ApolloPolicyRuleWithViolationsAndDetails: lazy(function () { return chunk(import('./redux/policy_rules/PolicyRuleWithViolationsAndDetails')); }),
    PolicyRuleWithViolationsAndDetailsCorrectAndProtect: lazy(function () { return chunk(import('./redux/policy_rules/PolicyRuleWithViolationsAndDetailsCorrectAndProtect')); }),
    ApolloRestfulApiDetailsContainer: lazy(function () { return chunk(import('./redux/restful_apis/RestfulAPIDetailsContainer')); }),
    ApolloSharedLinks: lazy(function () { return chunk(import('./redux/shared_links/SharedLinks')); }),
    ApolloSsrfSploitsContainer: lazy(function () { return chunk(import('./redux/ssrf_sploits/SsrfSploitsContainer')); }),
    ShareCloudResourcePublicView: lazy(function () { return chunk(import('./redux/shared_links/public_app/CloudResourcePublicView')); }),
    ShareNetworkServicesPublicView: lazy(function () { return chunk(import('./redux/shared_links/public_app/NetworkServicePublicView')); }),
    ShareOnboardingPublicView: lazy(function () { return chunk(import('./redux/shared_links/public_app/OnboardingPublicView')); }),
    ShareProductOnboardingWizardPublicView: lazy(function () { return chunk(import('./redux/shared_links/public_app/ProductOnboardingWizardPublicView')); }),
    ShareRestfulAPIPublicView: lazy(function () { return chunk(import('./redux/shared_links/public_app/RestfulAPIPublicView')); }),
    ShareWebAppPublicView: lazy(function () { return chunk(import('./redux/shared_links/public_app/WebAppPublicView')); }),
};
// prettier-ignore
var MobileComponents = {
    MobilePoliciesPage: lazy(function () { return chunk(import('./pages/mobile/policies/MobilePoliciesPage')); }),
    MobilePoliciesUuidPage: lazy(function () { return chunk(import('./pages/mobile/policies/uuid/MobilePoliciesUuidPage')); }),
    MobileInventoryPage: lazy(function () { return chunk(import('./pages/mobile/inventory/MobileInventoryPage')); }),
    MobileAssetGroupsUuidPage: lazy(function () { return chunk(import('./pages/mobile/asset_groups/uuid/MobileAssetGroupsUuidPage')); }),
};
// prettier-ignore
var DevSecOpsComponents = {
    DevSecOpsPage: lazy(function () { return chunk(import('./pages/devsecops/DevSecOpsHome')); }),
    StanfordDishUniHome: lazy(function () { return chunk(import('./pages/devsecops/stanford_dish_university/StanfordDishUniHome')); }),
    StanfordDishUniViewAllMaterials: lazy(function () { return chunk(import('./pages/devsecops/stanford_dish_university/StanfordDishUniViewAllMaterials')); }),
    DevSecOpsJiraApiCloudWebLandingPage: lazy(function () { return chunk(import('./pages/devsecops/jira_integration/api_cloud_web/ApiCloudWebLandingPage')); }),
    DevSecOpsJiraApiCloudWebConfigure: lazy(function () { return chunk(import('./pages/devsecops/jira_integration/api_cloud_web/ApiCloudWebConfigure')); }),
    DevSecOpsGithubOnPremConfigure: lazy(function () { return chunk(import('./pages/devsecops/jira_integration/api_cloud_web/ApiCloudWebConfigure')); }),
};
// prettier-ignore
var PoliciesComponents = {
    PoliciesPage: lazy(function () { return chunk(import('./pages/policies/PoliciesPage')); }),
    PoliciesUuidPage: lazy(function () { return chunk(import('./pages/policies/uuid/PoliciesUuidPage')); }),
    PoliciesV2Page: lazy(function () { return chunk(import('./pages/policies/v2/PoliciesV2Page')); }),
    PoliciesV2UuidPage: lazy(function () { return chunk(import('./pages/policies/v2/uuid/PoliciesV2UuidPage')); }),
    PoliciesOpenscanRedirectPage: lazy(function () { return chunk(import('./pages/policies/v2/PoliciesOpenscanRedirectPage')); }),
};
// prettier-ignore
var DashboardComponents = {
    DashboardPage: lazy(function () { return chunk(import('./pages/dashboard/DashboardPage')); }),
    DashboardLandingPage: lazy(function () { return chunk(import('./pages/dashboard/LandingPage')); }),
};
// prettier-ignore
export default __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, SettingsV2Components), DevSecOpsV2Components), MobileV2Components), WebV2Components), cloudV2Components), ApiComponents), ApiV2Components), CloudComponents), WebComponents), SupplyChainComponents), OpenScanV2Components), ManagementComponents), ReduxComponents), MobileComponents), DevSecOpsComponents), PoliciesComponents), DashboardComponents);
