import { useEffect, useState } from 'react';
import { useLocation } from '@reach/router';
var PRODUCT_PATHS = {
    api: 'api/v2',
    cloud: 'cloud/v2',
    web: 'web/v2',
    mobile: 'mobile-secure/v2',
    openscan: 'openscan/v2',
    dashboard: 'dashboard/v2',
    devsecops: 'devsecops/v2',
    management: 'management/v2',
    policies: 'policies/v2',
};
var checkPath = function (pathname, path, product) {
    var _a, _b;
    if (product === 'dashboard') {
        return (['/dashboard/', '/dashboard'].includes((_b = (_a = pathname.match(/dashboard/)) === null || _a === void 0 ? void 0 : _a.input) !== null && _b !== void 0 ? _b : '') ||
            !!pathname.match(/dashboard\/v2/));
    }
    return !!pathname.match(path);
};
var useGetBaseProductUrl = function () {
    var location = useLocation();
    var _a = useState('api'), baseProduct = _a[0], setBaseProduct = _a[1];
    var _b = useState('/api/v2'), baseUrl = _b[0], setBaseUrl = _b[1];
    var _c = useState(), currentProduct = _c[0], setCurrentProduct = _c[1];
    useEffect(function () {
        var product = Object.keys(PRODUCT_PATHS).find(function (key) {
            return checkPath(location.pathname, PRODUCT_PATHS[key], key);
        });
        setCurrentProduct(product);
        if (!product)
            return;
        // we set the base url based on the current path(product)
        // but if current path is not one of the products,
        // we set the base url based on the base product
        if (product === 'mobile' || baseProduct === 'mobile') {
            setBaseUrl(PRODUCT_PATHS.mobile);
        }
        else if (product === 'web' || baseProduct === 'web') {
            setBaseUrl(PRODUCT_PATHS.web);
        }
        else if (product === 'cloud' || baseProduct === 'cloud') {
            setBaseUrl(PRODUCT_PATHS.cloud);
        }
        else if (product === 'openscan' || baseProduct === 'openscan') {
            setBaseUrl(PRODUCT_PATHS.openscan);
        }
        else if (product === 'dashboard' || baseProduct === 'dashboard') {
            // Redirect to API Secure v2 by default
            setBaseUrl(PRODUCT_PATHS.api);
        }
        else {
            // TMP Workaround when we don't have baseProduct in browser memory (e.g. when we refresh the page)
            setBaseUrl(PRODUCT_PATHS.api);
        }
        // we don't want to set the base product for these products
        if (product === 'devsecops' || product === 'management' || product === 'policies') {
            return;
        }
        setBaseProduct(product);
    }, [baseProduct, location.pathname]);
    return { baseProduct: baseProduct, baseUrl: baseUrl, currentProduct: currentProduct };
};
export default useGetBaseProductUrl;
