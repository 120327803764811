import React, { memo } from 'react';
import { Text } from '@dt/material-components';
import { TableCell, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import { AssetsLink } from '../../assets';

const InventoryTableCellAssetNameComponent = function InventoryTableCellAssetName({ asset, productBasePath }) {
  return (
    <TableCell align="left" height={60}>
      <Box display={'flex'} flexDirection={'column'}>
        {productBasePath ? (
          <Tooltip disableInteractive title={asset.name}>
            <AssetsLink productBasePath={productBasePath} asset_type={asset.asset_type} asset_id={asset.id} isV2>
              <Text component={'div'} variant={'body'} noWrap={true}>
                {asset.name}
              </Text>
            </AssetsLink>
          </Tooltip>
        ) : (
          <Tooltip disableInteractive title={asset.name}>
            <Text component={'div'} variant={'body'} noWrap={true}>
              {asset.name}
            </Text>
          </Tooltip>
        )}
        {asset.url && (
          <Tooltip disableInteractive title={asset.name}>
            <Text component={'div'} variant={'bodyS'} noWrap={true}>
              {asset.url}
            </Text>
          </Tooltip>
        )}
      </Box>
    </TableCell>
  );
};

export default memo(InventoryTableCellAssetNameComponent);
